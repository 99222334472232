import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    Col,
    Card,
    CardBody,
    CardText,
    Container,
    Row,
    ButtonGroup,
    Button
} from 'reactstrap';
import * as actions from '../../../store/actions/index';
import authenticatedInstance from '../../../api/authenticated';
import LoaderContext from '../../../context/LoaderContext';
import { 
    FiEdit,
    FiTrash2,
} from "react-icons/fi";
import { 
    FaCircleUser,
} from "react-icons/fa6";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ModalDelete from '../../Aux/ModalDelete/ModalDelete';
import withRouter from '../../Aux/WithRouter/WithRouter';
import FormCalendar from './FormCalendar';
import './Calendar.css';

class Calendar extends Component {
    static contextType = LoaderContext;

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            formCalendarOpen: false,
            formCalendarEdit: false,
            formCalendarEditData: null,
            formCalendarEditId: null,
            formCalendarEditPosition: null,
            event_id: null,
            calendar: [],
            exclude: {
                entity: 'anything',
                anything: {
                    id: 0,
                    show: false,
                    title: "",
                    message: ""
                },
                calendar: {
                    id: 0,
                    position: 0,
                    show: false,
                    title: "Excluir compromisso do calendário",
                    message: "Deseja realmente excluir esse compromisso?"
                },
            },
            events: []
        };
    }

    componentDidMount() {
        authenticatedInstance.get(
            '/calendar'
        ).then(response => {
            this.setState({events: response.data});
            return response.data;
        }).catch(error => {
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    calendarAdd = () => {
        this.setState({
            formCalendarOpen: !this.state.formCalendarOpen,
            formCalendarEdit: false,
            formCalendarEditData: null
        });
    }

    editCalendarEvent = (position) => {
        console.log("editCalendarEvent", position, this.state.events);
        this.setState({
            formCalendarOpen: !this.state.formCalendarOpen,
            formCalendarEdit: true,
            formCalendarEditPosition: position,
            formCalendarEditData: this.state.events[position]
        });
    }

    addCalendarEvent = (calendarNew) => {
        console.log("addCalendarEvent", calendarNew);
        const events = Object.values({...this.state.events});
        if(this.state.formCalendarEdit) {
            let data = {...this.state.formCalendarEditData};
            data['date_time'] = calendarNew['date_time'];
            data['duration'] = calendarNew['duration'];
            data['obs'] = calendarNew['obs'];
            events[this.state.formCalendarEditPosition] = data;
            console.log("addCalendarEvent 2", events[this.state.formCalendarEditPosition] );
        } else {
            events.push(calendarNew);
        }
        this.setState({
            formCalendarOpen: false,
            events: events
        });
    }

    exclude = (id, position, entity, path) => {
        console.log("exclude", id, position, entity, path);
        const exclude = {...this.state.exclude};
        exclude["entity"] = entity;
        exclude[entity]["id"] = id;
        exclude[entity]["position"] = position;
        exclude[entity]["show"] = true;
        exclude[entity]["path"] = path;
        this.setState({exclude: exclude});
    }

    excludeDo = () => {
        const events = Object.values({...this.state.events});
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
        switch(this.state.exclude.entity) {
            case "calendar":
                delete events[exclude[this.state.exclude.entity]["position"]];
                break;
            default:
                break;
        }
        this.setState({events: events});
    }

    excludeCancel = () => {
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
    }

    render() {
        moment.locale('pt-br');

        let modalExclude = "";
        if(this.state.exclude[this.state.exclude.entity]["show"]) {
            modalExclude = (
                <ModalDelete
                    title={this.state.exclude[this.state.exclude.entity]["title"]}
                    message={this.state.exclude[this.state.exclude.entity]["message"]}
                    path={this.state.exclude[this.state.exclude.entity]["path"]}
                    actionOnSuccess={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

        let events = "";
        if (typeof this.state.events != 'undefined' && this.state.events.length > 0) {
            events = (
                <Fragment>
                    {this.state.events.map((event, index) => {
                        const dateTime = moment(event.date_time);
                        return (
                            <Container key={index} className="bg-light border item-list-box">
                                <Row>
                                    <Col sm={2}>
                                        <h5>{dateTime.format('DD/MM/YYYY')}<br />
                                        {dateTime.format('HH:mm')} - {dateTime.add(event.duration.substring(0, 5), 'HH:mm').format('HH:mm') }</h5>
                                        <p>Duração: {event.duration.substring(0, 5)}</p>
                                    </Col>
                                    <Col sm={9}>
                                        {   typeof event.company != 'undefined' && event.company != null
                                            && typeof event.company.title != 'undefined' && event.company.title != null ?
                                            <h6>{event.company.title}</h6> : null
                                        }
                                        {   typeof event.local != 'undefined' && event.local != null
                                            ?
                                            <p>{event.local.title}</p>
                                            : null
                                        }
                                        {   typeof event.address != 'undefined' && event.address != null
                                            ?
                                            <p>{event.address.address_1} {event.address.number}, {event.address.address_2} - {event.address.city}</p>
                                            : null
                                        }
                                        <p>{event.obs}</p>
                                    </Col>
                                    <Col sm={1}>
                                        <ButtonGroup vertical>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => this.editCalendarEvent(index)}
                                                >
                                                <FiEdit title="Editar Evento" />
                                            </Button>
                                            <Button
                                                color="primary"
                                                outline
                                                onClick={() => this.exclude(event.id, index, "calendar", "/calendar/" + event.id)}
                                                >
                                                <FiTrash2 title="Apagar Evento" />
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </Container>
                        );
                    })}
                </Fragment>
            );
        } else {
            events = <p>Sem eventos agendados.</p>;
        }

        return (
            <Fragment>
                <Card
                    className="event-card"
                    color="light"
                    outline
                    style={{
                        width: '100%    '
                    }}
                >
                    <CardBody>
                        <CardText>
                            <Row>
                                <Col sm={5}>
                                    <Button type="button" color="primary" onClick={() => this.calendarAdd()}><AiOutlineUsergroupAdd /> Adicionar Evento</Button>
                                </Col>
                            </Row>
                            <hr/>
                            {events}
                        </CardText>
                    </CardBody>
                </Card>
                <FormCalendar
                    returnFunction={(calendar) => this.addCalendarEvent(calendar)}
                    eventId={this.state.event_id}
                    isOpen={this.state.formCalendarOpen}
                    onClosed={this.calendarAdd}
                    edit={this.state.formCalendarEdit}
                    calendar={this.state.formCalendarEditData}
                />
                {modalExclude}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentCompanyId: state.company.current,
    }
};

const mapDispatchProps = dispatch => {
    return {
        loaderStop: () => dispatch(actions.loaderStop())
    }
};


export default connect(mapStateToProps, mapDispatchProps)(withRouter(Calendar));
