import React, { PureComponent, Fragment, createRef } from 'react';
import authenticatedInstance from '../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../Aux/Toastfy/Toastfy';
import { ReactSortable } from 'react-sortablejs';
import { 
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    Container,
    PopoverHeader,
    PopoverBody,
    UncontrolledPopover,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br'
import {arrayMoveImmutable} from 'array-move';
import classNames from 'classnames';
import * as actions from '../../store/actions/index';
import FormArrangementPart from './FormArrangementPart';
import FormArrangementPartFile from './FormArrangementPartFile';
import ModalDelete from '../Aux/ModalDelete/ModalDelete';
import ViewScore from './ViewScore';
import { 
    FiClock,
    FiMove,
    FiEdit,
    FiEye,
    FiTrash2,
    FiFilePlus,
    FiUpload
} from "react-icons/fi";
import withRouter from '../Aux/WithRouter/WithRouter';
import './ViewArrangement.css';

class ViewArrangement extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            currentScoreFile: '',
            deleteInstrumentId: 0,
            deleteInstrumentFileId: 0,
            deleteInstrumentShow: false,
            deleteInstrumentFileShow: false,
            editInstrumentId: 0,
            editPartDialogShow: false,
            editPartDialogOpen: false,
            editPartFileDialogShow: false,
            scoreShow: false,
            arrangement:  {
                id: 0,
                opus_id: 0,
                title: '',
                description: '',
                author_name: '',
                author_id: 0,
                nota: '',
                tone: {},
                release_date: '',
                instrumentations: [],
                instruments: []
            }
        }

        this.partsTBody = createRef();
    }

    async componentDidMount() {
        console.log('ViewArrangement componentDidMount', this.props.currentCompanyId, this.props.params.id);
        await this.loadOpus(this.props.currentCompanyId, this.props.params.id).then(async () => {
            await this.loadArrangement(this.props.params.opus_id, this.props.params.id);
        });
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    loadOpus = async (companyId, opusId) => {
        this.props.setOpus(companyId, opusId);
    }

    loadArrangement = async (opusId, arrangementId) => {
        if (typeof this.props.params !== 'undefined' && typeof this.props.params.id !== 'undefined') {
            await authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/'+ this.props.params.id).then(response => {
                let new_instrumentations = [];
                response.data.instrumentations.map((value) => {
                    new_instrumentations.push({ value: value.id, label: value.title });
                    return new_instrumentations;
                });
                const updatedForm = {...response.data};
                updatedForm.selectedInstrumentOption = new_instrumentations;

                moment.locale('pt-br');
                const dateTimeObj = moment(response.data.release_date);
                updatedForm.release_date = dateTimeObj.format('L');

                this.setState({
                    arrangement: updatedForm
                });
            }).catch(error => {
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.arrangement};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({arrangement: updatedForm});
    }

    submitHandler = (data) => {
        let new_instrumentations = [];
        this.state.arrangement.selectedInstrumentOption.map((value) => {
            new_instrumentations.push(value.value);
            return new_instrumentations;
        });
        const updatedForm = {...this.state.arrangement};
        updatedForm.instrumentations = new_instrumentations;
        updatedForm.author_id = 0;
        updatedForm.opus_id = this.props.params.opus_id;
        updatedForm.nota = updatedForm.nota.length === 0 ? 0 : parseInt(updatedForm.nota);
        this.setState({arrangement: updatedForm});
        delete updatedForm["_links"];

        if(this.props.edit) {
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/' + this.props.params.id, updatedForm).then(response => {
                toastfy("success", 'Obra alterada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/opus/'+ this.props.params.opus_id +'/arrangements', updatedForm).then(response => {
                toastfy("success", 'Obra adicionada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.arrangement};
        updatedForm.selectedInstrumentOption = newValue;
        this.setState({ arrangement: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await this.props.addSimpleItemSubmit(title, 'instrumentations', 'title');
        const updatedForm = {...this.state.arrangement};
        const new_instrument = {
          value: newInstrument.id,
          label: newInstrument.title
        };
        updatedForm.selectedInstrumentOption.push(new_instrument);
        this.setState({
          arrangement: updatedForm
        });
    }

    addInstrumentPart = (data) => {
        let arrangementPart = {...this.state.arrangement};
        arrangementPart.instruments.push({
            id: data.id,
            title: data.title,
            obs: data.obs,
            abrev: data.abrev,
            number: data.number,
            ordering: data.ordering,
            file_hash: data.file_hash,
            extension: data.extension,
            thumbnail: data.thumbnail,
            processing: data.processing
    });
        this.setState({
            editInstrumentId: data.id,
            arrangement: arrangementPart,
            editPartFileDialogShow: false
        });
    }

    partDialogShow = (id, show, partShow) => {
        this.setState({
            editPartDialogOpen: !this.state.editPartDialogOpen,
            editInstrumentId: id,
            editPartDialogShow: show,
            editPartFileDialogShow: partShow
        });
    }

    editPartFileDialogShow = (show) => {
        this.setState({
            editPartFileDialogShow: show
        });
    }

    editInstrumentPart = (data) => {
        let arrangement = {...this.state.arrangement};
        let instruments = arrangement.instruments;
        const edited = {
            id: data.id,
            title: data.title,
            obs: data.obs,
            abrev: data.abrev,
            number: data.number,
            ordering: data.ordering,
            file_hash: data.file_hash,
            extension: data.extension,
            thumbnail: data.thumbnail,
            processing: data.processing
        };
        arrangement.instruments.map((value, i) => {
            if(value.id === data.id) {
                instruments[i] = edited;
                return instruments[i];
            }
            return false;
        });
        arrangement.instruments = instruments;
        this.setState({
            editInstrumentId: data.id,
            arrangement: arrangement,
            editPartFileDialogShow: false
        });
    }

    returnInstrumentPart = (data) => {
        if(this.state.editPartDialogShow) {
            this.editInstrumentPart(data);
        } else {
            this.addInstrumentPart(data);
        }
    }

    excludeInstrument = (id) => {
        this.setState({deleteInstrumentId: id, deleteInstrumentShow: true});
    }

    excludeInstrumentSuccess = () => {
        let arrangement = {...this.state.arrangement};
        arrangement.instruments = arrangement.instruments.filter(item => item.id !== this.state.deleteInstrumentId);
        this.setState({
            deleteInstrumentId: 0,
            deleteInstrumentShow: false,
            arrangement: arrangement
        });
    }

    deleteInstrumentCancel = () => {
        this.setState({
            deleteInstrumentId: 0,
            deleteInstrumentShow: false,
        });
    }

    excludeInstrumentFile = (id) => {
        this.setState({deleteInstrumentFileId: id, deleteInstrumentFileShow: true});
    }

    excludeInstrumentFileSuccess = () => {
        let arrangement = {...this.state.arrangement};
        arrangement.instruments.map((value, i) => {
            if(value.id === this.state.deleteInstrumentFileId) {
                arrangement.instruments[i]['file_hash'] = '';
                arrangement.instruments[i]['extension'] = '';
                arrangement.instruments[i]['thumbnail'] = 0;
                arrangement.instruments[i]['processing'] = 0;
                arrangement.instruments[i]['size'] = 0;
                arrangement.instruments[i]['thumbnail_medium'] = '';
                arrangement.instruments[i]['thumbnail_small'] = '';
                arrangement.instruments[i]['file_url'] = '';
                return arrangement.instruments[i];
            }
            return false;
        });
        this.setState({
            deleteInstrumentFileId: 0,
            deleteInstrumentFileShow: false,
            arrangement: arrangement
        });
    }

    deleteInstrumentFileCancel = () => {
        this.setState({
            deleteInstrumentFileId: 0,
            deleteInstrumentFileShow: false,
        });
    }

    viewScore = (id) => {
        const instruments = this.state.arrangement.instruments;
        const part = instruments.filter(item => item.id === id)[0];
        console.log('part id', id)
        console.log('part', part)
        this.setState({
            currentScoreFile: part.file_url,
            scoreShow: true
        });
    }

    closeScore = () => {
        console.log('closeScore');
        this.setState({
            currentScoreFile: '',
            scoreShow: false
        });
    }

    onCreateAuthors = async (title) => {
        const newAuthor = await this.props.addSimpleItemSubmit(title, 'authors', 'name');
        const updatedForm = {...this.state.arrangement};
        updatedForm.author.name = newAuthor.name;
        updatedForm.author.id = newAuthor.id;
        updatedForm.author_id = newAuthor.id;
        this.setState({
          arrangement: updatedForm
        });
    }

    handleAuthorChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.arrangement};
        updatedForm.author_id = newValue.value;
        updatedForm.author.id = newValue.value;
        updatedForm.author.name = newValue.label;
        this.setState({ arrangement: updatedForm });
    };

    parseNumber = (value) => {
        if(
            typeof value === 'number' 
            && !isNaN(value)
            && parseInt(value) > 0
        ) {
            return ' ' + value.toString();
        }

        return '';
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let arrangement = {...this.state.arrangement};
        arrangement['instruments'] = arrayMoveImmutable(arrangement['instruments'], oldIndex, newIndex);
        let ordering = [];
        arrangement['instruments'].map((value, i) => {
            ordering.push({position: i, partId: value.id});
            return ordering;
        });
        this.setState({
            arrangement: arrangement
        });

        authenticatedInstance.put(
            '/collection/' + this.props.currentCompanyId + 
            '/opus/' + this.props.params.opus_id +
            '/arrangements/' + this.props.params.id +
            '/ordenate_parts',
            {order: ordering}).then(response => {
            toastfy("success", 'Partes reordenadas com sucesso!');
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao tentar reordenar as partes...');
            this.setState({error: true});
            this.props.loaderStop();
        });

    };

    setOrder = (instruments) => {
        const arrangement= {...this.state.arrangement};
        arrangement['instruments'] = instruments;
        this.setState({
            arrangement: arrangement
        });
    }
    
    render() {
        let badgeColor = "secondary";
        if(this.state.arrangement.nota < 3) {
            badgeColor = "danger";
        } else if(this.state.arrangement.nota === 3) {
            badgeColor = "warning";
        } else if(this.state.arrangement.nota > 3) {
            badgeColor = "success";
        }

        let modalDelete = '';
        if(this.state.deleteInstrumentShow) {
            modalDelete = (
                <ModalDelete
                    title="Excluir Parte Instrumental"
                    message="Tem certeza que deseja excluir essa parte instrumental (e seus arquivos)?"
                    path={'/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/' + this.props.params.id +'/instruments/'+ this.state.deleteInstrumentId}
                    actionOnSuccess={() => this.excludeInstrumentSuccess()}
                    cancel={this.deleteInstrumentCancel}
                />
            );
        }

        if(this.state.deleteInstrumentFileShow) {
            modalDelete = (
                <ModalDelete
                    title="Excluir Arquivo"
                    message="Tem certeza que deseja excluir esse arquivo?"
                    path={'/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/' + this.props.params.id +'/instruments/'+ this.state.deleteInstrumentFileId +'/part'}
                    actionOnSuccess={() => this.excludeInstrumentFileSuccess()}
                    cancel={this.deleteInstrumentFileCancel}
                />
            );
        }

        // let viewScore = '';
        // if(this.state.scoreShow) {
        //     viewScore = (
        //         <ViewScore
        //             title="Partitura"
        //             pdfUrl={this.state.currentScoreFile.replace('127.0.0.1', 'localhost')}  
        //             show={this.closeScore}
        //         />
        //     );
        // }

        return (
        <Fragment>
            <CardTitle tag="h6" className="font-italic">
                <Row>
                    <Col sm={12}>
                        {this.props.currentOpus.title}&nbsp;
                        - &nbsp;{this.props.currentOpus.author.name}
                    </Col>
                </Row>
            </CardTitle>
            <CardTitle tag="h4">
                <Row>
                    <Col sm={8}>
                        {this.state.arrangement.title}
                    </Col>
                    <Col sm={4}>
                        <Badge color={badgeColor}>Nota {this.state.arrangement.nota}</Badge>
                    </Col>
                </Row>
            </CardTitle>
            <CardTitle tag="h5">
                <Row>
                    <Col sm={6}>
                        {this.state.arrangement.author_name}
                    </Col>
                </Row>
            </CardTitle>
            <CardText>
                <Row>
                    <Col sm={12}>
                        <hr className="hr" />
                        <h6>Informações</h6>
                        {
                            this.state.arrangement.description === '' ?
                                <p>Sem informações.</p>
                            :
                                this.state.arrangement.description
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={2}>
                        <h6>Data de lançamento:</h6>
                    </Col>
                    <Col sm={10}>
                        {this.state.arrangement.release_date}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={2}>
                        <h6>Tonalidade:</h6>
                    </Col>
                    <Col sm={10}>
                        {this.state.arrangement.tone.eu}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={2}>
                        <h6>Instrumentações:</h6>
                    </Col>
                    <Col sm={10}>
                        {
                            this.state.arrangement.instrumentations.map((value, i) => {
                                return (<Fragment key={i}><Badge>{value.title}</Badge>&nbsp;&nbsp;&nbsp;</Fragment>);
                            })
                            
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card
                            className="event-card"
                            color="light"
                            outline
                            style={{
                                width: '100%'
                            }}
                        >
                            <CardBody>
                                <CardText>
                                    <Row>
                                        <Col sm={5}>
                                            <Button type="button" color="primary" onClick={() => this.partDialogShow(0, false, false)}><FiFilePlus /> Adicionar</Button>
                                        </Col>
                                    </Row>
                                    <hr/>
                        {
                        typeof this.state.arrangement.instruments != 'undefined' && this.state.arrangement.instruments.length > 0 ?
                            <ReactSortable
                                group="shared"
                                animation={200}
                                delay={1}
                                swap
                                multiDrag
                                setList={this.setOrder}
                                list={this.state.arrangement.instruments}
                                handle=".handle"
                                onEnd={(evt) => this.onSortEnd(evt)}
                            >
                            <Fragment>
                            {this.state.arrangement.instruments.map((instrument, index) => {
                            return (
                                <Container key={index} className="bg-light border item-list-box">
                                    <Row>
                                        <Col sm={1}>
                                            <FiMove className="handle" />
                                        </Col>
                                        <Col sm={2}>
                                        {
                                        parseInt(instrument.processing) === 1 ?
                                            <Fragment>
                                                <Button id={"thumb_s_" + instrument.id} outline color="primary">
                                                    <FiClock size="5em" />
                                                </Button>
                                                <UncontrolledPopover
                                                    target={"thumb_s_" + instrument.id}
                                                    trigger="click"
                                                >
                                                    <PopoverHeader>
                                                        {instrument.title}{this.parseNumber(instrument.number)}
                                                    </PopoverHeader>
                                                    <PopoverBody>
                                                        Arquivo da partitura ainda está em processamento,
                                                        aguarde alguns instantes e atualize a página
                                                    </PopoverBody>
                                                </UncontrolledPopover>  
                                            </Fragment>
                                        :
                                            parseInt(instrument.thumbnail) === 0 ?
                                                <Button outline color="primary" onClick={(id, show, partShow) => this.partDialogShow(instrument.id, false, true)}>
                                                    <FiUpload size="5em" />
                                                </Button>
                                            :
                                                <Fragment>
                                                <Card style={{width: '8rem', margin: '0 auto'}} outline color="info">
                                                    <CardImg
                                                        id={"thumb_s_" + instrument.id}
                                                        src={instrument.thumbnail_small}
                                                    />
                                                    <UncontrolledPopover
                                                        target={"thumb_s_" + instrument.id}
                                                        trigger="click"
                                                        >
                                                        <PopoverHeader>
                                                            {instrument.title}{this.parseNumber(instrument.number)}
                                                        </PopoverHeader>
                                                        <PopoverBody>
                                                            <img alt="Score" src={instrument.thumbnail_medium} />
                                                        </PopoverBody>
                                                    </UncontrolledPopover>  
                                                    <CardBody style={{padding: 'unset'}}>
                                                        <ButtonGroup>
                                                            <Button color="success" onClick={(id) => this.viewScore(instrument.id)}><FiEye size="1em" /></Button>
                                                            <Button color="primary" onClick={(id, show, partShow) => this.partDialogShow(instrument.id, false, true)}><FiUpload size="1em" /></Button>
                                                            <Button color="danger" onClick={(id) => this.excludeInstrumentFile(instrument.id)}><FiTrash2 size="1em" /></Button>
                                                        </ButtonGroup>
                                                    </CardBody>
                                                </Card>
                                                </Fragment>                          
                                            }
                                        </Col>
                                        <Col sm={8}>
                                            <h5>{instrument.title} {(parseInt(instrument.number) === 0 ? '' : instrument.number)}</h5>
                                            <p>{instrument.obs}</p>
                                        </Col>
                                        <Col sm={1}>
                                            <ButtonGroup vertical>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(id, show, partShow) => this.partDialogShow(instrument.id, true, false)}
                                                    // active={rSelected === 1}
                                                >
                                                    <FiEdit title="Editar Item" />
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    // disabled={instrument.members.length > 0 ? true : false}
                                                    onClick={(id, position, entity, path) => this.excludeInstrument(instrument.id)}
                                                    // active={rSelected === 2}
                                                >
                                                    <FiTrash2 title="Apagar Item" />
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            );
                            })}
                            </Fragment>
                            </ReactSortable>
                            : 
                            <p>Sem repertório escolhido para o evento.</p>
                        }
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </CardText>
            <FormArrangementPart
                    title="Editar Arranjo"
                    currentArrangementTitle={this.state.arrangement.title}
                    currentArrangementAuthor={this.state.arrangement.author_name}
                    currentCompanyId={this.props.currentCompanyId}
                    currentOpusId={this.props.params.opus_id}
                    currentArrangementId={this.props.params.id}
                    isOpen={this.state.editPartDialogOpen}
                    onClosed={(id, show, partShow) => this.partDialogShow(this.props.params.id, false, false)}
                    returnInstrumentPart={(data) => this.returnInstrumentPart(data)}
                    part={this.state.editPartDialogShow ? this.state.arrangement.instruments.filter(item => item.id === this.state.editInstrumentId)[0] : null}
                    edit={this.state.editPartDialogShow}
                    show={(id, show, partShow) => this.partDialogShow(id, show, partShow)}
                />
                <FormArrangementPartFile
                    title="Adicionar Arquivo"
                    currentArrangementTitle={this.state.arrangement.title}
                    currentArrangementAuthor={this.state.arrangement.author_name}
                    currentCompanyId={this.props.currentCompanyId}
                    currentOpusId={this.props.params.opus_id}
                    currentArrangementId={this.props.params.id}
                    currentPartId={this.state.editInstrumentId}
                    isOpen={this.state.editPartFileDialogShow}
                    onClosed={(id, show, partShow) => this.partDialogShow(this.props.params.id, false, false)}
                    returnInstrumentPart={(data) => this.editInstrumentPart(data)}
                    part={this.state.editPartFileDialogShow ? this.state.arrangement.instruments.filter(item => item.id === this.state.editInstrumentId)[0] : null}
                    show={(show) => this.editPartFileDialogShow(show)}
                />
            {modalDelete}
            {/* {viewScore} */}
            <ViewScore
                title="Partitura"
                pdfUrl={this.state.currentScoreFile}  
                show={this.state.scoreShow}
            />
        </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentCompany: state.company,
        currentOpus: state.opus
	}
};

const mapDispatchProps = dispatch => {
	return {
		setOpus: (companyId, opusId) => dispatch(actions.setOpus(companyId, opusId)),
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(ViewArrangement));
