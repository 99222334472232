import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    id: 0,
    title: '',
    author: {
      'id': '',
      'name': ''
    },
    author_id: null,
    style: {
      'id': '',
      'title': ''
    },
    style_id: null,
    nota: '',
    diretorio: '',
    compoe_livros: '',
    link_audio: '',
    link_video: '',
    // solo: '',
    info: '',
    livro_pagina: '',
    instrumentations: [],
    selectedInstrumentOption: [],
    arrangements: []
};

const setCurrentOpus = ( state, action ) => {
    // console.log('setCurrentOpus', action.data);
    const currentOpus = {...action.data};
    return updateObject(
        state,
        currentOpus
        // {
        //     current: action.data.id,
        //     id: action.data.id,
        //     title: action.data.title
        // }
    );
};

const opusFail = (state, action) => {
    // console.log(action);
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.OPUS_CURRENT: return setCurrentOpus(state, action);
        case actionTypes.OPUS_FAIL: return opusFail(state, action);
        default:
            return state;
    }
};

export default reducer;
