import authenticatedInstance from './authenticated';

const sympleItensGet = (inputValue, path, label) => {
    if(inputValue.length >= 3) {
        return authenticatedInstance.get(path + '?search=' + label + ':' + inputValue)
            .then(response => {
                let optionsArray = [];
                response.data['itens'].data.map((option) => {
                    optionsArray.push({
                        value: parseInt(option.id),
                        label: option[label]
                    });
                    return optionsArray;
                });
                console.log(optionsArray);
                return optionsArray;
            }).catch(function(error) {
                console.log(error);
            });
    }
};

export default sympleItensGet;