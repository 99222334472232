import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';
import loaderReducer from './reducers/loader';
import authReducer from './reducers/auth';
import companyReducer from './reducers/company';
import opusReducer from './reducers/opus';

const createRootReducer = (history) => combineReducers({
    // router: connectRouter(history),
    loader: loaderReducer,
    company: companyReducer,
    auth: authReducer,
    opus: opusReducer
});

export default createRootReducer;
