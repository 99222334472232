import { authenticatedInstance } from '../../api';
import * as actionTypes from './actionTypes';

export const setCurrentOpus = (data) => {
    return {
        type: actionTypes.OPUS_CURRENT,
        data: data
    };
};

export const opusFail = (data) => {
    return {
        type: actionTypes.OPUS_FAIL,
        error: data
    };
};

export const setOpus = (companyId, opusId) => {
    return dispatch => {
        if (localStorage.getItem("current_opus") === null) {
            dispatch(fetchOpus(companyId, opusId));
        } else {
            const opus = JSON.parse(localStorage.getItem("current_opus"));
            if(parseInt(opus.id) !== parseInt(opusId)) {
                console.log('setCurrent_opus', opus.id, opusId);
                dispatch(fetchOpus(companyId, opusId));
            } else {
                dispatch(setCurrentOpus(opus));
            }
        }
    }
};

export const fetchOpus = (companyId, opusId) => {
    console.log('fetchOpus');
    return dispatch => {
        // authenticatedInstance.get('/opus/' + opusSlug).then(response => {
        authenticatedInstance.get('/collection/' + companyId + '/opus/' + opusId).then(response => {
            console.log('fetchOpus fetched', response.data);
            localStorage.setItem('current_opus', JSON.stringify(response.data));
            dispatch(setCurrentOpus(response.data));
        }).catch(error => {
            dispatch(opusFail(error));
        });
    }
};
