import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notAuthenticatedInstance } from '../../../api';
import {
	Container, Col, Form,
   FormGroup, Label, Input,
   Button, FormText, FormFeedback
} from 'reactstrap';
import { Navigate } from 'react-router-dom';
import LoaderContext from '../../../context/LoaderContext';
import * as actions from '../../../store/actions/index';
import toastfy from '../../Aux/Toastfy/Toastfy';
import './AuthForm.css';

class AuthForm extends Component {
	static contextType = LoaderContext;

	constructor(props) {
		super(props);
			this.state = {
			'email': '',
			'password': '',
			validate: {
				emailState: '',
			},
		}
		this.handleChange = this.handleChange.bind(this);
	}

	validateEmail(e) {
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const { validate } = this.state;
		if (emailRex.test(e.target.value)) {
			validate.emailState = 'has-success';
		} else {
			validate.emailState = 'has-danger';
		}
		this.setState({ validate });
	}

	handleChange = async (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;
		this.setState({
			[ name ]: value,
		});
	}

	submitForm(e) {
		e.preventDefault();
		// this.props.onAuth(this.state.email, this.state.password);
        const authData = {
            email: this.state.email,
            password: this.state.password,
            remember_me: true
        }
        notAuthenticatedInstance.post('/user/login', authData)
            .then(response => {
                // console.log(response);
                this.props.authSuccess(response.data);
                this.props.setInfo();
            })
            .catch(err => {
                console.log(err);
                this.props.authFail(err);
				this.props.loaderStop();
				toastfy("error", 'E-mail ou senha não conferem!');
            });
	}

	render() {
		const { email, password } = this.state;

		if(this.props.isAuth) {
			return <Navigate to="/my" />;
		}

		return (
			<Container className="AuthForm">
				<h2>Sign In</h2>
				<Form className="form" onSubmit={ (e) => this.submitForm(e) }>
					<Col>
						<FormGroup>
							<Label>E-mail</Label>
							<Input
								type="email"
								name="email"
								id="email"
								placeholder="myemail@email.com"
								value={ email }
								valid={ this.state.validate.emailState === 'has-success' }
								invalid={ this.state.validate.emailState === 'has-danger' }
								onChange={ (e) => {
									this.validateEmail(e)
									this.handleChange(e)
								}}
							/>
							<FormFeedback valid>
								That's a tasty looking email you've got there.
							</FormFeedback>
							<FormFeedback>
								Uh oh! Looks like there is an issue with your email. Please input a correct email.
							</FormFeedback>
							<FormText>Your username is most likely your email.</FormText>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label for="password">Password</Label>
							<Input
								type="password"
								name="password"
								id="password"
								placeholder="********"
								value={ password }
								onChange={ (e) => this.handleChange(e) }
							/>
						</FormGroup>
					</Col>
					<Button>Submit</Button>
				</Form>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		token: state.auth.access_token,
        isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop()),
		authSuccess: (data) => dispatch(actions.authSuccess(data)),
		authFail: (err) => dispatch(actions.authFail(err)),
		setInfo: () => dispatch(actions.info()),
		onAuth: (email, password) => dispatch(actions.auth(email, password))
	};
};

export default connect(mapStateToProps, mapDispatchProps)(AuthForm);
