import { authenticatedInstance } from '../../api';
import * as actionTypes from './actionTypes';
import { setCompanies } from './auth';

export const setCurrentCompany = (data) => {
    console.log('setCurrentCompany', data);
    return {
        type: actionTypes.COMPANY_CURRENT,
        data: data
    };
};
export const getCurrentCompany = (data) => {
    return {
        type: actionTypes.COMPANY_GET_CURRENT,
        data: data
    };
};

export const companyFail = (data) => {
    return {
        type: actionTypes.COMPANY_FAIL,
        error: data
    };
};

export const updateAvatar = (photo) => {
    return dispatch => {
        dispatch(setAvatar(photo));
        const info = JSON.parse(localStorage.getItem('info'));
        dispatch(setCompanies(info.companies));
    }
}

export const setAvatar = (photo) => {
    return {
        type: actionTypes.COMPANY_UPDATE_AVATAR,
        photo: photo
    };
}

export const setCompany = (companySlug) => {
    return dispatch => {
        const currentCompany = localStorage.getItem("current_company");
        console.log('setCompany 1', currentCompany, companySlug);
        if (currentCompany === null) {
            console.log('setCompany 2');
            dispatch(fetchCompany(companySlug));
        } else {
            console.log('setCompany 3');
            const company = JSON.parse(currentCompany);
            if(company.slug !== companySlug && typeof companySlug !== 'undefined') {
                console.log('setCompany 4');
                dispatch(fetchCompany(companySlug));
            } else {
                console.log('setCompany 5', company);
                dispatch(setCurrentCompany(company));
            }
        }
    }
};

export const fetchCompany = (companySlug) => {
    return dispatch => {
        authenticatedInstance.get('/company/' + companySlug).then(response => {
            localStorage.setItem('current_company', JSON.stringify(response.data));
            dispatch(setCurrentCompany(response.data));
        }).catch(error => {
            dispatch(companyFail(error));
        });
    }
};
