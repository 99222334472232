import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Avatar, { createAvatarComponent, SrcSource } from 'react-avatar';
import withRouter from '../WithRouter/WithRouter';

const AvatarSrc = createAvatarComponent({
    sources: [ SrcSource ]
});

class CompanyAvatar extends PureComponent {

    firstLastName = (str) => {
        const nameArr = str.split(' ');
        let name = str;
        if(nameArr.length > 2) {
            name = nameArr[0];
            name += ' ' + nameArr[nameArr.length - 1];
        }
        return name;
    }

    stringToColour = (str) => {
        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }
        return colour
    }

    render() {
        if(this.props.own === true) {
            if(
                this.props.info.own_company.photos !== null
                && typeof this.props.info.own_company.photos !== 'undefined'
                && typeof this.props.info.own_company.photos.profile !== 'undefined'
                && this.props.info.own_company.photos.profile !== null
            ) {
                console.log("CompanyAvatar", this.props.info.own_company.photos.profile.url);
                return <AvatarSrc
                    src={this.props.info.own_company.photos.profile.url}
                    size={this.props.size ? this.props.size : "150"}
                    round={this.props.round ? this.props.round : "10px"}
                    onClick={this.props.onClickHandler ? this.props.onClickHandler : null}
                />;
            } else {
                return <Avatar
                    name={this.props.info.own_company.title !== null ? this.firstLastName(this.props.info.own_company.title) : ''}
                    maxInitials={2}
                    color={this.props.info.own_company.title !== null ? this.stringToColour(this.props.info.own_company.title) : '#032f62'}
                    size={this.props.size ? this.props.size : "150"}
                    round={this.props.round ? this.props.round : "10px"}
                    onClick={this.props.onClickHandler ? this.props.onClickHandler : null}
                />;
            }
        } else {
            if(
                this.props.currentCompany.photos !== null
                && typeof this.props.currentCompany.photos !== 'undefined'
                && typeof this.props.currentCompany.photos.profile !== 'undefined'
                && this.props.currentCompany.photos.profile !== null
            ) {
                return <AvatarSrc
                    src={this.props.currentCompany.photos.profile.url}
                    size={this.props.size ? this.props.size : "150"}
                    round={this.props.round ? this.props.round : "10px"}
                    onClick={this.props.onClickHandler ? this.props.onClickHandler : null}
                />;
            } else {
                return <Avatar
                    name={this.props.currentCompany.title !== null ? this.firstLastName(this.props.currentCompany.title) : ''}
                    maxInitials={2}
                    color={this.props.currentCompany.title !== null ? this.stringToColour(this.props.currentCompany.title) : '#032f62'}
                    size={this.props.size ? this.props.size : "150"}
                    round={this.props.round ? this.props.round : "10px"}
                    onClick={this.props.onClickHandler ? this.props.onClickHandler : null}
                />;
            }
        }
    }
}

const mapStateToProps = state => {
	return {
        info: state.auth.info,
		currentCompany: state.company
	}
};

export default connect(mapStateToProps, null)(withRouter(CompanyAvatar));
