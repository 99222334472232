import React, { PureComponent } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row
} from 'reactstrap';
import * as actions from '../../../store/actions/index';
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormAddCompany extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            edit: false,
            event_id: 0,
            form:  {
                id: 0,
                title: '',
                description: '',
                email: '',
            }
        }
    }

    componentDidMount() {
        this.loadForm();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;

        this.setState({
            isOpen: open
        });
    }

    resetForm = () => {
        this.setState({
            form: {
                id: 0,
                title: '',
                description: '',
                email: '',
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {
            title: this.state.form.title,
            description: this.state.form.description,
            email: this.state.form.email
        };

        authenticatedInstance.post('/company', updatedForm).then(response => {
            toastfy('success', 'Empresa adicionada com sucesso!');
            this.props.returnFunction({
                id: response.data.id,
                slug: response.data.slug,
                title: this.state.form.title,
                description: this.state.form.description,
                email: this.state.form.email
            });
            this.resetForm();
            return response.data;
        }).catch(error => {
            toastfy('error', 'Houve algum problema ao tentar adicionar o item...');
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    expenseRevenue = () => {
        let val = 0;
        if(this.state.form.revenue_expense === 0) {
            val = 1;
        }
        const form = {...this.state.form};
        form.revenue_expense = val;
        this.setState({form: form});
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    toogleSearch = (type) => {
        this.setState({searchType: type});
    };

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        Adicionar Empresa
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="title">Nome da Empresa:</Label>
                        <Input type="text" ref="title" name="title" id="title" placeholder="Nome da Empresa" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="description">Descrição:</Label>
                        <Input type="text" name="description" id="description" placeholder="Descrição" onChange={(event) => this.inputChangedHandler(event, 'description')} value={this.state.form.description} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="email">E-mail:</Label>
                        <Input type="text" name="email" id="email" placeholder="nome@email.com" onChange={(event) => this.inputChangedHandler(event, 'email')} value={this.state.form.email} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>Adicionar</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(null, mapDispatchProps)(withRouter(FormAddCompany));
