import { authenticatedInstance } from '../../api';
import * as actionTypes from './actionTypes';
// import loaderStore from "../loaderStore";
import loaderStore from "../storeRoot";
import { loaderStart } from './loader'; 
import { setCurrentCompany } from './company';


export const authStart = () => {
    loaderStore.dispatch(loaderStart());
    return {
        type: actionTypes.AUTH_START
    };
};

export const infoStart = () => {
    return {
        type: actionTypes.AUTH_INFO_START
    };
};

export const authSuccess = (authData) => {
    // console.log('action authSuccess', authData);
    return {
        type: actionTypes.AUTH_SUCCESS,
        authData: authData
    };
};

export const infoSuccess = (infoData) => {
    console.log('action infoSuccess', infoData);
    loaderStore.dispatch(setCurrentCompany(infoData.own_company));
    return {
        type: actionTypes.AUTH_INFO,
        infoData: infoData
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const isAuthenticated = () => {
    return {
        type: actionTypes.AUTH_IS_AUTHENTICATED
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
}

export const setCompanies = (companies) => {
    return {
        type: actionTypes.AUTH_UPDATE_COMPANIES,
        companies: Object.entries(companies)
    };
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        // const authData = {
        //     email: email,
        //     password: password,
        //     remember_me: true
        // }
        // notAuthenticatedInstance.post('/user/login', authData)
        //     .then(response => {
        //         // console.log(response);
        //         dispatch(authSuccess(response.data));
        //         // dispatch(info());
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         dispatch(authFail(err));
        //     });
    };
};

// export const info = () => {
//     return dispatch => {
//         dispatch(infoStart());
//         authenticatedInstance.get('/user')
//             .then(response => {
//                 // console.log('user:', response);
//                 dispatch(infoSuccess(response.data));
//             })
//             .catch(err => {
//                 console.log(err);
//             });
//     };
// };

export const info = () => {
    return dispatch => {
        // dispatch(infoStart());
        const info = localStorage.getItem('info');
        console.log("action info", info)
        if(info === null) {
            authenticatedInstance.get('/user')
                .then(response => {
                    dispatch(infoSuccess(response.data));
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            dispatch(infoSuccess(JSON.parse(info)));
        }
    };
};

