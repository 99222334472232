import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import authenticatedInstance from '../../api/authenticated';
import { 
    Button,
    Col,
    CardTitle,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Badge,
    Row,
    Table
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import LoaderContext from '../../context/LoaderContext';
import ModalDelete from '../Aux/ModalConfirm/ModalConfirm';
import withRouter from '../Aux/WithRouter/WithRouter';

class ViewOpus extends PureComponent {
    static contextType = LoaderContext;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            deleteId: 0,
            deleteShow: false,
            opus:  {
                id: 0,
                title: '',
                author: {
                  'id': '',
                  'name': ''
                },
                author_id: null,
                style: {
                  'id': '',
                  'title': ''
                },
                style_id: null,
                nota: '',
                diretorio: '',
                compoe_livros: '',
                link_audio: '',
                link_video: '',
                // solo: '',
                info: '',
                livro_pagina: '',
                instrumentations: [],
                selectedInstrumentOption: [],
                arrangements: []
            }
        }
    }

    async componentDidMount() {
        await this.loadOpus(this.props.currentCompanyId, this.props.params.id).then(() => {
            const updatedForm = {...this.props.currentOpus};
            this.setState({
                opus: updatedForm
            });
        });
    }

    loadOpus = async (companyId, opusId) => {
        this.props.setOpus(companyId, opusId);
    }

    addArrangement = (arrangement) => {

    }

    exclude = (id) => {
        this.setState({deleteId: id, deleteShow: true});
    }

    excludeDo = () => {
        this.setState({deleteShow: false});
        authenticatedInstance.delete('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.id +'/arrangements/' + this.state.deleteId).then(response => {
            this.loadOpus(this.props.currentCompanyId, this.props.params.id);
        }).catch(error => {
            this.setState({error: true, deleteShow: false});
            this.props.loaderStop();
        });
    }

    excludeCancel = () => {
        this.setState({deleteShow: false});
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    render() {
        let badgeColor = "secondary";
        if(this.props.currentOpus.nota < 3) {
            badgeColor = "danger";
        } else if(this.props.currentOpus.nota === 3) {
            badgeColor = "warning";
        } else if(this.props.currentOpus.nota > 3) {
            badgeColor = "success";
        }

        let deleteTitleModal = 'Excluir Arranjo';
        let deleteMessageModal = 'Tem certeza que deseja excluir?';
        let modalExclude = "";

        if(this.state.deleteShow) {
            modalExclude = (
                <ModalDelete
                    title={deleteTitleModal}
                    message={deleteMessageModal}
                    handler={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

        return (
            <Fragment>
                <CardTitle tag="h4">
                    <Row>
                        <Col sm={8}>
                            {this.props.currentOpus.title}
                        </Col>
                        <Col sm={4}>
                            <Badge color={badgeColor}>Nota {this.props.currentOpus.nota}</Badge>
                        </Col>
                    </Row>
                </CardTitle>
                <CardTitle tag="h5" className="font-italic">
                    <Row>
                        <Col sm={8}>
                            {this.props.currentOpus.author.name}
                        </Col>
                        <Col sm={4}>
                            Estilo: {this.props.currentOpus.style.title}
                        </Col>
                    </Row>
                </CardTitle>
                <CardText>
                    <Row>
                        <Col sm={12}>
                            <hr className="hr" />
                            <h6>Informações</h6>
                            {
                                this.props.currentOpus.info === '' ?
                                    <p>Sem informações.</p>
                                :
                                    this.props.currentOpus.info
                            }
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={2}>
                            <h6>Instrumentações:</h6>
                        </Col>
                        <Col sm={10}>
                            {
                                this.props.currentOpus.instrumentations.map((value) => {
                                    return (<Fragment><Badge>{value.title}</Badge>&nbsp;&nbsp;&nbsp;</Fragment>);
                                })
                                
                            }
                        </Col>
                    </Row>

                    {
                        this.props.currentOpus.link_video !== '' &&
                            <Row className="mt-4">
                                <Col sm={2}>
                                    <h6>Link Vídeo:</h6>
                                </Col>
                                <Col sm={10}>
                                    {this.props.currentOpus.link_video}
                                </Col>
                            </Row>
                    }
                    {
                        this.props.currentOpus.link_audio !== '' &&
                            <Row className="mt-2">
                                <Col sm={2}>
                                    <h6>Link Áudio:</h6>
                                </Col>
                                <Col sm={10}>
                                    {this.props.currentOpus.link_audio}
                                </Col>
                            </Row>
                    }
                    {
                        this.props.currentOpus.diretorio !== '' &&
                            <Row className="mt-2">
                                <Col sm={2}>
                                    <h6>Diretório:</h6>
                                </Col>
                                <Col sm={10}>
                                    {this.props.currentOpus.diretorio}
                                </Col>
                            </Row>
                    }
                    {
                        this.props.currentOpus.compoe_livros === 1 &&
                            <Row className="mt-2">
                                <Col sm={2}>
                                    <h6>Livro:</h6>
                                </Col>
                                <Col sm={10}>
                                    {this.props.currentOpus.livro_pagina}
                                </Col>
                            </Row>
                    }
                    <Row className="mt-5">
                        <Col sm="1">
                            <Link to={"/c/"+  this.props.currentCompany.slug +"/scores/opus/edit/" + this.props.currentOpus.id}>
                                <Button type="button" color="primary">Editar</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr className="hr" />
                            <Link to={"/c/"+  this.props.currentCompany.slug +"/scores/opus/" + this.props.currentOpus.id + "/arrangements/add"}>
                                <Button type="button" color="primary">Adicionar</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr className="hr" />
                            { this.props.currentOpus.arrangements.length > 0 ?
                            <Table dark>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Título</th>
                                        <th>Tom</th>
                                        <th>Arranjador</th>
                                        <th>Instrumentações</th>
                                        <th>Visualizar</th>
                                        <th>Editar</th>
                                        <th>Excluir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.props.currentOpus.arrangements.map((item, index) => {
                                        console.log('item: ', item);
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{item.id}</th>
                                                <td>{item.title}</td>
                                                <td>{item.tone.eu}</td>
                                                <td>{item.author_name}</td>
                                                <td>
                                                    {
                                                        item.instrumentations.map((instr, i) => {
                                                            return (<Fragment><Badge>{instr.title}</Badge>&nbsp;&nbsp;&nbsp;</Fragment>);
                                                        })
                                                    }
                                                    <br />
                                                    {
                                                        item.instruments.map((instr, i) => {
                                                            return (<Fragment><Badge color="success">{instr.abrev}{typeof instr.number == 'number' ? ' ' + instr.number : ''}</Badge>&nbsp;&nbsp;</Fragment>); 
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    <Link to={"/c/"+ this.props.currentCompany.slug + '/scores/opus/'+ this.props.currentOpus.id +'/arrangements/view/' + item.id}>
                                                        <Button color="primary">Visualizar</Button>&nbsp;&nbsp;
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={"/c/"+ this.props.currentCompany.slug + '/scores/opus/' + this.props.currentOpus.id + "/arrangements/edit/" + item.id}>
                                                        <Button color="warning">Editar</Button>
                                                    </Link>
                                                </td>
                                                <td><Button color="danger" onClick={() => this.exclude(item.id)}>Excluir</Button></td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </Table>
                            : <p>Nenhum arranjo registrado.</p>
                            }
                        </Col>
                    </Row>
                </CardText>
                {modalExclude}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentCompany: state.company,
        currentOpus: state.opus
	}
};

const mapDispatchProps = dispatch => {
	return {
		setOpus: (companyId, opusId) => dispatch(actions.setOpus(companyId, opusId)),
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(ViewOpus));