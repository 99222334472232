import React, { PureComponent } from 'react';
import authenticatedInstance from '../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../Aux/Toastfy/Toastfy';
import { 
    Button,
    // CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../store/actions/index';
// import ModalGeneric from '../Aux/ModalGeneric/ModalGeneric';
import SimpleItemAddSubmit from '../../api/simpleItemAddSubmit';
import SympleItensGet from '../../api/sympleItensGet';
import withRouter from '../Aux/WithRouter/WithRouter';

class FormArrangementPart extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            form:  {
                id: 0,
                arrangement_id: 0,
                instrument_id: 0,
                instrument: {id: 0, name: ''},
                number: '',
                obs: ''
            }
        }
    }

    componentDidMount() {
        this.loadForm();
    }
   
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        if(this.props.part !== null) {
            let part = {...this.props.part};
            part["instrument"] = {
                id: part.instrument_id,
                title: part.title
            };
            this.setState({form: part});
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = () => {
        const updatedForm = {...this.state.form};
        updatedForm.arrangement_id = this.props.currentArrangementId;

        if(this.props.edit) {
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.currentOpusId +'/arrangements/' + this.props.currentArrangementId + '/instruments/edit/'+ this.state.form.id, updatedForm).then(response => {
                toastfy("success", 'Arranjo alterado com sucesso!');
                this.props.returnInstrumentPart({
                    id: response.data.id,
                    title: response.data.instrument.title,
                    abrev: response.data.instrument.abrev,
                    obs: response.data.obs,
                    number: parseInt(response.data.number),
                    ordering: parseInt(response.data.ordering),
                    file_hash: response.data.file_hash,
                    extension: response.data.extension,
                    thumbnail: response.data.thumbnail,
                    processing: response.data.processing
                });
                this.props.show(response.data.id, false, false);
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar o Arranjo...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.currentOpusId +'/arrangements/' + this.props.currentArrangementId + '/instruments/add', updatedForm).then(response => {
                toastfy("success", 'Obra adicionada com sucesso!');
                this.props.returnInstrumentPart({
                    id: response.data.id,
                    title: response.data.instrument.title,
                    abrev: response.data.instrument.abrev,
                    obs: response.data.obs,
                    number: parseInt(response.data.number),
                    ordering: parseInt(response.data.ordering),
                    file_hash: response.data.file_hash,
                    extension: response.data.extension,
                    thumbnail: response.data.thumbnail,
                    processing: response.data.processing
                });
                this.props.show(response.data.id, false, true);
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        // console.log('handleInstrumentChange', newValue);
        const updatedForm = {...this.state.form};
        updatedForm.instrument_id = newValue.value;
        updatedForm.instrument.id = newValue.value;
        updatedForm.instrument.title = newValue.label;
        this.setState({ form: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/instruments', 'title');
        const updatedForm = {...this.state.form};
        updatedForm.instrument = {
            id: newInstrument.id,
            title: newInstrument.title
        };
        updatedForm.instrument_id = newInstrument.id;
        this.setState({
          form: updatedForm
        });
    }

    filterOptionsHandler = (inputValue, path, label) => {
        if(inputValue.length >= 3) {
            return authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/' + path + '?search=' + label + ':' + inputValue)
                .then(response => {
                    let optionsArray = [];
                    response.data[path].data.map((option) => {
                        optionsArray.push({
                            value: parseInt(option.id),
                            label: option[label]
                        });
                        return optionsArray;
                    });
                    return optionsArray;
                }).catch(function(error) {
                    console.log(error);
                });
        }
    };

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.props.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                <Col sm={12}>
                        {this.props.currentArrangementTitle}
                    </Col>
                    {/* <Col sm={4}>
                        Parte
                    </Col> */}
                </Row>
                <Row>
                    <Col sm={12}>
                        {this.props.currentArrangementAuthor}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Form>
                    <FormGroup row>
                        <Col sm={9}>
                            <Label for="title">Instrumento</Label>
                            <SelectAsync
                            id="instrument"
                            name="instrument"
                            matchPos="any"
                            value={{value: this.state.form.instrument.id, label: this.state.form.instrument.title}}
                            loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/' + this.props.currentCompanyId + '/instruments', 'title')}
                            onCreateOption={this.onCreateInstruments}
                            onChange={this.handleInstrumentChange}
                        />
                        </Col>
                        <Col sm={3}>
                            <Label for="number">Número</Label>
                            <Input type="number" name="number" id="number" onChange={(event) => this.inputChangedHandler(event, 'number')} value={(parseInt(this.state.form.number) === 0 ? '' : this.state.form.number)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Label for="obs">Observação:</Label>
                            <Input type="text" name="obs" id="obs" placeholder="Observação" onChange={(event) => this.inputChangedHandler(event, 'obs')} value={this.state.form.obs} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col sm={12}>
                            <Button color="primary" onClick={() => this.submitHandler()}>Salvar</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentOpus: state.opus
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormArrangementPart));
