import authenticatedInstance from './authenticated';
import toastfy from '../components/Aux/Toastfy/Toastfy';

const simpleItemAddSubmit = async (data, path, label) => {
    const order = {
        [label]: data
    }
    try {
        const response = await authenticatedInstance.post(path, order);
        toastfy("success", 'Item adicionado com sucesso!');
        return response.data;
    } catch (error) {
        toastfy("error", 'Houve algum problema ao tentar adicionar o item. Aguarde um momento e tente novamente.');
        return error;
    }
};

export default simpleItemAddSubmit;