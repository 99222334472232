import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import authenticatedInstance from '../../../api/authenticated';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    CardTitle,
    CardBody,
    Row,
    Col,
    Card,
    CardHeader,
    CardText,
    CardSubtitle
} from 'reactstrap';
import { 
    FiCalendar, 
    FiMapPin 
} from "react-icons/fi";
import googleMapsSearchLink from '../../Aux/AddressForm/GoogleMapsSearchLink';
import withRouter from '../../Aux/WithRouter/WithRouter';
import './Event.css';

class Events extends Component {

	constructor(props) {
	  super(props);
      this.state = {
        error: false,
        events: null
      };
	}

    componentDidMount() {
        this.loadEvents();
    }

    loadEvents = async () => {
        // Load Events
        try {
            authenticatedInstance.get('/collection/'+ this.props.currentCompanyId +'/events')
            .then(response => {
                this.setState({
                    events: response.data.events
                });
            });
        } catch (error) {
            this.setState({ error: true });
        }
    }

	render() {
        moment.locale('pt-br');

    	return (
            <Fragment>
                <CardTitle tag="h5">
                    Próximos eventos:
                </CardTitle>
                <CardBody>
                <Row xs={3}>
                {
                    this.state.events != null ?
                        this.state.events.map((event, index) => {
                            const link = "/c/"+ this.props.currentCompany.slug +"/events/view/" + event.id;
                            const dateTimeObj = moment(event.date_time);
                            return (
                            <Col key={index}>
                                <Card
                                    className="my-2"
                                    color="primary"
                                    outline
                                    style={{
                                        width: '18rem'
                                    }}
                                >
                                    <CardHeader>
                                        <small>
                                            <Link to={link}>
                                                <FiCalendar />&nbsp;&nbsp;{dateTimeObj.format('LLL')}
                                            </Link>
                                        </small>
                                    </CardHeader>
                                    <CardBody>
                                        <CardSubtitle>
                                        {event.local === null ? '' :
                                                event.local.address === null ?
                                                    (<small><FiMapPin />&nbsp;&nbsp;{event.local.title}</small>)
                                                    :
                                                    (<small>
                                                    <a 
                                                        href={googleMapsSearchLink(event.local.address.address_1, event.local.address.number, event.local.address.city)}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                    >
                                                        <FiMapPin />&nbsp;&nbsp;{event.local.title}
                                                    </a>
                                                    </small>)
                                        }
                                        </CardSubtitle>
                                        <CardTitle tag="h5">
                                            {event.title}
                                        </CardTitle>
                                        <CardText>
                                            {event.description}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            );
                        })
                        :
                        <p>Nenhum evento disponível.</p>
                }
                </Row>
                </CardBody>
            </Fragment>
        );
	}
 }


 const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentCompany: state.company,
	}
};

export default connect(mapStateToProps, null)(withRouter(Events));
