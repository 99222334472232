import React, { PureComponent } from 'react';
import AddrCountries from './AddrCountries';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import withRouter from '../WithRouter/WithRouter';
class AddressForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            countries: [],
            country: {value: 0, label: ""}
        }
    }

    componentDidMount() {
        let optionsArray = [];
        AddrCountries.map((option) => {
            optionsArray.push({
                value: option.id,
                label: option.country_name
            });
            return optionsArray;
        });

        this.setState({
            countries: optionsArray
        });
    }

    inputChanged = (event, inputIdentifier) => {
        let own_company = {address: {}};
        this.props.inputChanged(event, own_company.address[inputIdentifier]);
    }

    inputCreatableChanged = (newValue, actionMeta) => {
        console.log('label:', actionMeta.name);
        console.log('value:', newValue);
        // this.props.inputChanged({target: {value: newValue.id}}, 'country');
    };

    render() {
        const currentCountry = {
            value: typeof this.props.fields.address.country.id !== "undefined" ? this.props.fields.address.country.id : "",
            label: typeof this.props.fields.address.country.country_name !== "undefined" ? this.props.fields.address.country.country_name : ""
        };

        return (
            <Form>
                <FormGroup row>
                    <Col sm={4}>
                        <Label for="country">Country</Label>
                        <CreatableSelect
                            id="country"
                            name="country"
                            matchPos="any"
                            options={this.state.countries}
                            onChange={this.props.inputCreatableChanged}
                            value={currentCountry}
                        />
                    </Col>
                    <Col sm={4}>
                        <Label for="name">State/Province</Label>
                        <Input
                            type="text"
                            name="state"
                            id="state"
                            placeholder="State or Province name"
                            onChange={(event) => this.props.inputChanged(event, 'state')}
                            defaultValue={this.props.fields.address.state}
                        />
                    </Col>
                    <Col sm={4}>
                        <Label for="name">ZIP</Label>
                        <Input
                            type="text"
                            name="zip"
                            id="zip"
                            placeholder="XXXXXXXX"
                            onChange={(event) => this.props.inputChanged(event, 'zip')}
                            defaultValue={this.props.fields.address.zip}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={6}>
                        <Label for="address_1">Address Line 1</Label>
                        <Input
                            type="text"
                            id="address_1"
                            name="address_1"
                            placeholder="Address (street, avenue, etc)"
                            onChange={(event) => this.props.inputChanged(event, 'address_1')}
                            defaultValue={this.props.fields.address.address_1}
                        />
                    </Col>
                    <Col sm={3}>
                        <Label for="number">Number</Label>
                        <Input
                            type="text"
                            id="number"
                            name="number"
                            placeholder="Number (or S/N)"
                            onChange={(event) => this.props.inputChanged(event, 'number')}
                            defaultValue={this.props.fields.address.number}
                        />
                    </Col>
                    <Col sm={3}>
                        <Label for="number">Complement</Label>
                        <Input
                            type="text"
                            id="compl"
                            name="compl"
                            placeholder="complement"
                            onChange={(event) => this.props.inputChanged(event, 'compl')}
                            defaultValue={this.props.fields.address.compl}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={6}>
                        <Label for="address_2">Address Line 2</Label>
                        <Input
                            type="text"
                            name="address_2"
                            id="address_2"
                            onChange={(event) => this.props.inputChanged(event, 'address_2')}
                            placeholder="Other address informations"
                            defaultValue={this.props.fields.address.address_2}
                        />
                    </Col>
                    <Col sm={6}>
                        <Label for="neighborhood">Neighborhood</Label>
                        <Input
                            type="text"
                            name="neighborhood"
                            id="neighborhood"
                            placeholder="neighborhood"
                            onChange={(event) => this.props.inputChanged(event, 'neighborhood')}
                            defaultValue={this.props.fields.address.neighborhood}
                        />
                    </Col>
                </FormGroup>
            </Form>
        );
    }
}

export default withRouter(AddressForm);