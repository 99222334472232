import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import 'moment/locale/pt-br';
import {
    Col,
    Card,
    CardBody,
    CardText,
    Container,
    Row,
    ButtonGroup,
    Button
} from 'reactstrap';
import { ReactSortable } from "react-sortablejs";
import * as actions from '../../../store/actions/index';
import authenticatedInstance from '../../../api/authenticated';
import LoaderContext from '../../../context/LoaderContext';
import './Event.css';
import { 
    FiEdit,
    FiTrash2,
    FiMove, 
} from "react-icons/fi";
import { 
    FaCircleUser,
    FaUserPlus
} from "react-icons/fa6";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import FormStaff from './FormStaff';
import FormStaffMember from './FormStaffMember';
import ModalDelete from '../../Aux/ModalDelete/ModalDelete';
import withRouter from '../../Aux/WithRouter/WithRouter';

class EventStaffMembers extends Component {
	static contextType = LoaderContext;

	constructor(props) {
	  super(props);
      this.state = {
        error: false,
        formStaffOpen: false,
        formStaffEdit: false,
        formStaffEditData: null,
        formStaffEditId: null,
        formStaffEditPosition: null,
        formStaffMemberOpen: false,
        formStaffMemberEdit: false,
        formStaffMemberEditData: null,
        formStaffMemberPosition: null,
        membersTemp: [],
        event_id: null,
        staff: [],
        exclude: {
            entity: 'anything',
            anything: {
                id: 0,
                show: false,
                title: "",
                message: ""
            },
            staff: {
                id: 0,
                position: 0,
                show: false,
                title: "Excluir Função/Cargo",
                message: "Deseja realmente excluir essa função/cargo?"
            },
            member: {
                id: 0,
                position: 0,
                memberPosition: 0,
                show: false,
                title: "Excluir Membro",
                message: "Deseja realmente excluir esse membro?"
            }
        },
        events: null
      };
	}

    componentDidMount() {
        this.setState({
            event_id: this.props.event_id,
            staff: this.props.staff
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event_id !== this.props.event_id) {
            this.setState({
                event_id: this.props.event_id,
                staff: this.props.staff
            });
        }
    }

    parseNumber = (value) => {
        if(
            typeof value === 'number' 
            && !isNaN(value)
            && parseInt(value) > 0
        ) {
            return ' ' + value.toString();
        }

        return '';
    }
    
    staffAdd = () => {
        this.setState({
            formStaffOpen: !this.state.formStaffOpen,
            formStaffEdit: false,
            formStaffEditData: null
        });
    }

    staffEdit = (position) => {
        this.setState({
            formStaffOpen: !this.state.formStaffOpen,
            formStaffEdit: true,
            formStaffEditPosition: position,
            formStaffEditData: this.state.staff[position]
        });
    }

    addStaff = (staffNew) => {
        const staff = Object.values({...this.state.staff});
        if(this.state.formStaffEdit) {
            let data = {...this.state.formStaffEditData};
            data['staff_id'] = staffNew['id'];
            data['title'] = staffNew['title'];
            data['abrev'] = staffNew['abrev'];
            data['obs'] = staffNew['obs'];
            data['budget'] = staffNew['budget'];
            data['number'] = staffNew['number'];
            staff[this.state.formStaffEditPosition] = data;
        } else {
            staffNew["members"] = [];
            staff.push(staffNew);
        }
        this.setState({
            formStaffOpen: false,
            staff: staff
        });
    }

    setStaffOrder = (staffs) => {
        this.setState({
            staff: staffs
        });
    }

    staffOrderEnd = (evt) => {
        let ordering = [];
        this.state.staff.map((staff, index) => {
            ordering.push({position: index, staffId: staff.id});
            return ordering;
        })

        authenticatedInstance.put(
            '/collection/' + this.props.currentCompanyId + 
            '/event/' + this.props.event_id +
            '/ordenate_staffs',
            {order: ordering}).then(response => {
            toastfy("success", 'Cargos reordenados com sucesso!');
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao tentar reordenar os cargos, tente novamente');
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    staffMemberAddForm = (staffId, staffPosition, instrument, budget) => {
        const formStaffMemberEditData = {
            instrument: {id: instrument.id, title: instrument.title},
            budget: budget
        };
        this.setState({
            formStaffMemberOpen: !this.state.formStaffMemberOpen,
            formStaffMemberEdit: false,
            formStaffMemberEditData: formStaffMemberEditData,
            formStaffEditId: staffId,
            formStaffEditPosition: staffPosition
        });
    }

    staffMemberClose = () => {
        this.setState({
            formStaffMemberOpen: false,
            formStaffMemberEdit: false,
            formStaffMemberEditData: null,
            formStaffEditId: null,
            formStaffEditPosition: null
        });
    }

    addStaffMember = (staffMember) => {
        const staff = Object.values({...this.state.staff});
        if(this.state.formStaffMemberEdit) {
            let data = {...this.state.formStaffMemberEditData};
            data['obs'] = staffMember['obs'];
            data['budget'] = staffMember['budget'];
            staff[this.state.formStaffEditPosition]["members"][this.state.formStaffMemberPosition] = data;
        } else {
            staff[this.state.formStaffEditPosition]["members"].push(staffMember);
        }
        this.setState({
            formStaffMemberOpen: false,
            staff: staff
        });
    }

    staffMemberEdit = (position, memberPosition) => {
        const editData = this.state.staff[position]["members"][memberPosition];
        this.setState({
            formStaffMemberOpen: !this.state.formStaffMemberOpen,
            formStaffMemberEdit: true,
            formStaffEditPosition: position,
            formStaffMemberPosition: memberPosition,
            formStaffMemberEditData: editData
        });
    }

    setStaffMembersOrder = (members) => {
        let temp = this.state.membersTemp;
        if(Array.isArray(temp)) {
            temp.push(members);
            this.setState({membersTemp: temp});
        }
    }

    setStaffMembersOrderEnd = (evt) => {
        const staffIdFrom = evt.from.getAttribute("id").replace("staff-", "");
        const staffIdTo = evt.to.getAttribute("id").replace("staff-", "");

        const staff = Object.values({...this.state.staff});
        let order = {};
        if(staffIdFrom === staffIdTo) {
            if (typeof this.state.membersTemp[1] !== 'undefined') {
                const index = staff.findIndex(item => item.id === parseInt(staffIdTo));

                staff[index].members = this.state.membersTemp[1];
                order = {
                    staffId: staffIdTo,
                    members: this.state.membersTemp[1]
                };

                this.setState({
                    staff: staff
                });

                this.setStaffMembersOrderEndSubmit(order);
            }
        } else {
            var indexTo = staff.findIndex(item => item.id === parseInt(staffIdTo));
            var indexFrom = staff.findIndex(item => item.id === parseInt(staffIdFrom));

            staff[indexTo].members = this.state.membersTemp[1];
            
            order = {
                staffId: staffIdTo,
                movedMemberId: this.state.membersTemp[1][evt.newDraggableIndex]['id'],
                members: this.state.membersTemp[1]
            };
            
            staff[indexFrom].members = this.state.membersTemp[2];
            
            this.setState({
                staff: staff
            });

            this.setStaffMembersOrderEndSubmit(order);
        }
    }

    setStaffMembersOrderEndSubmit = (dataObj) => {
        authenticatedInstance.put(
            '/collection/' + this.props.currentCompanyId + 
            '/event/' + this.props.event_id +
            '/ordenate_staff_members',
            dataObj).then(response => {
            toastfy("success", 'Profissionais reordenados com sucesso!');
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao tentar reordenar os profissionais, tente novamente');
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    setStaffMembersOnUnchoose = (evt) => {
        this.setState({membersTemp: []});
    }

    exclude = (id, position, entity, path, memberPosition) => {
        console.log("exclude", id, position, entity, path, memberPosition);
        const exclude = {...this.state.exclude};
        exclude["entity"] = entity;
        exclude[entity]["id"] = id;
        exclude[entity]["position"] = position;
        exclude[entity]["show"] = true;
        if(memberPosition !== null) {
            exclude[entity]["memberPosition"] = memberPosition;
        }
        exclude[entity]["path"] = '/collection/' + this.props.currentCompanyId + '/event/' + this.props.event_id + path;
        this.setState({exclude: exclude});
    }

    excludeDo = () => {
        const staff = Object.values({...this.state.staff});
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
        switch(this.state.exclude.entity) {
            case "staff":
                delete staff[exclude[this.state.exclude.entity]["position"]];
                break;
            case "member":
                staff[exclude[this.state.exclude.entity]["position"]]["members"].splice(exclude[this.state.exclude.entity]["memberPosition"], 1);
                break;
            default:
                break;
        }
        this.setState({staff: staff});
    }

    excludeCancel = () => {
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
    }

	render() {
        let modalExclude = "";
        if(this.state.exclude[this.state.exclude.entity]["show"]) {
            modalExclude = (
                <ModalDelete
                    title={this.state.exclude[this.state.exclude.entity]["title"]}
                    message={this.state.exclude[this.state.exclude.entity]["message"]}
                    path={this.state.exclude[this.state.exclude.entity]["path"]}
                    actionOnSuccess={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

    	return (
        <Fragment>
            <Card
                className="event-card"
                color="light"
                outline
                style={{
                    width: '100%    '
                }}
            >
                <CardBody>
                    <CardText>
                        <Row>
                            <Col sm={5}>
                                <Button type="button" color="primary" onClick={this.staffAdd}><AiOutlineUsergroupAdd /> Adicionar Cargo/Função</Button>
                            </Col>
                        </Row>
                        <hr/>
                        {
                        typeof this.state.staff != 'undefined' && this.state.staff.length > 0 ?
                            <ReactSortable
                                group="shared"
                                animation={200}
                                delay={1}
                                swap
                                multiDrag
                                setList={this.setStaffOrder}
                                list={this.state.staff}
                                handle=".handle"
                                onEnd={(evt) => this.staffOrderEnd(evt)}
                            >
                            <Fragment>
                            {this.state.staff.map((staff, index) => {
                            return (
                                <Container key={index} className="bg-light border item-list-box">
                                    <Row>
                                        <Col sm={1}>
                                            <FiMove className="handle" />
                                        </Col>
                                        <Col sm={3}>
                                            <h5>{staff.title}{this.parseNumber(staff.number)}</h5>
                                            <p>{staff.obs}</p>
                                            <p>Cachê padrão: R$ {staff.budget}</p>
                                        </Col>
                                        <Col sm={1}>
                                            <ButtonGroup vertical>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(staffId, staffPosition, instrument, budget) => this.staffMemberAddForm(staff.id, index, {id: staff.instrument_id, title: staff.title}, staff.budget)}
                                                    // active={rSelected === 1}
                                                >
                                                    <FaUserPlus title="Adicionar Profissional" />
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(position) => this.staffEdit(index)}
                                                    // active={rSelected === 1}
                                                >
                                                    <FiEdit title="Editar Cargo" />
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    disabled={staff.members.length > 0 ? true : false}
                                                    onClick={(id, position, entity, path) => this.exclude(staff.id, index, "staff", "/staff/" + staff.id)}
                                                    // active={rSelected === 2}
                                                >
                                                    <FiTrash2 title="Apagar Cargo" />
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                        <Col sm={7}>
                                            <ReactSortable
                                                group="staff_members"
                                                animation={200}
                                                delay={1}
                                                swap
                                                multiDrag
                                                setList={this.setStaffMembersOrder}
                                                list={staff.members}
                                                handle=".handle"
                                                id={"staff-" + staff.id}
                                                onEnd={this.setStaffMembersOrderEnd}
                                                onUnchoose={this.setStaffMembersOnUnchoose}
                                            >
                                            {
                                            staff.members.map((member, mIndex) => {
                                                return (
                                                <Container key={mIndex} className="bg-light border staff-member-box">
                                                    <Row>
                                                    <Col sm={1}>
                                                        {
                                                            (
                                                                typeof member.photo != 'undefined' 
                                                                && member.photo !== null 
                                                                && member.photo.path !== null
                                                                && member.photo.path !== ""
                                                            ) ?
                                                                <img class="handle user-avatar-small" alt={member.name} src={member.photo.path} />
                                                            :
                                                                <FaCircleUser className="handle" size="1.7em" />
                                                        }
                                                    </Col>
                                                    <Col sm={6}>
                                                        <h5>{member.name}</h5>
                                                        <p>{member.obs === '' ? '' : member.obs + ' - '}<span>Budget: R$ {member.budget}</span></p>
                                                    </Col>
                                                    <Col sm={5}>
                                                    <ButtonGroup>
                                                        <Button
                                                            color="primary"
                                                            outline
                                                            onClick={(position, memberPosition) => this.staffMemberEdit(index, mIndex)}
                                                            // active={rSelected === 1}
                                                        >
                                                            <FiEdit title="Editar Membro" />
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            outline
                                                            onClick={(id, position, entity, path) => this.exclude(staff.id, index, "member", "/staff/" + staff.id + "/remove_member/" + member.id, mIndex)}
                                                            // active={rSelected === 2}
                                                        >
                                                            <FiTrash2 title="Apagar Membro" />
                                                        </Button>
                                                    </ButtonGroup>
                                                    </Col>
                                                    </Row>
                                                </Container>
                                                );
                                            })
                                        }
                                        </ReactSortable>
                                        </Col>
                                    </Row>
                                </Container>
                            );
                            })}
                            </Fragment>
                            </ReactSortable>
                            : 
                            <p>Sem pessoas alocadas no staff.</p>
                        }
                    </CardText>
                </CardBody>
            </Card>
            <FormStaff
                returnFunction={(staff) => this.addStaff(staff)}
                eventId={this.state.event_id}
                isOpen={this.state.formStaffOpen}
                onClosed={this.staffAdd}
                edit={this.state.formStaffEdit}
                staff={this.state.formStaffEditData}
            />
            <FormStaffMember
                returnFunction={(staffMember) => this.addStaffMember(staffMember)}
                eventId={this.state.event_id}
                staffId={this.state.formStaffEditId}
                isOpen={this.state.formStaffMemberOpen}
                onClosed={this.staffMemberClose}
                edit={this.state.formStaffMemberEdit}
                member={this.state.formStaffMemberEditData}
            />
            {modalExclude}
        </Fragment>
        );
	}
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(EventStaffMembers));
