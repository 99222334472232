import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notAuthenticatedInstance } from '../../../api';
import toastfy from '../../Aux/Toastfy/Toastfy';
import {
	Container, Col, Form,
   FormGroup, Label, Input,
   Button, FormText, FormFeedback
} from 'reactstrap';
import { Navigate } from 'react-router-dom';
import LoaderContext from '../../../context/LoaderContext';
import * as actions from '../../../store/actions/index';
import './SignUpForm.css';

class SignUpForm extends Component {
	static contextType = LoaderContext;

	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			password: '',
			password_retype: '',
			success: false,
			validate: {
				emailState: '',
				nameState: ''
			},
		}
		this.handleChange = this.handleChange.bind(this);
	}

	validateName(e) {
		const nameRex = /(\w.+\s).+/;
		const { validate } = this.state;
		if (nameRex.test(e.target.value)) {
			validate.nameState = 'has-success';
		} else {
			validate.nameState = 'has-danger';
		}
		this.setState({ validate });
	}

	validateEmail(e) {
		const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const { validate } = this.state;
		if (emailRex.test(e.target.value)) {
			validate.emailState = 'has-success';
		} else {
			validate.emailState = 'has-danger';
		}
		this.setState({ validate });
	}

	handleChange = async (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;
		this.setState({
			[ name ]: value,
		});
	}

	submitForm(e) {
		e.preventDefault();
		
        const bodyFormData = {
			'name': this.state.name,
			'email': this.state.email,
			'password': this.state.password
		};

        try {
            notAuthenticatedInstance.post(
				'/user/signup',
				bodyFormData
			)
            .then(response => {
                toastfy("success", 'Conta criada com sucesso!');
				this.setState({success: true});
				// this.props.onAuth(this.state.email, this.state.password);
                // return response.data;
            });
        } catch (error) {
			toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
			this.setState({error: true});
			this.props.loaderStop();
        }
	}

	render() {
		const { name, email, password, password_retype } = this.state;

		if(this.props.isAuth) {
			return <Navigate to="/my" />;
		}
		if(this.state.success) {
			return (
				<Container className="SignUpForm" style={{textAlign: 'center'}}>
					<h3>Sucesso na criação de sua conta!</h3>
					<p>Você receberá um e-mail com um link de validação. Clique no botão "Verificar a Conta".</p>
					<p>Se não receber o e-mail, procure na caixa de spam.</p>
				</Container>
			);
		} else {
			return (
				<Container className="SignUpForm">
					<h2>Sign Up</h2>
					<Form className="form" onSubmit={ (e) => this.submitForm(e) }>
						<Col>
							<FormGroup>
								<Label>Name</Label>
								<Input
									type="text"
									name="name"
									id="name"
									placeholder="John Smith"
									value={ name }
									valid={ this.state.validate.nameState === 'has-success' }
									invalid={ this.state.validate.nameState === 'has-danger' }
									onChange={ (e) => {
										this.validateName(e)
										this.handleChange(e)
									}}
								/>
								<FormFeedback valid>
									That's a tasty looking email you've got there.
								</FormFeedback>
								<FormFeedback>
									Uh oh! Looks like there is an issue with your email. Please input a correct email.
								</FormFeedback>
								<FormText>Your username is most likely your email.</FormText>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>E-mail</Label>
								<Input
									type="email"
									name="email"
									id="email"
									placeholder="myemail@email.com"
									value={ email }
									valid={ this.state.validate.emailState === 'has-success' }
									invalid={ this.state.validate.emailState === 'has-danger' }
									onChange={ (e) => {
										this.validateEmail(e)
										this.handleChange(e)
									}}
								/>
								<FormFeedback valid>
									That's a tasty looking email you've got there.
								</FormFeedback>
								<FormFeedback>
									Uh oh! Looks like there is an issue with your email. Please input a correct email.
								</FormFeedback>
								<FormText>Your username is most likely your email.</FormText>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label for="password">Password</Label>
								<Input
									type="password"
									name="password"
									id="password"
									placeholder="********"
									value={ password }
									onChange={ (e) => this.handleChange(e) }
								/>
							</FormGroup>
							<FormGroup>
								<Label for="password_retype">Retype Password</Label>
								<Input
									type="password"
									name="password_retype"
									id="password_retype"
									placeholder="********"
									value={ password_retype }
									onChange={ (e) => this.handleChange(e) }
								/>
							</FormGroup>
						</Col>
						<Button>Submit</Button>
					</Form>
				</Container>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		token: state.auth.access_token,
        isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
		onAuth: (email, password) => dispatch(actions.auth(email, password)),
		onInfo: () => dispatch(actions.info()),
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(SignUpForm);
