import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CompanyAvatar from './CompanyAvatar';
import authenticatedInstance from '../../../api/authenticated';
import * as actions from '../../../store/actions/index';
import { 
    FiEdit,
    FiCheck,
    FiCornerUpLeft,
    FiCornerUpRight,
    FiTrash2
} from "react-icons/fi";
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import { 
    Button,
    ButtonGroup,
    Col, 
    FormGroup, 
    Input 
} from 'reactstrap';
import toastfy from '../Toastfy/Toastfy';
import ModalGeneric from '../ModalGeneric/ModalGeneric';
import ModalDelete from '../ModalDelete/ModalDelete';
import withRouter from '../WithRouter/WithRouter';
class CompanyAvatarForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            edit: true,
            avatar: '',
            avatarId: 0,
            avatarScale: 1,
            avatarEditor: null,
            avatarData: '',
            avatarRotate: 0,
            avatarBlank: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            avatarDeleted: false,
            avatarChanged: false,
            width: 250,
            height: 250
        }
    }

    componentDidMount() {
        let params = {
            edit: true,
            avatarId: 0,
            width: 250,
            height: 250
        };
        if(
            this.props.currentCompany.photos !== null
            && this.props.currentCompany.photos !== ''
        ) {
            params.edit = false;
            params.avatarId = this.props.currentCompany.photos.id;
        }
        if(this.props.type === 1) {
            params.width = 350;
            params.height = 100;
        }

        this.setState(params);
    }

    imageHandler = () => {
        const canvas = this.state.avatarEditor.getImage();

        const obj = this;
        canvas.toBlob(function (blob)  {
                obj.submitHandler(blob);
            },
            "image/jpeg",
            0.95
        );
    }

    submitHandler = (imgBlob) => {
        const formData = new FormData();
        formData.append("avatar", imgBlob, 'avatar.jpg');
        formData.append("company_id", this.props.currentCompany.id);
        formData.append("type", this.props.type);
        console.log("submitHandler 1", this.props.type);
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        authenticatedInstance.post(
            '/company/photo',
            formData,
            headers
        ).then(response => {
            toastfy("success", 'Avatar atualizado com sucesso!');
            this.setState({
                avatarId: response.data.id,
                avatarChanged: false,
                avatarDeleted: false
            });
            console.log("submitHandler 2", response.data);
            // this.props.setAvatar(response.data);
            this.props.updateAvatar(response.data);
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao tentar enviar o avatar...');
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    setEdit = (edit = true) => {
        this.setState({
            edit: edit
        });
    }

    setAvatarScale = (evt) => {
        if(
            this.state.avatar !== '' 
            && this.state.avatar !== this.state.avatarBlank
        ) {
            console.log("setAvatarSize", evt.target.value);
            this.setState({
                avatarScale: evt.target.value / 50,
                avatarDeleted: false,
                avatarChanged: true
            });
        }
    }
    
    setAvatarEditorRef = (editor) => {
        this.setState({avatarEditor: editor});
    }
    
    onAvatarPositionChange = () => {
        if(
            this.state.avatar !== '' 
            && this.state.avatar !== this.state.avatarBlank
        ) {
            this.setState({
                avatarDeleted: false,
                avatarChanged: true
            });
        }
    }
    
    onAvatarChange = () => {
        this.setState({
            avatarData: this.state.avatarEditor.getImageScaledToCanvas().toDataURL('image/jpeg', 1)
        });
    }
    
    setAvatar = (event) => {
        const file = event.target.files[0];
        this.setState({
            avatar: file, 
            avatarScale: 1, 
            avatarData: this.state.avatarEditor.getImageScaledToCanvas().toDataURL('image/jpeg', 1),
            avatarDeleted: false,
            avatarChanged: true
        });
    }
    
    setAvatarDropped = (dropped) => {
        this.setState({
            avatar: dropped, 
            avatarScale: 1,
            avatarDeleted: false,
            avatarChanged: true
        });
    }

    rotateAvatar = (grad) => {
        if(
            this.state.avatar !== '' 
            && this.state.avatar !== this.state.avatarBlank
        ) {
            this.setState({
                avatarRotate: this.state.avatarRotate + grad,
                avatarDeleted: false,
                avatarChanged: true
            });
        }
    }

    clearAvatar = () => {
        if(
            this.state.avatar !== '' 
            && this.state.avatar !== this.state.avatarBlank
        ) {
            this.setState({
                avatar: this.state.avatarBlank, 
                avatarScale: 1,
                avatarChanged: false,
                avatarDeleted: true
            });
        }
    }

    exclude = (exclude = true) => {
        this.setState({
            exclude: exclude
        });
    }

    excluded = () => {
        this.props.setAvatar(null);
        this.setEdit();
    }
    
    render() {
        let modalExclude = "";
        if(this.state.exclude) {
            modalExclude = (
                <ModalDelete
                    title="Excluir foto/avatar"
                    message="Deseja mesmo excluir essa foto?"
                    path={'/user/avatar/' + this.state.avatarId}
                    actionOnSuccess={() => this.excluded()}
                    cancel={(exclude) => this.exclude(false)}
                    show={true}
                />
            );
        }

        if(this.state.edit) {
            return (
                <ModalGeneric
                    title="Parte"
                    handler={null}
                    cancel={(show) => this.props.cancel(false)}
                    show={true}
                >
                    <FormGroup style={{textAlign: 'center'}} row>
                        <legend>Meu Avatar</legend>
                        <Col sm={12}>
                            <p>
                                <Input
                                    id="fileAvatar"
                                    name="file"
                                    type="file"
                                    onChange={(e) => this.setAvatar(e)}
                                />
                            </p>
                            <Dropzone
                                onDrop={(dropped) => this.setAvatarDropped(dropped[0])}
                                noClick
                                noKeyboard
                                style={{ width: '250px', height: '250px' }}
                            >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} >
                                    <AvatarEditor
                                        ref={this.setAvatarEditorRef}
                                        width={this.state.width}
                                        height={this.state.height}
                                        border={50}
                                        scale={this.state.avatarScale}
                                        borderRadius={10}
                                        image={this.state.avatar}
                                        onImageChange={this.onAvatarChange}  
                                        onImageReady={this.onAvatarChange}
                                        onPositionChange={this.onAvatarPositionChange}
                                        rotate={this.state.avatarRotate}
                                        style={{ 
                                            backgroundImage:`url(/assets/images/avatar-text-drop-image-400x400.jpg)`,
                                            backgroundPositionX: 'center'
                                        }}
                                    />
                                    <input {...getInputProps()} />
                                </div>
                            )}
                            </Dropzone>
                            <Input
                                id="exampleRange"
                                name="range"
                                type="range"
                                onChange={(evt) => this.setAvatarScale(evt)}
                                defaultValue="50"
                            />
                            <div style={{textAlign: 'center'}}>
                                <ButtonGroup
                                    // className="my-2"
                                    // size="sm"
                                >
                                    <Button
                                        color="success"
                                        disabled={!this.state.avatarChanged}
                                        onClick={this.imageHandler}
                                    >
                                        <FiCheck />
                                    </Button>
                                    <Button 
                                        outline
                                        onClick={(grad) => this.rotateAvatar(-90)}
                                    >
                                        <FiCornerUpLeft />
                                    </Button>
                                    <Button 
                                        outline
                                        onClick={(grad) => this.rotateAvatar(90)}
                                    >
                                        <FiCornerUpRight />
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={this.clearAvatar}
                                    >
                                        <FiTrash2 />
                                    </Button>
                                </ButtonGroup>
                            </div>
                            <img
                                src={this.state.avatarData} 
                                alt="avatar"
                                style={{ display: 'none' }}
                            />
                        </Col>
                    </FormGroup>
                </ModalGeneric>
            );
        }

        console.log("currentCompany", this.props.currentCompany.photos)
        return (
            <ModalGeneric
                title="Parte"
                handler={null}
                cancel={(show) => this.props.cancel(false)}
                show={true}
            >
                <FormGroup style={{textAlign: 'center'}} row>
                    <legend>Meu Avatar</legend>
                    <Col sm={12}>
                        {
                            this.props.type === 0 ? 
                            <CompanyAvatar /> 
                            : 
                            <img 
                                alt="avatar"
                                style={{maxWidth: '250px', maxHeight: '100px'}}
                                src={this.props.currentCompany.photos.background !== null ? this.props.currentCompany.photos.background.url : '/assets/images/back-to-school-6305092_1280.jpg'}
                            />
                        }
                        
                    </Col>
                </FormGroup>
                <FormGroup style={{textAlign: 'center'}} row>
                    <Col sm={12}>
                        <ButtonGroup
                            // className="my-2"
                            // size="sm"
                        >
                            <Button
                                color="success"
                                onClick={this.setEdit}
                            >
                                <FiEdit />
                            </Button>
                            <Button
                                color="danger"
                                onClick={(exclude) => this.exclude(true)}
                            >
                                <FiTrash2 />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </FormGroup>
                {modalExclude}
            </ModalGeneric>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompany: state.company
	}
};


const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop()),
        setAvatar: (photo) => dispatch(actions.setAvatar(photo)),
        updateAvatar: (photo) => dispatch(actions.updateAvatar(photo))
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(CompanyAvatarForm));
