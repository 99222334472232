import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notAuthenticatedInstance } from '../../../api';
import toastfy from '../../Aux/Toastfy/Toastfy';
import {
	Container
} from 'reactstrap';
import { Navigate } from 'react-router-dom';
import LoaderContext from '../../../context/LoaderContext';
import * as actions from '../../../store/actions/index';
import withRouter from '../../Aux/WithRouter/WithRouter';

class VerifyAccount extends Component {
	static contextType = LoaderContext;

	constructor(props) {
		super(props);
		this.state = {
			success: false
		}
	}

	componentDidMount() {
        this.verify(this.props.params.token);
	}

	verify = (token) => {

        try {
            notAuthenticatedInstance.get(
				'/user/confirm/' + token
			)
            .then(response => {
                toastfy("success", 'Conta verificada com sucesso!');
				this.props.onAuthSuccess(response.data);
				this.props.onInfo();
				this.setState({success: true});
            });
        } catch (error) {
			toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
			this.setState({error: true});
			this.props.loaderStop();
        }
	}

	render() {
		if(this.props.isAuth) {
			return <Navigate to="/my" />;
		}
		if(!this.state.success) {
			return (
				<Container className="VerifyAccount" style={{textAlign: 'center'}}>
					<p>Aguarde, verificando sua conta!</p>
					{/* <Navigate to="/my" /> */}
				</Container>
			);
		} else {
			return (
				<Container className="VerifyAccount" style={{textAlign: 'center'}}>
					<h3>Sucesso na verificação!</h3>
				</Container>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		token: state.auth.access_token,
        isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
		onAuth: (email, password) => dispatch(actions.auth(email, password)),
		onInfo: () => dispatch(actions.info()),
		onAuthSuccess: (authData) => dispatch(actions.authSuccess(authData)),
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(VerifyAccount));
