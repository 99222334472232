import React, { PureComponent, Fragment } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../../store/actions/index';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';
import SympleItensGet from '../../../api/sympleItensGet';
import { 
    FaCircleUser
} from "react-icons/fa6";
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormMember extends PureComponent {

    constructor(props) {
        super(props);

        // console.log("formMember constructor");

        this.state = {
            isOpen: true,
            searchType: "name",
            selectedInstrumentOption: [],
            // edit: false,
            form:  {
                id: 0,
                event_id: 0,
                staff_id: 0,
                user_id: 0,
                name: '',
                number: 0,
                obs: '',
                budget: 0,
                avatar: null
            }
        }
    }

    componentDidMount() {
        // console.log('componentDidMount')
        this.loadForm();
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            // console.log('componentDidUpdate')
            // console.log('componentDidUpdate prevProps', prevProps.member)
            // console.log('componentDidUpdate props', this.props.member)
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        const form = {...this.state.form};
        // console.log(this.props.member);
        let selectedInstrumentOption = [];
        if(this.props.member !== null) {
            
            // EDIT
            if(this.props.edit) {
                form.id = this.props.member.id;
                form.user_id = this.props.member.user_id;
                form.name = this.props.member.name
                form.obs = this.props.member.obs;
                form.budget = this.props.member.budget;
            } else {
                form.budget = this.props.member.budget;
            }
            
            selectedInstrumentOption = this.state.selectedInstrumentOption;
            if(typeof this.props.member.instrument !== 'undefined') {
                if(
                    selectedInstrumentOption.length === 0 || this.props.member.instrument.id !== selectedInstrumentOption[0]["value"]
                ) {
                    selectedInstrumentOption.push({
                        value: this.props.member.instrument.id,
                        label: this.props.member.instrument.title
                    });
                }
            }
        }

        // console.log('loadform member', this.props.member)
        // console.log('loadform instrument', this.props.member.instrument)
        // console.log('loadform', form)

        this.setState({
            isOpen: open,
            selectedInstrumentOption: selectedInstrumentOption,
            form: form
        });
    }

    resetForm = () => {
        this.setState({
            selectedInstrumentOption: [],
            // edit: false,
            form: {
                id: 0,
                name: '',
                user_id: 0,
                user: {id: 0, name: ''},
                number: '',
                obs: '',
                budget: 0
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {...this.state.form};

        if(this.props.edit) {
            delete updatedForm.id;
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/staff/' + this.props.staffId +'/update_member/' + this.state.form.id, updatedForm).then(response => {
                toastfy("success", 'Função alterada com sucesso!');
                this.props.returnFunction({
                    id: this.state.form.id,
                    obs: response.data.obs,
                    budget: response.data.budget,
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a função...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/staff/'+ this.props.staffId +'/add_member', updatedForm).then(response => {
                toastfy("success", 'Função adicionada com sucesso!');
                console.log("submit member", response.data)
                this.props.returnFunction({
                    id: response.data.id,
                    ordering: 0,
                    staff_id: response.data.id,
                    user_id: response.data.user_id,
                    name: updatedForm.name,
                    photo: {path: this.state.form.avatar},
                    obs: this.state.form.obs,
                    budget: this.state.form.budget,
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a funçao...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        let selectedInstrumentOption = this.state.selectedInstrumentOption;
        selectedInstrumentOption = newValue;
        this.setState({ selectedInstrumentOption: selectedInstrumentOption });
    };

    handleMemberSearch = (inputValue) => {
        if(inputValue.length >= 3) {
            let queryStr = "?name=" + inputValue;
            if(this.state.selectedInstrumentOption.length > 0) {
                let instrs = [];
                this.state.selectedInstrumentOption.map((option) => {
                    instrs.push(option.value);
                    return instrs;
                })
                queryStr += "&instruments=" + instrs.join(',');
            }

            return authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/staff/' + this.props.staffId + '/search_members' + queryStr)
                .then(response => {
                    let optionsArray = [];
                    response.data['itens'].map((option) => {
                        optionsArray.push({
                            value: parseInt(option.id),
                            label: option.name,
                            avatar: (option.photo !== null ? option.photo.path : null),
                            city: "Porto Alegre"
                        });
                        return optionsArray;
                    });
                    // console.log(optionsArray);
                    return optionsArray;
                }).catch(function(error) {
                    console.log(error);
                });
        }
    };

    handleMemberChange = (newValue, actionMeta) => {
        // console.log("handleMemberChange", newValue);
        const updatedForm = {...this.state.form};
        updatedForm.user_id = newValue.value;
        updatedForm.name = newValue.label;
        updatedForm.avatar = newValue.avatar;
        this.setState({ form: updatedForm });
    };

    onCreateMembers = async (name) => {
        const newMember = await SimpleItemAddSubmit(name, '/collection/'+ this.props.currentCompanyId +'/members', 'name');
        const updatedForm = {...this.state.form};
        updatedForm.user_id = newMember.id;
        updatedForm.name = newMember.name;
        this.setState({
          form: updatedForm
        });
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        // console.log('onClosed')
        this.props.onClosed();
    }

    toogleSearch = (type) => {
        this.setState({searchType: type});
    };

    render() {
        // console.log(this.props.edit)
        let filters = (<Fragment></Fragment>);
        if(!this.props.edit) {
            filters = (
            <FormGroup row>
                <Col sm={12}>
                    <Label for="user">Que toque o(s) instrumento(s):</Label>
                    <SelectAsync
                        id="instrument"
                        name="instrument"
                        isMulti="true"
                        matchPos="any"
                        value={this.state.selectedInstrumentOption}
                        loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/' + this.props.currentCompanyId + '/instruments', 'title')}
                        // onCreateOption={this.onCreateInstruments}
                        onChange={this.handleInstrumentChange}
                    />
                </Col>
            </FormGroup>
            );
        }

        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        {this.props.edit ? 'Editar Membro' : 'Adicionar Membro'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                    {!this.props.edit ?
                        <Fragment>
                        <Label for="user">Busque pelo nome:</Label>
                        <SelectAsync
                            id="user"
                            name="user"
                            matchPos="any"
                            formatOptionLabel={formatOptionLabel}
                            value={{value: this.state.form.user_id, label: this.state.form.name, city: "", avatar: this.state.form.avatar}}
                            loadOptions={(inputValue) => this.handleMemberSearch(inputValue)}
                            onCreateOption={this.onCreateMembers}
                            onChange={this.handleMemberChange}
                        />
                        </Fragment>
                        :
                        <h3>{this.state.form.name}</h3>
                    }
                    </Col>
                </FormGroup>
                {filters}
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Observação:</Label>
                        <Input type="text" name="obs" id="obs" placeholder="Observação" onChange={(event) => this.inputChangedHandler(event, 'obs')} value={this.state.form.obs} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="budget">Valor a Receber (cachê):</Label>
                        <InputGroup>
                            <InputGroupText>R$</InputGroupText>
                            <Input type="text" ref="budget" name="budget" id="budget" placeholder="" onChange={(event) => this.inputChangedHandler(event, 'budget')} value={this.state.form.budget} />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const formatOptionLabel = ({ value, label, city, avatar}) => (
    <Row>
        <Col sm={2}>
            {avatar == null ? (<FaCircleUser size="1.7em" />) : (<img alt={label} src={avatar} style={{width:"1.7em", height:"1.7em", borderRadius:"50%"}} />)}
        </Col>
        <Col sm={10}>
            <h5>{label}</h5>
            <h6>
                <span className='primary'>{city}</span>
            </h6>
        </Col>
    </Row>
);

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormMember));
