import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      backdrop: true,
    };

    // this.toggle = this.toggle.bind(this);
    this.changeBackdrop = this.changeBackdrop.bind(this);
  }

  // toggle() {
  //   this.setState(prevState => ({
  //     modal: !prevState.modal
  //   }));
  // }

  changeBackdrop(e) {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value });
  }

  render() {

    return (
      <div>
        <Modal 
            isOpen={this.state.modal} 
            toggle={this.props.cancel} 
            className={this.props.className} 
            backdrop={this.state.backdrop}
            // onExit={this.props.cancel}
        >
          <ModalHeader toggle={this.props.cancel}>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
          {
            this.props.handler === null ?
              <ModalFooter>
                <Button color="secondary" onClick={this.props.cancel}>Fechar</Button>
              </ModalFooter>
              :
              <ModalFooter>
                <Button color="primary" onClick={this.props.handler}>Confirmar</Button>{' '}
                <Button color="secondary" onClick={this.props.cancel}>Cancelar</Button>
              </ModalFooter>
          }
        </Modal>
      </div>
    );
  }
}

export default ModalGeneric;
