import React, { PureComponent } from 'react';
import authenticatedInstance from '../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row
} from 'reactstrap';
import * as actions from '../../store/actions/index';
import ModalGeneric from '../Aux/ModalGeneric/ModalGeneric';
import withRouter from '../Aux/WithRouter/WithRouter';

class FormArrangementPartFile extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            form:  {
                id: 0,
                arrangement_id: 0,
                instrument_id: 0,
                instrument: {id: 0, name: ''},
                number: '',
                obs: ''
            }
        }
    }

    componentDidMount() {
        if(this.props.part !== null) {
            let part = {...this.props.part};
            part["instrument"] = {
                id: part.instrument_id,
                title: part.title
            };
            this.setState({form: part});
        }
    }

    submitHandler = (event) => {
        console.log('file: ', event.target.files);
        console.log('path: ', '/collection/' + this.props.currentCompanyId + '/opus/' + this.props.currentOpusId +'/arrangements/' + this.props.currentArrangementId + '/instruments/'+ this.state.currentPartId + '/part/add');
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("filePart", file);

        const headers = {
            "Content-Type": "multipart/form-data"
        };

        authenticatedInstance.post(
            '/collection/' + this.props.currentCompanyId + '/opus/' + this.props.currentOpusId +'/arrangements/' + this.props.currentArrangementId + '/instruments/' + this.props.currentPartId + '/part',
            formData,
            headers
        ).then(response => {
            console.log("formArrangementPartFile success")
            toastfy("success", 'Partitura adicionada com sucesso!');
            console.log(response.data);
            this.props.returnInstrumentPart({
                id: response.data.id,
                title: response.data.instrument.title,
                abrev: response.data.instrument.abrev,
                obs: response.data.obs,
                number: parseInt(response.data.number),
                ordering: parseInt(response.data.ordering),
                file_hash: response.data.file_hash,
                extension: response.data.extension,
                thumbnail: response.data.thumbnail,
                processing: response.data.processing
            });
            this.props.show(false);
            return response.data;
        }).catch(error => {
            console.log("formArrangementPartFile error", error)
            toastfy("error", 'Houve algum problema ao tentar adicionar a Obra...');
            this.setState({error: true});
            this.props.loaderStop();
        });
    }

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.props.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={6}>
                        {this.props.currentArrangementTitle}
                    </Col>
                    <Col sm={6}>
                        {this.props.currentArrangementAuthor}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {this.state.form.instrument.title} {(parseInt(this.state.form.number) === 0 ? '' : this.state.form.number)}
                    </Col>
                </Row>
                </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Uploado do arquivo PDF:</Label>
                        <Input
                            id="filePart"
                            name="filePart"
                            type="file"
                            onChange={(e) => this.submitHandler(e)}
                        />
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentOpus: state.opus
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormArrangementPartFile));
