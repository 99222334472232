import React, { PureComponent } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row
} from 'reactstrap';
import * as actions from '../../../store/actions/index';
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormFinancial extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            edit: false,
            event_id: 0,
            form:  {
                id: 0,
                revenue_expense: 0,
                user_id: 0,
                description: '',
                date_payment: '',
                value: 0.0
            }
        }
    }

    componentDidMount() {
        this.loadForm();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        const form = {...this.state.form};
        if(this.props.financial !== null) {

            // EDIT
            if(this.props.edit) {
                form.id = this.props.financial.id;
                form.revenue_expense = this.props.financial.revenue_expense;
                form.user_id = this.props.financial.user_id;
                form.description = this.props.financial.description;
                form.date_payment = this.props.financial.date_payment;
                form.value = this.props.financial.value;
            }
        }

        this.setState({
            isOpen: open,
            form: form
        });
    }

    resetForm = () => {
        this.setState({
            form: {
                id: 0,
                revenue_expense: 0,
                user_id: 0,
                description: '',
                date_payment: '',
                value: 0.0
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {
            revenue_expense: this.state.form.revenue_expense,
            // user_id: this.state.form.user_id,
            user_id: 0,
            date_payment: this.state.form.date_payment,
            description: this.state.form.description,
            value: this.state.form.value
        };

        if(this.props.edit) {
            delete updatedForm.id;
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/financial/' + this.state.form.id, updatedForm).then(response => {
                toastfy('success', 'Item alterado com sucesso!');
                this.props.returnFunction({
                    id: this.state.form.id,
                    revenue_expense: updatedForm.revenue_expense,
                    user_id: this.state.form.user_id,
                    date_payment: updatedForm.date_payment,
                    description: updatedForm.description,
                    value: updatedForm.value
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar alterar o item...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/financial', updatedForm).then(response => {
                toastfy('success', 'Item financeiro adicionado com sucesso!');
                this.props.returnFunction({
                    id: response.data.id,
                    revenue_expense: this.state.form.revenue_expense,
                    user_id: this.state.form.user_id,
                    date_payment: this.state.form.date_payment,
                    description: this.state.form.description,
                    value: this.state.form.value
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar adicionar o item...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    expenseRevenue = () => {
        let val = 0;
        if(this.state.form.revenue_expense === 0) {
            val = 1;
        }
        const form = {...this.state.form};
        form.revenue_expense = val;
        this.setState({form: form});
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    toogleSearch = (type) => {
        this.setState({searchType: type});
    };

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        {this.props.edit ? 'Editar Item' : 'Adicionar Item'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup switch>
                    <Input
                        type="switch"
                        checked={(this.state.form.revenue_expense === 1 ? true : false)}
                        onClick={this.expenseRevenue}
                    />
                    <Label check>{(this.state.form.revenue_expense === 1 ? "Receita" : "Despesa")}</Label>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="date_payment">Data</Label>
                        <Input type="date" name="date_payment" id="date_payment" placeholder="Data" onChange={(event) => this.inputChangedHandler(event, 'date_payment')} value={this.state.form.date_payment} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Descrição:</Label>
                        <Input type="text" name="description" id="description" placeholder="Descrição" onChange={(event) => this.inputChangedHandler(event, 'description')} value={this.state.form.description} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="value">Valor:</Label>
                        <InputGroup>
                            <InputGroupText>R$</InputGroupText>
                            <Input type="text" ref="value" name="value" id="value" placeholder="0,00" onChange={(event) => this.inputChangedHandler(event, 'value')} value={this.state.form.value} />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormFinancial));
