import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'moment/locale/pt-br';
import {
    ButtonGroup,
    Button,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardText,
    Row,
    Table
} from 'reactstrap';
import classNames from 'classnames';
import LoaderContext from '../../../context/LoaderContext';
import './Event.css';
import { 
    FiEdit,
    FiTrash2
} from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import FormFinancial from './FormFinancial';
import ModalDelete from '../../Aux/ModalDelete/ModalDelete';
import withRouter from '../../Aux/WithRouter/WithRouter';

class EventFinancial extends Component {
	static contextType = LoaderContext;

	constructor(props) {
	  super(props);
      this.state = {
        error: false,
        formFinancialOpen: false,
        formFinancialEdit: false,
        formFinancialEditData: null,
        formFinancialEditId: null,
        formFinancialEditPosition: null,
        event_id: null,
        financial: [],
        exclude: {
            entity: 'anything',
            anything: {
                id: 0,
                show: false,
                title: "",
                message: ""
            },
            financial: {
                id: 0,
                position: 0,
                show: false,
                title: "Excluir item",
                message: "Deseja realmente excluir esse item?"
            },
        },
        events: null
      };
	}

    componentDidMount() {
        this.setState({
            event_id: this.props.event_id,
            financial: this.props.financial
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event_id !== this.props.event_id) {
            this.setState({
                event_id: this.props.event_id,
                financial: this.props.financial
            });
        }
    }
    
    financialAdd = () => {
        this.setState({
            formFinancialOpen: !this.state.formFinancialOpen,
            formFinancialEdit: false,
            formFinancialEditData: null
        });
    }

    financialEdit = (position) => {
        this.setState({
            formFinancialOpen: !this.state.formFinancialOpen,
            formFinancialEdit: true,
            formFinancialEditPosition: position,
            formFinancialEditData: this.state.financial[position]
        });
    }

    addFinancial = (financialNew) => {
        const financial = Object.values({...this.state.financial});
        if(this.state.formFinancialEdit) {
            let data = {...this.state.formFinancialEditData};
            data['revenue_expense'] = financialNew['revenue_expense'];
            data['date_payment'] = financialNew['date_payment'];
            data['description'] = financialNew['description'];
            data['value'] = financialNew['value'];
            financial[this.state.formFinancialEditPosition] = data;
        } else {
            financial.push(financialNew);
        }
        this.setState({
            formFinancialOpen: false,
            financial: financial
        });
    }

    exclude = (id, position, entity, path) => {
        const exclude = {...this.state.exclude};
        exclude["entity"] = entity;
        exclude[entity]["id"] = id;
        exclude[entity]["position"] = position;
        exclude[entity]["show"] = true;
        exclude[entity]["path"] = '/collection/' + this.props.currentCompanyId + '/event/' + this.props.event_id + path;
        this.setState({exclude: exclude});
    }

    excludeDo = () => {
        const financial = Object.values({...this.state.financial});
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
        switch(this.state.exclude.entity) {
            case "financial":
            default:
                delete financial[exclude[this.state.exclude.entity]["position"]];
                break;
        }
        this.setState({financial: financial});
    }

    excludeCancel = () => {
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
    }

	render() {
        let modalExclude = "";
        if(this.state.exclude[this.state.exclude.entity]["show"]) {
            modalExclude = (
                <ModalDelete
                    title={this.state.exclude[this.state.exclude.entity]["title"]}
                    message={this.state.exclude[this.state.exclude.entity]["message"]}
                    path={this.state.exclude[this.state.exclude.entity]["path"]}
                    actionOnSuccess={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

        let revenue = 0.0;
        let expense = 0.0;

    	return (
        <Fragment>
        <Row>
            <Col sm="12">
            <Card
                className="event-card"
                color="light"
                outline
                style={{
                    width: '100%    '
                }}
            >
                <CardBody>
                    <CardTitle tag="h5">
                        Pagamentos/Recebimentos
                    </CardTitle>
                    <CardText>
                    <Row>
                        <Col sm={5}>
                            <Button type="button" color="primary" onClick={this.financialAdd}><AiOutlineUsergroupAdd /> Adicionar Pagamento/Despesa</Button>
                        </Col>
                    </Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Data
                                </th>
                                <th>
                                    Descrição
                                </th>
                                <th>
                                    Valor
                                </th>
                                <th>
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.financial.map((item, index) => {
                                if(parseInt(item.revenue_expense) === 1) {
                                    revenue += parseFloat(item.value);
                                } else {
                                    expense += parseFloat(item.value);
                                }
                                return (
                                    <tr key={index} className={classNames({ 'text-success': parseInt(item.revenue_expense) === 1, 'text-danger': parseInt(item.revenue_expense) === 0 })}>
                                        <th scope="row">
                                            {index + 1}
                                        </th>
                                        <td>
                                            {item.date_payment}
                                        </td>
                                        <td>
                                            {item.description}
                                        </td>
                                        <td>
                                            {item.value}
                                        </td>
                                        <td>
                                            <ButtonGroup>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(position, memberPosition) => this.financialEdit(index)}
                                                >
                                                    <FiEdit title="Editar" />
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(id, position, entity, path) => this.exclude(item.id, index, "financial", "/financial/" + item.id)}
                                                >
                                                    <FiTrash2 title="Apagar" />
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                );
                            })
                            }
                        </tbody> 
                    </Table>
                    </CardText>
                </CardBody>
            </Card>
            </Col>
        </Row>
        <Row>
            <Col sm="12">
                <Card
                    className="event-card"
                    color="light"
                    style={{
                        width: '100%    '
                    }}
                >
                    <CardHeader>
                        Totais
                    </CardHeader>
                    <CardBody>
                        <CardText>
                        <Table bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            Orçamento
                                        </th>
                                        <th>
                                            R$ {this.props.budget}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Valor Total Recebido
                                        </th>
                                        <th>
                                            R$ {revenue}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Valor Total Gasto
                                        </th>
                                        <th>
                                            R$ {expense}
                                        </th>
                                    </tr>
                                </thead>
                            </Table>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <FormFinancial
            returnFunction={(financial) => this.addFinancial(financial)}
            eventId={this.state.event_id}
            isOpen={this.state.formFinancialOpen}
            onClosed={this.financialAdd}
            edit={this.state.formFinancialEdit}
            financial={this.state.formFinancialEditData}
        />
        {modalExclude}
        </Fragment>
        );
	}
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

export default connect(mapStateToProps, null)(withRouter(EventFinancial));
