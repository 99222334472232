import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, NavLink as NavLinkStrap } from 'reactstrap';
import { 
    FiPlusCircle,
    FiHome,
    FiSun
} from "react-icons/fi";
import classNames from 'classnames';

class MenuEvents extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
            this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        // const { icon, title, items } = this.props;
        return (
            <Fragment>
                <NavItem onClick={this.toggleNavbar} className={classNames({'menu-open': !this.state.collapsed})}>
                    <NavLinkStrap className="dropdown-toggle" href="#">
                        <FiHome />&nbsp;&nbsp;
                        Eventos
                    </NavLinkStrap>
                </NavItem>
                <Collapse isOpen={!this.state.collapsed} navbar className={classNames('items-menu',{'mb-1': !this.state.collapsed})}>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/events/add"}>
                            <FiPlusCircle />&nbsp;&nbsp;
                            Adicionar Evento
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/events"}>
                            <FiSun />&nbsp;&nbsp;
                            Eventos
                        </NavLink>
                    </NavItem>
                </Collapse>
            </Fragment>
        );
    }
}

export default MenuEvents;
