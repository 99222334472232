import React, { PureComponent, Fragment } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
} from 'reactstrap';
import * as actions from '../../../store/actions/index';
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormPlaylist extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            edit: false,
            form:  {
                id: 0,
                title: '',
                obs: '',
            }
        }
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.loadForm();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        const form = {...this.state.form};
        if(this.props.playlist !== null) {
            
            // EDIT
            if(this.props.edit) {
                form.id = this.props.playlist.id;
                form.title = this.props.playlist.title
                form.obs = this.props.playlist.obs;
            } else {
                this.resetForm();
            }
        }

        this.setState({
            isOpen: open,
            form: form
        });
    }

    resetForm = () => {
        this.setState({
            form: {
                id: 0,
                title: '',
                obs: '',
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        let updatedForm = {...this.state.form};
        // updatedForm['title'] = data.title;
        // updatedForm['obs'] = data.obs;

        if(this.props.edit) {
            delete updatedForm.id;
            authenticatedInstance.put('/playlist/' + this.state.form.id, updatedForm).then(response => {
                toastfy('success', 'Playlist alterada com sucesso!');
                this.props.returnFunction({
                    // id: this.state.form.id,
                    // title: this.state.form.title,
                    // obs: this.state.form.obs,
                    id: response.data.id,
                    ordering: response.data.ordering,
                    title: response.data.title,
                    obs: response.data.obs,
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar alterar a música...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/playlist', updatedForm).then(response => {
                toastfy('success', 'Música adicionada com sucesso!');
                this.props.returnFunction({
                    id: response.data.id,
                    ordering: 0,
                    title: this.state.form.title,
                    obs: this.state.form.obs,
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar adicionar a música...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        {this.props.edit ? 'Editar Música' : 'Adicionar Música'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="user">Título:</Label>
                        <Input type="text" name="title" id="title" placeholder="Título" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Observação:</Label>
                        <Input type="text" name="obs" id="obs" placeholder="Observação" onChange={(event) => this.inputChangedHandler(event, 'obs')} value={this.state.form.obs} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormPlaylist));
