import axios from 'axios';
import {
    logoutUser,
    insertAuthentication,
    handleError
} from './interceptors';

// const baseURL = 'http://127.0.0.1:8000/api/v1';
// const baseURL = 'http://192.168.0.121:8000/api/v1';
const baseURL = 'http://api.camerataaccord.com.br/api/v1'
// const baseURL = `${SERVER_URL}v1`;
console.log('baseURL', baseURL);

const authenticatedInstance = axios.create({
  baseURL,
  'Content-Type': 'application/json',
  timeout: 30 * 1000
});

authenticatedInstance.interceptors.request.use(insertAuthentication, handleError);
authenticatedInstance.interceptors.response.use(logoutUser, handleError);

export default authenticatedInstance;
