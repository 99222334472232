import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // modal: this.props.show,
            modal: true,
            backdrop: true,
        };

        // this.toggle = this.toggle.bind(this);
        this.changeBackdrop = this.changeBackdrop.bind(this);
    }

    // toggle() {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    // }

    changeBackdrop(e) {
        let value = e.target.value;
        if (value !== 'static') {
            value = JSON.parse(value);
        }
        this.setState({ backdrop: value });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.props.cancel}
                    className={this.props.className}
                    backdrop={this.state.backdrop}
                    // onExit={this.props.cancel}
                >
                    <ModalHeader toggle={this.props.cancel}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        {this.props.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.props.handler}>Confirmar</Button>{' '}
                        <Button color="secondary" onClick={this.props.cancel}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalConfirm;
