// import store from '../store/authStore';
import { logout } from '../store/actions/auth';
import Axios from 'axios';
import store from '../store/storeRoot';
import * as actions from '../store/actions/index';

const state = store.getState()

export function insertAuthentication(request) {
    store.dispatch(actions.loaderStart());

    const access_token = localStorage.getItem('access_token');
    request.headers.Authorization = `Bearer ${access_token}`;
    return request
}

export function cancelRequestIfOffline(request) {
    if (state.connectivity.online === false) {
        throw new Axios.Cancel('User disconnected from internet')
    } else return request
}

export function logoutUser(response) {
    store.dispatch(actions.loaderStop());
    if (response.status === 401) {
        store.dispatch(logout('Sua sessão expirou'));
    }
    return response
}

export function handleError(err) {
  return Promise.reject(err)
}

export function handleAuthenticatedError(err) {
  if (err.response.status === 401) {
    store.dispatch(logout('Sua sessão expirou'));
  }
  return Promise.reject(err)
}

export function startStatus(response) {
    store.dispatch(actions.loaderStart());
    return response;
}

export function endStatus(response) {
    store.dispatch(actions.loaderStop());
    return response;
}
