import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Link } from 'react-router-dom';
import LoaderContext from '../../../context/LoaderContext';
import CompanyBox from '../../Aux/CompanyBox/CompanyBox';
import FormAddCompany from './FormAddCompany';
import {
    Button,
    Col,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Row,
} from 'reactstrap';
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdOutlineDomainAdd } from "react-icons/md";
import withRouter from '../../Aux/WithRouter/WithRouter';
import './Company.css';

class Companies extends Component {
	static contextType = LoaderContext;

	constructor(props) {
	  super(props);
      this.state = {
        error: false,
        formAddCompanyOpen: false,
        companies: []
      };
	}

    componentDidMount() {
        const companies = [];
        this.props.info.companies.map((company, index) => {
            companies.push(company);
            return companies;
        });
        this.setState({
            companies: companies
        });
    }

    companyForm = () => {
        this.setState({
            formAddCompanyOpen: !this.state.formAddCompanyOpen,
        });
    }

    companyAdd = (company) => {
        let companies = Object.values({...this.state.companies});
        companies.push(company);
        this.props.setCompanies(companies);
        this.setState({
            companies: companies,
            formAddCompanyOpen: !this.state.formAddCompanyOpen,
        });
    }

	render() {
    	return (
        <Fragment>
            <CompanyBox>
                <h4><HiOutlineBuildingOffice2 /> Minhas Companias</h4>
                <Row>
                    <Col sm={5}>
                        <Button type="button" color="primary" onClick={this.companyForm}><MdOutlineDomainAdd /> Criar Empresa</Button>
                    </Col>
                </Row>
                <hr/>
                <Row xs={3}>
                {
                    // this.props.info.companies != null ?
                    this.state.companies.length > 0 ?
                    // this.props.info.companies.map((company, index) => {
                        this.state.companies.map((company, index) => {
                        return (
                            <Col key={index}>
                                <Card
                                    className="my-2"
                                    color="primary"
                                    outline
                                    style={{
                                    width: '18rem'
                                    }}
                                >
                                    <CardHeader>
                                        <Link to={'/c/' + company.slug}>{company.title}</Link>
                                    </CardHeader>
                                    <CardBody>
                                    <CardTitle tag="h5">
                                        Special Title Treatment
                                    </CardTitle>
                                    <CardText>
                                        With supporting text below as a natural lead-in to additional content.
                                    </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })
                    :
                    <p>Nenhuma companhia disponível.</p>
                }
                </Row>
            </CompanyBox>

            <FormAddCompany
                returnFunction={(company) => this.companyAdd(company)}
                isOpen={this.state.formAddCompanyOpen}
                onClosed={this.companyForm}
            />
        </Fragment>
        );
	}
 }

const mapStateToProps = state => {
	return {
        // isAuth: state.auth.isAuthenticated,
		info: state.auth.info,
	}
};

const mapDispatchProps = dispatch => {
	return {
		setCompanies: (companies) => dispatch(actions.setCompanies(companies))
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(Companies));
