import React from 'react';
import { Badge, Button, Card, CardText, CardTitle, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';

class ModalShow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // modal: this.props.show,
            modal: true,
            backdrop: true,
        };

        // this.toggle = this.toggle.bind(this);
        this.changeBackdrop = this.changeBackdrop.bind(this);
    }

    // toggle() {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    // }

    changeBackdrop(e) {
        let value = e.target.value;
        if (value !== 'static') {
            value = JSON.parse(value);
        }
        this.setState({ backdrop: value });
    }

    render() {
        let livro = "";
        if(this.props.item.livro_pagina !== "") {
            livro = (
                <div>
                    <Badge color="secondary">Livro</Badge><br />
                    <p>Página: {this.props.item.livro_pagina}</p>
                </div>
            );
        }

        let info = "";
        if(this.props.item.info !== "") {
            info = (
                <Card body>
                    <CardTitle>Informações:</CardTitle>
                    <CardText>{this.props.item.info}</CardText>
                </Card>
            );
        }

        let link_video = "";
        if(this.props.item.link_video !== "") {
            link_video = (
                <Row>
                    <Col>
                        <Badge color="secondary">Link Video:</Badge><br />
                        <h4>{this.props.item.link_video}</h4>
                    </Col>
                </Row>
            );
        }

        let link_audio = "";
        if(this.props.item.link_audio !== "") {
            link_audio = (
                <Row>
                    <Col>
                        <Badge color="secondary">Link Áudio:</Badge><br />
                        <h4>{this.props.item.link_audio}</h4>
                    </Col>
                </Row>
            );
        }

        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.props.cancel}
                    className={this.props.className}
                    backdrop={this.state.backdrop}
                    // onExit={this.props.cancel}
                >
                    <ModalHeader toggle={this.props.cancel}>{this.props.item.title}</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row>
                                <Col>
                                    <Badge color="secondary">Título</Badge><br />
                                    <h4>{this.props.item.title}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Badge color="secondary">Autor</Badge><br />
                                    <p>{this.props.item.author.name}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Badge color="secondary">Estilo</Badge><br />
                                    <p>{this.props.item.style.title}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Badge color="secondary">Instrumentações</Badge><br />
                                    <ul>
                                    {this.props.item.instrumentations.map((instrumentation, index) => {
                                        return (
                                            <li key={index}>
                                                {instrumentation.title}
                                            </li>
                                        );
                                    })}
                                    </ul>
                                </Col>
                            </Row>
                            {livro}
                            {info}
                            <Row>
                                <Col xs="10" sm="12" md="10">
                                    <Badge color="secondary">Diretório</Badge><br />
                                    <p>{this.props.item.diretorio}</p>
                                </Col>
                                <Col xs="2" sm="12" md="2">
                                    <Badge color="secondary">Nota</Badge><br />
                                    <p>{this.props.item.nota}</p>
                                </Col>
                            </Row>
                            {link_video}
                            {link_audio}
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.props.delete()}>Excluir</Button>
                        <Link to={"/opus/edit/" + this.props.item.id}>
                            <Button color="warning">Editar</Button>
                        </Link>
                        <Button color="secondary" onClick={this.props.cancel}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalShow;
