const Tones = [
    {
        "id": 0,
        "eu": "Lá♭m",
        "am": "A♭m",
    },
    {
        "id": 1,
        "eu": "Lá♭M",
        "am": "A♭M",
    },
    {
        "id": 2,
        "eu": "Lám",
        "am": "Am",
    },
    {
        "id": 3,
        "eu": "LáM",
        "am": "AM",
    },
    {
        "id": 4,
        "eu": "Lá♯m",
        "am": "A♯m",
    },
    {
        "id": 5,
        "eu": "Lá♯M",
        "am": "A♯M",
    },
    {
        "id": 6,
        "eu": "Si♭m",
        "am": "B♭m",
    },
    {
        "id": 7,
        "eu": "Si♭M",
        "am": "B♭M",
    },
    {
        "id": 8,
        "eu": "Sim",
        "am": "Bm",
    },
    {
        "id": 9,
        "eu": "SiM",
        "am": "BM",
    },
    {
        "id": 10,
        "eu": "Dóm",
        "am": "Cm",
    },
    {
        "id": 11,
        "eu": "DóM",
        "am": "CM",
    },
    {
        "id": 12,
        "eu": "Dó♯m",
        "am": "C♯m",
    },
    {
        "id": 13,
        "eu": "Dó♯M",
        "am": "C♯M",
    },
    {
        "id": 14,
        "eu": "Ré♭m",
        "am": "D♭m",
    },
    {
        "id": 15,
        "eu": "Ré♭M",
        "am": "D♭M",
    },
    {
        "id": 16,
        "eu": "Rém",
        "am": "Dm",
    },
    {
        "id": 17,
        "eu": "RéM",
        "am": "DM",
    },
    {
        "id": 18,
        "eu": "Ré♯m",
        "am": "D♯m",
    },
    {
        "id": 19,
        "eu": "Ré♯M",
        "am": "D♯M",
    },
    {
        "id": 20,
        "eu": "Mi♭m",
        "am": "E♭m",
    },
    {
        "id": 21,
        "eu": "Mi♭M",
        "am": "E♭M",
    },
    {
        "id": 22,
        "eu": "Mim",
        "am": "Em",
    },
    {
        "id": 23,
        "eu": "MiM",
        "am": "EM",
    },
    {
        "id": 24,
        "eu": "Fám",
        "am": "Fm",
    },
    {
        "id": 25,
        "eu": "FáM",
        "am": "FM",
    },
    {
        "id": 26,
        "eu": "Fá♯m",
        "am": "F♯m",
    },
    {
        "id": 27,
        "eu": "Fá♯M",
        "am": "F♯M",
    },
    {
        "id": 28,
        "eu": "Sol♭m",
        "am": "G♭m",
    },
    {
        "id": 29,
        "eu": "Sol♭M",
        "am": "G♭M",
    },
    {
        "id": 30,
        "eu": "Solm",
        "am": "Gm",
    },
    {
        "id": 31,
        "eu": "SolM",
        "am": "GM",
    },
    {
        "id": 32,
        "eu": "Sol♯m",
        "am": "G♯m",
    },
    {
        "id": 33,
        "eu": "Sol♯M",
        "am": "G♯M",
    }
];

export default Tones;