import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import 'moment/locale/pt-br';
import {
    Col,
    Card,
    CardBody,
    CardText,
    Container,
    Row,
    ButtonGroup,
    Button,
    Badge
} from 'reactstrap';
import { ReactSortable } from "react-sortablejs";
import * as actions from '../../../store/actions/index';
import authenticatedInstance from '../../../api/authenticated';
import LoaderContext from '../../../context/LoaderContext';
import { 
    FiEdit,
    FiTrash2,
    FiMove,
    FiAlertTriangle
} from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import FormProgram from './FormProgram';
import ModalDelete from '../../Aux/ModalDelete/ModalDelete';
import withRouter from '../../Aux/WithRouter/WithRouter';

class PlaylistProgram extends Component {
	static contextType = LoaderContext;

	constructor(props) {
	  super(props);
      this.state = {
        error: false,
        formProgramOpen: false,
        formProgramEdit: false,
        formProgramEditData: null,
        formProgramEditId: null,
        formProgramEditPosition: null,
        playlist_id: null,
        program: [],
        exclude: {
            entity: 'anything',
            anything: {
                id: 0,
                show: false,
                title: "",
                message: ""
            },
            program: {
                id: 0,
                position: 0,
                show: false,
                title: "Excluir item do programa",
                message: "Deseja realmente excluir esse item?"
            },
        },
        playlists: null
      };
	}

    componentDidMount() {
        this.loadProgram(this.props.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.loadProgram(this.props.params.id);
        }
    }
    
    loadProgram = async (id) => {
        try {
            authenticatedInstance.get('/playlist/' + id + '/programs')
            .then(response => {
                // moment.locale('pt-br');
                // const dateTimeObj = moment(response.data.date_time);

                this.setState({
                    program: response.data,
                    playlist_id: id
                });
            });
        } catch (error) {
            this.setState({ error: true });
        }
    }
    
    programAdd = () => {
        this.setState({
            formProgramOpen: !this.state.formProgramOpen,
            formProgramEdit: false,
            formProgramEditData: null
        });
    }

    programEdit = (position) => {
        this.setState({
            formProgramOpen: !this.state.formProgramOpen,
            formProgramEdit: true,
            formProgramEditPosition: position,
            formProgramEditData: this.state.program[position]
        });
    }

    addProgram = (programNew) => {
        const program = Object.values({...this.state.program});
        if(this.state.formProgramEdit) {
            let data = {...this.state.formProgramEditData};
            data['obs'] = programNew['obs'];
            data['arrangement'] = programNew['arrangement'];
            program[this.state.formProgramEditPosition] = data;
        } else {
            program.push(programNew);
        }
        this.setState({
            formProgramOpen: false,
            program: program
        });
    }

    setProgramOrder = (programs) => {
         this.setState({
            program: programs
        });
    }

    programOrderEnd = (evt) => {
        this.setState(
            (prevState) => {
              return { program: prevState.program };
            },
            () => {
                let ordering = [];
                this.state.program.map((program, index) => {
                    ordering.push({position: index, programId: program.id})
                    return ordering;
                })

                authenticatedInstance.put(
                    '/playlist/' + this.props.params.id +
                    '/programs/ordenate',
                    {order: ordering}).then(response => {
                    toastfy("success", 'Itens reordenados com sucesso!');
                    return response.data;
                }).catch(error => {
                    toastfy("error", 'Houve algum problema ao tentar reordenar os itens, tente novamente');
                    this.setState({error: true});
                    this.props.loaderStop();
                });
            }
        );
    }

    exclude = (id, position, entity, path, memberPosition) => {
        console.log("exclude", id, position, entity, path, memberPosition);
        const exclude = {...this.state.exclude};
        exclude["entity"] = entity;
        exclude[entity]["id"] = id;
        exclude[entity]["position"] = position;
        exclude[entity]["show"] = true;
        if(memberPosition !== null) {
            exclude[entity]["memberPosition"] = memberPosition;
        }
        exclude[entity]["path"] = '/collection/' + this.props.currentCompanyId + '/playlist/' + this.props.playlist_id + path;
        this.setState({exclude: exclude});
    }

    excludeDo = () => {
        const program = Object.values({...this.state.program});
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
        switch(this.state.exclude.entity) {
            case "program":
                delete program[exclude[this.state.exclude.entity]["position"]];
                break;
            case "member":
                program[exclude[this.state.exclude.entity]["position"]]["members"].splice(exclude[this.state.exclude.entity]["memberPosition"], 1);
                break;
            default:
                break;
        }
        this.setState({program: program});
    }

    excludeCancel = () => {
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
    }

	render() {
        let modalExclude = "";
        if(this.state.exclude[this.state.exclude.entity]["show"]) {
            modalExclude = (
                <ModalDelete
                    title={this.state.exclude[this.state.exclude.entity]["title"]}
                    message={this.state.exclude[this.state.exclude.entity]["message"]}
                    path={this.state.exclude[this.state.exclude.entity]["path"]}
                    actionOnSuccess={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

    	return (
        <Fragment>
            <Card
                className="playlist-card"
                color="light"
                outline
                style={{
                    width: '100%    '
                }}
            >
                <CardBody>
                    <CardText>
                        <Row>
                            <Col sm={5}>
                                <Button type="button" color="primary" onClick={this.programAdd}><AiOutlineUsergroupAdd /> Adicionar Música</Button>
                            </Col>
                        </Row>
                        <hr/>
                        {
                        typeof this.state.program != 'undefined' && this.state.program.length > 0 ?
                            <ReactSortable
                                group="shared"
                                animation={200}
                                delay={1}
                                swap
                                multiDrag
                                setList={this.setProgramOrder}
                                list={this.state.program}
                                handle=".handle"
                                onEnd={(evt) => this.programOrderEnd(evt)}
                            >
                            <Fragment>
                            {this.state.program.map((program, index) => {
                            return (
                                <Container key={index} className="bg-light border item-list-box">
                                    <Row>
                                        <Col sm={1}>
                                            <FiMove className="handle" />
                                        </Col>
                                        <Col sm={5}>
                                            <h5>{program.opus.title}</h5>
                                            <h6>{program.opus.author.name}</h6>
                                            <p>{program.obs}</p>
                                        </Col>
                                        <Col sm={5}>
                                            {
                                            // program.arrangement === null || typeof program.arrangement.tone === 'undefined' ?
                                            program.arrangement === null ?
                                                <p><FiAlertTriangle color="yellow" size="1.5em" /> Sem material/arranjo selecionado.</p>
                                            :
                                                <Fragment>
                                                    <Badge color='secondary'>Material/Arranjo</Badge>
                                                    <h5>{program.arrangement.title} - {program.arrangement.tone.eu}</h5>
                                                    <p>{program.arrangement.description}</p>
                                                </Fragment>
                                            }
                                        </Col>
                                        <Col sm={1}>
                                            <ButtonGroup vertical>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(position) => this.programEdit(index)}
                                                >
                                                    <FiEdit title="Editar Item" />
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    outline
                                                    onClick={(id, position, entity, path) => this.exclude(program.id, index, "program", "/program/" + program.id)}
                                                >
                                                    <FiTrash2 title="Apagar Item" />
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            );
                            })}
                            </Fragment>
                            </ReactSortable>
                            : 
                            <p>Sem repertório escolhido para o playlisto.</p>
                        }
                    </CardText>
                </CardBody>
            </Card>
            <FormProgram
                returnFunction={(program) => this.addProgram(program)}
                playlistId={this.state.playlist_id}
                isOpen={this.state.formProgramOpen}
                onClosed={this.programAdd}
                edit={this.state.formProgramEdit}
                program={this.state.formProgramEditData}
            />
            {modalExclude}
        </Fragment>
        );
	}
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(PlaylistProgram));
