import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, NavLink as NavLinkStrap } from 'reactstrap';
import { FiCalendar, FiUser } from "react-icons/fi";
import { CgPassword } from "react-icons/cg";
import { FaUserTie } from "react-icons/fa";
import { FaUserPen } from "react-icons/fa6";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { IoIosWallet } from "react-icons/io";
import classNames from 'classnames';

class MenuUser extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: false
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Fragment>
                <p>Minha Área</p>
                <NavItem onClick={this.toggleNavbar} className={classNames({'menu-open': !this.state.collapsed})}>
                    <NavLinkStrap className="dropdown-toggle" href="#">
                        <FaUserPen />&nbsp;&nbsp;
                        Editar meus dados
                    </NavLinkStrap>
                </NavItem>
                <Collapse isOpen={!this.state.collapsed} navbar className={classNames('items-menu',{'mb-1': !this.state.collapsed})}>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/edit/main"}>
                            <FiUser />&nbsp;&nbsp;
                            Meus Dados
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/playlists"}>
                            <FiCalendar />&nbsp;&nbsp;
                            Playlists
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/calendar"}>
                            <FiCalendar />&nbsp;&nbsp;
                            Calendário
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/edit/password"}>
                            <CgPassword />&nbsp;&nbsp;
                            Minha Senha
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/edit/avatar"}>
                            <FaUserTie />&nbsp;&nbsp;
                            Minha Foto/Avatar
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/companies"}>
                            <HiBuildingOffice2 />&nbsp;&nbsp;
                            Minhas Companias
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/my/financial"}>
                            <IoIosWallet />&nbsp;&nbsp;
                            Meus Pagamentos
                         </NavLink>
                    </NavItem>
                </Collapse>
            </Fragment>
        );
    }
}

export default MenuUser;
