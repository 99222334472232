import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { Nav } from 'reactstrap';
import MenuCollection from './MenuCollection';
import MenuEvents from './MenuEvents';
import MenuUser from './MenuUser';
import classNames from 'classnames';

class SideBar extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        let menus;
        if(window.location.pathname.includes("/c/")) {
            menus = (
                <Fragment>
                    <p>{this.props.currentCompany.title}</p>
                    <MenuEvents slug={this.props.currentCompany.slug} />
                    <MenuCollection slug={this.props.currentCompany.slug} />
                </Fragment>
            );
        }
        if(window.location.pathname.includes("/my")) {
            menus = <MenuUser />;
        }

        return (
            <div className={classNames('sidebar', {'is-open': this.props.isOpen})}>
                <div className="sidebar-header">
                    <h3>thetic.me</h3>
                </div>
                <Nav vertical className="list-unstyled pb-3">
                    {menus}
                </Nav>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
		currentCompany: state.company
	}
};

export default connect(mapStateToProps, null)(SideBar);
