import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    isLoading: false
};

const loaderStart = ( state, action ) => {
    return updateObject( state, { isLoading: true } );
};

const loaderStop = ( state, action ) => {
    return updateObject( state, { isLoading: false } );
};

const reducer = ( state = initialState, action ) => {
    // console.log('reducer', action);
    switch ( action.type ) {
        case actionTypes.LOADER_START: return loaderStart(state, action);
        case actionTypes.LOADER_STOP: return loaderStop(state, action);
        default:
            return state;
    }
};

export default reducer;
