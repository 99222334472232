import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import * as actions from './store/actions/index';
import LoaderContext from './context/LoaderContext';
import CompanyContext from './context/CompanyContext';
import CompanyBox from './components/Aux/CompanyBox/CompanyBox';
import LoadingOverlay from 'react-loading-overlay-ts';
import SignUpForm from './components/Dashboard/Auth/SignUpForm';
import VerifyAccount from './components/Dashboard/Auth/VerifyAccount';
import AuthForm from './components/Dashboard/Auth/AuthForm';
import Logout from './components/Dashboard/Auth/Logout/Logout';
import Layout from './components/Aux/Layout/Layout';
import Components from './components/Dashboard/Components/Components';
import Home from './components/Dashboard/Home/Home';
import Company from './components/Dashboard/Company/Company';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MyHome from './components/Dashboard/MyHome/MyHome';
import UserMainForm from './components/Dashboard/User/UserMainForm';
import UserPasswordForm from './components/Dashboard/User/UserPasswordForm';
import UserAvatarForm from './components/Dashboard/User/UserAvatarForm';
import Companies from './components/Dashboard/Company/Companies';

import Collection from './components/Scores/Collection';
import FormOpus from './components/Scores/FormOpus';
import ViewOpus from './components/Scores/ViewOpus';
import Event from './components/Dashboard/Event/Event';
import Events from './components/Dashboard/Event/Events';
import FormEvent from './components/Dashboard/Event/FormEvent';
import FormArrangement from './components/Scores/FormArrangement';
import ViewArrangement from './components/Scores/ViewArrangement';
import SimpleItens from './components/Generic/SimpleItens';
import SimpleItemForm from './components/Generic/SimpleItemForm';
import Calendar from './components/Dashboard/Calendar/Calendar';
import Playlists from './components/Dashboard/Playlist/Playlists';
import PlaylistProgram from './components/Dashboard/Playlist/PlaylistProgram';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isOpen: true,
          loading: false,
          companyId: null,
          companySlug: null
        }
    }

    componentDidMount() {
        this.props.isAuthenticated();
        // console.log('this.props.info -> ', this.props.info);
        // console.log('this.props.isAuth', this.props.isAuth);
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    toggleLoading = () => {
        // console.log('is toggling!');
        this.setState({loading: !this.state.loading});
    }

    setCompany = (id, slug) => {
        this.setState({companyId: id, companySlug: slug});
    }

    render() {
        return (
            <Fragment>
                <LoaderContext.Provider value={{loading: this.state.loading, toggle: this.toggleLoading}}>
                <CompanyContext.Provider value={{id: this.state.companyId, slug: this.state.companySlug, setCompany: this.setCompany}}>

                    <LoadingOverlay
        				active={this.state.loading || this.props.loading}
        				spinner
        				text='Carregando...'
        				className="loadingSpinner"
        				>
        			</LoadingOverlay>

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                    <div className="App wrapper">
                        <Router>
                        <Layout
                            toggle={this.toggle}
                            isOpen={this.state.isOpen}
                            setLoading={this.loading}
                        >
                            <Routes>
                                <Route key="home" path="/" exact={true} element={<Home/>} />
                                <Route key="components" path="/components" exact={true} element={<Components />} />
                                <Route key="signup" path="/signup" exact={true} element={<SignUpForm />} />
                                <Route key="verifyAccount" path="/user/verify/:token" element={<VerifyAccount />} />
                                <Route key="login" path="/login" exact={true} element={<AuthForm />} />
                                <Route key="logout" path="/logout" exact={true} element={<Logout />} />
                                <Route key="mySpace" path="/my" >
                                    <Route key="mySpaceHome" index exact={true} element={<MyHome />} />
                                    <Route key="myPlaylists" path="playlists" exact={true} element={<CompanyBox><Playlists /></CompanyBox>} />
                                    <Route key="myPlaylistProgram" path="playlists/:id/program" element={<CompanyBox><PlaylistProgram /></CompanyBox>} />
                                    <Route key="myCalendar" path="calendar" exact={true} element={<CompanyBox><Calendar /></CompanyBox>} />
                                    <Route key="myEditMain" path="edit/main" exact={true} element={<UserMainForm />} />
                                    <Route key="myEdreact route itPassword" path="edit/password" exact={true} element={<UserPasswordForm />} />
                                    <Route key="myEditAvatar" path="edit/avatar" exact={true} element={<UserAvatarForm />} />
                                    <Route key="companies" path="companies" element={<Companies />} />
                                </Route>
                                <Route key="company" path="/c/:slug" >
                                    <Route key="companyHome" index exact={true} element={<CompanyBox><Company /></CompanyBox>} />
                                    <Route key="companyAvatar" path={"edit/logo"} element={<UserAvatarForm />} />
                                    <Route key="companyLibrary" path={"scores"} >
                                        <Route key="companyLibraryScores" index exact={true} element={<CompanyBox><Collection /></CompanyBox>} />
                                        <Route key="opusAdd" path={"opus/add"} exact={true} element={<CompanyBox><FormOpus edit={false} /></CompanyBox>} />
                                        <Route key="opusEdit" path={"opus/edit/:id"} element={<CompanyBox><FormOpus edit={true} /></CompanyBox>} />
                                        <Route key="opusView" path={"opus/view/:id"} element={<CompanyBox><ViewOpus /></CompanyBox>} />
                                        <Route key="arrangementAdd" path={"opus/:opus_id/arrangements/add"} element={<CompanyBox><FormArrangement edit={false} /></CompanyBox>} />
                                        <Route key="arrangementEdit" path={"opus/:opus_id/arrangements/edit/:id"} element={<CompanyBox><FormArrangement edit={true} /></CompanyBox>} />
                                        <Route key="arrangementView" path={"opus/:opus_id/arrangements/view/:id"} element={<CompanyBox><ViewArrangement edit={true} /></CompanyBox>} />
                                        <Route key="arrangementInstrumentAdd" path={"opus/:opus_id/arrangements/:id/instruments/add"} element={<CompanyBox><ViewArrangement edit={true} /></CompanyBox>} />
                                        <Route key={"authors"} path={"authors"} exact="exact" element={<CompanyBox><SimpleItens api_entity="authors" title_var_name="name" page_title="Autores" /></CompanyBox>} />
                                        <Route key="authorsAdd" path={"authors/add"} exact="exact" element={<CompanyBox><SimpleItemForm api_entity="authors" title_var_name="name" action="add" page_title="Autor" /></CompanyBox>} />
                                        <Route key="authorsEdit" path={"authors/edit/:id"} element={<CompanyBox><SimpleItemForm api_entity="authors" title_var_name="name" action="edit" page_title="Autor" /></CompanyBox>} />
                                        <Route key={"instrumentations"} path={"instrumentations"} exact="exact" element={<CompanyBox><SimpleItens api_entity="instrumentations" title_var_name="title" page_title="Instrumentações" /></CompanyBox>} />
                                        <Route key="instrumentationsAdd" path={"instrumentations/add"} exact="exact" element={<CompanyBox><SimpleItemForm api_entity="instrumentations" title_var_name="title" action="add" page_title="Instrumentação" /></CompanyBox>} />
                                        <Route key="instrumentationsEdit" path={"instrumentations/edit/:id"} element={<CompanyBox><SimpleItemForm api_entity="instrumentations" title_var_name="title" action="edit" page_title="Instrumentação" /></CompanyBox>} />
                                        <Route key={"styles"} path={"styles"} exact="exact" element={<CompanyBox><SimpleItens api_entity="styles" title_var_name="title" page_title="Estilos" /></CompanyBox>} />
                                        <Route key="stylesAdd" path={"styles/add"} exact="exact" element={<CompanyBox><SimpleItemForm api_entity="styles" title_var_name="title" action="add" page_title="Estilo" /></CompanyBox>} />
                                        <Route key="stylesEdit" path={"styles/edit/:id"} element={<CompanyBox><SimpleItemForm api_entity="styles" title_var_name="title" action="edit" page_title="Estilo" /></CompanyBox>} />
                                    </Route>
                                    <Route key="companyEvents" path={"events"} >
                                        <Route key="companyEventsList" index exact={true} element={<CompanyBox><Events /></CompanyBox>} />
                                        <Route key="companyEvent" path={"view/:id"} element={<CompanyBox><Event /></CompanyBox>} />
                                        <Route key="companyEventAdd" path={"add"} element={<CompanyBox><FormEvent /></CompanyBox>} />
                                        <Route key="companyEventEdit" path={"edit/:id"} element={<CompanyBox><FormEvent edit={true} /></CompanyBox>} />
                                    </Route>
                                </Route>
                            </Routes>
                        </Layout>
                        </Router>
                    </div>

                </CompanyContext.Provider>
                </LoaderContext.Provider>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        // loading: state.auth.loading,
        loading: state.loader.isLoading,
		info: state.auth.info,
		isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
		onInfo: () => dispatch(actions.info()),
        isAuthenticated: () => dispatch(actions.isAuthenticated())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(App);
