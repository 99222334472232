import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button,  Col, FormGroup, Label, Input } from 'reactstrap';
import authenticatedInstance from '../../../api/authenticated';
import toastfy from '../../Aux/Toastfy/Toastfy';
import CompanyBox from '../../Aux/CompanyBox/CompanyBox';
import withRouter from '../../Aux/WithRouter/WithRouter';

class UserPasswordForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            form:  {
                current_password: '',
                new_password: '',
                retype_password: '',
            },
            validate: {
                newPasswordState: '',
                retypePasswordState: ''
            }
        }
    }

    inputPasswordHandler = (event, field) => {
        console.log('fiedls changed: ', event.target.value);

        const updatedForm = {...this.state.form};
        updatedForm[field] = event.target.value;
        this.setState({form: updatedForm});
    }

    inputEqualsHandler = (event) => {
        console.log('fiedls changed: ', event.target.value);

        const _state  = {...this.state}
        _state['form']['retype_password'] = event.target.value;
		if (_state.form.new_password === event.target.value) {
            _state.validate.retypePasswordState = 'has-success'
		} else {
            _state.validate.retypePasswordState = 'has-danger'
		}
		this.setState(_state)
    }

    submitHandler = (data) => {
        const updatedForm = {...this.state.form};

        authenticatedInstance.put('/user/update', updatedForm).then(response => {
            toastfy("success", 'Senha alterada com sucesso!');
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao atualizar, tente novamente mais tarde.');
            this.setState({error: true});
        });
    }

    render() {

        // console.log('Props', this.state.form);
        return (
            <CompanyBox>
                <FormGroup row>
                    <legend>Criar Nova Senha</legend>
                    <Col sm={4}>
                        <Label for="password">Senha Atual</Label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="************"
                            onChange={(event) => this.inputPasswordHandler(event, 'current_password')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row> 
                    <Col sm={8}>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label for="new_password">Nova Senha</Label>
                                <Input
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    placeholder="************"
                                    onChange={(event) => this.inputPasswordHandler(event, 'new_password')}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label for="retype_password">Repetir Senha</Label>
                                <Input
                                    type="password"
                                    name="retype_password"
                                    id="retype_password"
                                    valid={ this.state.validate.retypePasswordState === 'has-success' }
                                    invalid={ this.state.validate.retypePasswordState === 'has-danger' }
                                    placeholder="************"
                                    onChange={(event) => this.inputEqualsHandler(event)}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <Col sm="1">
                        <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                    </Col>
                </FormGroup>
            </CompanyBox>
        );
    }
}

const mapStateToProps = state => {
	return {
        info: state.auth.info,
	}
};

export default connect(mapStateToProps, null)(withRouter(UserPasswordForm));
