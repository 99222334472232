import React, { Component, Fragment } from 'react';
import {
  Col,
  Input
} from 'reactstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import './Components.css';

class Components extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // avatar: '/assets/images/einstein.jpg',
      avatar: '',
      avatarScale: 1,
      avatarEditor: null,
      avatarData: '',
      avatarChanged: false
    };
  }

  // componentDidMount() {
  //   this.setState({defaultScale: 0.99});
  // }

  setAvatarScale = (evt) => {
    console.log("setAvatarSize", evt.target.value);
    this.setState({
      avatarScale: evt.target.value / 50,
      avatarChanged: true
    });
  }

  setAvatarEditorRef = (editor) => {
    this.setState({avatarEditor: editor});
  }

  onAvatarPositionChange = () => {
    this.setState({
      avatarChanged: true
    });
  }

  onAvatarChange = () => {
    this.setState({
      avatarData: this.state.avatarEditor.getImageScaledToCanvas().toDataURL('image/jpeg', 1)
    });
  }

  setAvatar = (event) => {
    console.log('file: ', event.target.files);
    console.log('path: ', '/collection/' + this.props.currentCompanyId + '/opus/' + this.props.currentOpusId +'/arrangements/' + this.props.currentArrangementId + '/instruments/'+ this.state.currentPartId + '/part/add');
    const file = event.target.files[0];
    this.setState({
      avatar: file, 
      avatarScale: 1, 
      avatarData: this.state.avatarEditor.getImageScaledToCanvas().toDataURL('image/jpeg', 1),
      avatarChanged: true
    });
  }

  setAvatarDropped = (dropped) => {
    console.log("setAvatar", dropped);
    this.setState({
      avatar: dropped, 
      avatarScale: 1,
      avatarChanged: true
    });
    // document.getElementById("exampleRange").value = 1;
  }

  render() {
    console.log("avatarChanged", this.state.avatarChanged);
    return (
      <Fragment>
        <Col sm="12">
          {/* <Router>
          <Routes>
            <Route key="my_components" path="/components" exact={true}>

            <h2>react-avatar-editor</h2>
                <Input
                  id="exampleFile"
                  name="file"
                  type="file"
                  onChange={(e) => this.setAvatar(e)}
                />

              <Dropzone
                onDrop={(dropped) => this.setAvatarDropped(dropped[0])}
                noClick
                noKeyboard
                style={{ width: '250px', height: '250px' }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} >
                    <AvatarEditor
                      ref={this.setAvatarEditorRef}
                      width={250}
                      height={250}
                      border={50}
                      scale={this.state.avatarScale}
                      borderRadius={10}
                      image={this.state.avatar}
                      onImageChange={this.onAvatarChange}  
                      onImageReady={this.onAvatarChange}
                      onPositionChange={this.onAvatarPositionChange}
                      style={{ backgroundImage:`url(/assets/images/einstein.jpg)` }}
                    />
                    <input {...getInputProps()} />
                  </div>
                )}
              </Dropzone>
              <Input
                id="exampleRange"
                name="range"
                type="range"
                onChange={(evt) => this.setAvatarScale(evt)}
                defaultValue="50"
              />

              <img src={this.state.avatarData} alt="avatar" />


            </Route>

          </Routes>
          </Router> */}
        </Col>

      </Fragment>
    );
  }
}

export default Components;
