import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    current: 0,
    id: 0,
    slug: '',
    title: '',
    photos: {
        background: '',
        avatar: ''
    }
};

const setCurrentCompany = ( state, action ) => {
    const currentCompany = {
        current: action.data.id,
        id: action.data.id,
        slug: action.data.slug,
        title: action.data.title,
        photos: action.data.photos
    };

    localStorage.setItem('current_company', JSON.stringify(currentCompany));
    return updateObject(
        state,
        currentCompany
    );
};

const getCurrentCompany = (state, action) => {
    let currentCompany = state;
    if(currentCompany.id === null) {
        currentCompany = JSON.parse(localStorage.getItem('info'));
    }
    return currentCompany;
}

const companyFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const updateAvatar = ( state, action ) => {
    let info = JSON.parse(localStorage.getItem('info'));
    console.log('updateAvatar durante', action.photo);

    const newCompanies = info.companies.map(company => {
        if (company.id === state.id) {
            company.photos = updateObject(
                company.photos,
                action.photo
            );
        }
        return company;
    });
    
    info.companies = updateObject(
        info.companies,
        newCompanies
    );
    
    if(info.own_company.id === state.id ) {
        info.own_company.photos = updateObject(
            info.own_company.photos,
            action.photo
        );
    }

    console.log('updateAvatar depois', info);
    localStorage.setItem('info', JSON.stringify(info));
    return updateObject(
        state,
        {
            photos: {...state.photos, ...action.photo}
        }
    );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.COMPANY_CURRENT: return setCurrentCompany(state, action);
        case actionTypes.COMPANY_GET_CURRENT: return getCurrentCompany(state, action);
        case actionTypes.COMPANY_FAIL: return companyFail(state, action);
        case actionTypes.COMPANY_UPDATE_AVATAR: return updateAvatar(state, action);
        default:
            return state;
    }
};

export default reducer;
