import { toast } from 'react-toastify';

const toastfy = (status, text) => {
    const configs = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    switch (status) {
        case "info":
            toast.info(text, configs);
            break;
        case "success":
            toast.success(text, configs);
            break;
        case "warn":
        case "warning":
            toast.warn(text, configs);
            break;
        case "error":
            toast.error(text, configs);
            break;
        default:
            toast.info(text, configs);
            break;
    }
};

export default toastfy;