export const LOADER_START = 'LOADER_START';
export const LOADER_STOP = 'LOADER_STOP';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_INFO = 'AUTH_INFO';
export const AUTH_INFO_START = 'AUTH_INFO_START';
export const AUTH_IS_AUTHENTICATED = 'AUTH_IS_AUTHENTICATED';
export const AUTH_UPDATE_COMPANIES = 'AUTH_UPDATE_COMPANIES';

export const COMPANY_CURRENT = 'COMPANY_CURRENT';
export const COMPANY_GET_CURRENT = 'COMPANY_GET_CURRENT';
export const COMPANY_FAIL = 'COMPANY_FAIL';
export const COMPANY_UPDATE_AVATAR = 'COMPANY_UPDATE_AVATAR';

export const OPUS_CURRENT = 'OPUS_CURRENT';
export const OPUS_FAIL = 'OPUS_FAIL';
