import React, { PureComponent, Fragment } from 'react';
import authenticatedInstance from '../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardTitle,
    Row
} from 'reactstrap';
import ToneSelect from './ToneSelect';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../store/actions/index';
import sympleItensGet from '../../api/sympleItensGet';
import withRouter from '../Aux/WithRouter/WithRouter';

class FormArrangement extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            form:  {
                id: 0,
                opus_id: 0,
                title: '',
                description: '',
                author_name: '',
                author_id: 0,
                nota: '',
                tone: 0,
                release_date: '',
                instrumentations: []
            }
        }
    }

    componentDidMount() {
        this.loadOpus(this.props.currentCompanyId, this.props.params.opus_id);
        if (typeof this.props.match !== 'undefined' && typeof this.props.params.id !== 'undefined') {
            authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/'+ this.props.params.id).then(response => {
                let new_instrumentations = [];
                response.data.instrumentations.map((value) => {
                    new_instrumentations.push({ value: value.id, label: value.title });
                    return new_instrumentations;
                });
                const updatedForm = {...response.data};
                updatedForm.selectedInstrumentOption = new_instrumentations;
                // updatedForm.author_id = response.data.author.id;
                updatedForm.tone = response.data.tone.id;

                this.setState({
                    form: updatedForm
                });
            }).catch(error => {
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    loadOpus = async (companyId, opusId) => {
        this.props.setOpus(companyId, opusId);
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        let new_instrumentations = [];
        this.state.form.selectedInstrumentOption.map((value) => {
            new_instrumentations.push(value.value);
            return new_instrumentations;
        });
        const updatedForm = {...this.state.form};
        updatedForm.instrumentations = new_instrumentations;
        updatedForm.author_id = 0;
        updatedForm.opus_id = this.props.params.opus_id;
        updatedForm.nota = updatedForm.nota.length === 0 ? 0 : parseInt(updatedForm.nota);
        this.setState({form: updatedForm});
        delete updatedForm["_links"];

        if(this.props.edit) {
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.opus_id +'/arrangements/' + this.props.params.id, updatedForm).then(response => {
                toastfy("success", 'Obra alterada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/opus/'+ this.props.params.opus_id +'/arrangements', updatedForm).then(response => {
                toastfy("success", 'Obra adicionada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.selectedInstrumentOption = newValue;
        this.setState({ form: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await this.props.addSimpleItemSubmit(title, 'instrumentations', 'title');
        const updatedForm = {...this.state.form};
        const new_instrument = {
          value: newInstrument.id,
          label: newInstrument.title
        };
        updatedForm.selectedInstrumentOption.push(new_instrument);
        this.setState({
          form: updatedForm
        });
    }

    onCreateAuthors = async (title) => {
        const newAuthor = await this.props.addSimpleItemSubmit(title, 'authors', 'name');
        const updatedForm = {...this.state.form};
        updatedForm.author.name = newAuthor.name;
        updatedForm.author.id = newAuthor.id;
        updatedForm.author_id = newAuthor.id;
        this.setState({
          form: updatedForm
        });
    }

    handleAuthorChange = (newValue, actionMeta) => {
        console.log('handleAuthorChange', newValue);
        const updatedForm = {...this.state.form};
        updatedForm.author_id = newValue.value;
        updatedForm.author.id = newValue.value;
        updatedForm.author.name = newValue.label;
        this.setState({ form: updatedForm });
        console.log('componentDidMount', this.state.form);
    };

    render() {
        console.log('tone:', this.state.form.tone);
        console.log('tone type:', typeof this.state.form.tone);
        return (
        <Fragment>
            <CardTitle tag="h4">
                <Row>
                    <Col sm={8}>
                        {this.props.currentOpus.title}
                    </Col>
                    <Col sm={4}>
                        Novo Arranjo
                    </Col>
                </Row>
            </CardTitle>
            <CardTitle tag="h5" className="font-italic">
                <Row>
                    <Col sm={12}>
                        {this.props.currentOpus.author.name}
                    </Col>
                </Row>
            </CardTitle>
            <Form>
                <FormGroup row>
                    <Col sm={5}>
                        <Label for="title">Título</Label>
                        <Input type="text" name="title" id="title" placeholder="Título da obra" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                    </Col>
                  <Col sm={5}>
                      <Label for="author_name">Arranjador</Label>
                      <Input type="text" name="author_name" id="author_name" placeholder="Nome do arranjador" onChange={(event) => this.inputChangedHandler(event, 'author_name')} value={this.state.form.author_name} />
                      {/* <SelectAsync
                          id="author"
                          name="author"
                          matchPos="any"
                          value={{value: this.state.form.author.id, label: this.state.form.author.name}}
                          loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/authors', 'name')}
                          onCreateOption={this.onCreateAuthors}
                          onChange={this.handleAuthorChange}
                      /> */}
                  </Col>
                  <Col sm={2}>
                      <Label for="nota">Nota</Label>
                      <Input type="number" name="nota" id="nota" placeholder="Nota" onChange={(event) => this.inputChangedHandler(event, 'nota')} value={this.state.form.nota} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="description">Descrição</Label>
                        <Input type="textarea" name="description" id="description" placeholder="Descrição" onChange={(event) => this.inputChangedHandler(event, 'description')} value={this.state.form.description} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={3}>
                        <Label for="release_date">Data de lançamento</Label>
                        <Input type="date" name="release_date" id="release_date" placeholder="Data de lançamento do arranjo" onChange={(event) => this.inputChangedHandler(event, 'release_date')} value={this.state.form.release_date} />
                    </Col>
                    <Col sm={3}>
                        <Label for="tone">Tonalidade</Label>
                        <ToneSelect
                            name="tone"
                            id="tone"
                            notation="eu"
                            value={this.state.form.tone}
                            onChange={this.inputChangedHandler}
                        />
                    </Col>
                    <Col sm={6}>
                        <Label for="instrumentations">Instrumentações</Label>
                        <SelectAsync
                            id="instrumentations"
                            name="instrumentations"
                            isMulti="true"
                            matchPos="any"
                            value={this.state.form.selectedInstrumentOption}
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/instrumentations', 'title')}
                            onCreateOption={this.onCreateInstruments}
                            onChange={this.handleInstrumentChange}
                          />
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <Col sm="1">
                        <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                    </Col>
                </FormGroup>
            </Form>
        </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentOpus: state.opus
	}
};

const mapDispatchProps = dispatch => {
	return {
		setOpus: (companyId, opusId) => dispatch(actions.setOpus(companyId, opusId)),
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormArrangement));
