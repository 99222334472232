import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import {
    Button,
    Card,
    CardTitle,
    CardBody,
    CardText,
    CardImgOverlay,
    CardImg,
} from 'reactstrap';
import { 
    FiEdit
} from "react-icons/fi";
import CompanyAvatar from '../CompanyAvatar/CompanyAvatar';
import CompanyAvatarForm from '../CompanyAvatar/CompanyAvatarForm';
import withRouter from '../WithRouter/WithRouter';
import './CompanyBox.css';

class CompanyBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAvatarForm: false,
            avatarType: 0
        }
    }

    componentDidMount() {
        if (this.props.currentCompany.slug !== this.props.params.slug) {
            this.loadCompany(this.props.params.slug);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.slug !== this.props.params.slug) {
            this.loadCompany(this.props.params.slug);
        }
    }

    loadCompany = async (slug) => {
        this.props.setCompany(slug);
    }
    
    showAvatarForm = (show, type) => {
        console.log("showAvatarForm", show, type);
        this.setState({
            showAvatarForm: show,
            avatarType: type
        });
    }

	render() {
        let modalAvatarForm = "";
        if(this.state.showAvatarForm) {
            modalAvatarForm = (
                <CompanyAvatarForm
                    title="Editar foto/avatar"
                    message="Editar foto"
                    // path={'/user/avatar/' + this.state.avatarId}
                    // actionOnSuccess={() => this.excluded()}
                    cancel={(show) => this.showAvatarForm(false)}
                    show={true}
                    type={this.state.avatarType}
                />
            );
        }
    	return (
            <Fragment>
                <Card>
                <CardImg
                    // alt={this.props.currentCompany.title}
                    src={
                        this.props.currentCompany.photos !== null
                        && this.props.currentCompany.photos.background !== null 
                        ? 
                            this.props.currentCompany.photos.background.url 
                        : 
                            '/assets/images/back-to-school-6305092_1280.jpg'
                    }
                    style={{
                        objectFit: 'cover'
                    }}
                    width="100%"
                    className="card-img"
                />
                <CardImgOverlay>
                    <Button 
                        outline
                        className='edit-overlay'
                        onClick={(show) => this.showAvatarForm(true, 1)}
                    >
                        <FiEdit />
                    </Button>
                    {/* <a className='edit-overlay' onClick={(show) => this.showAvatarForm(true)}><FiEdit /></a> */}
                    <CardTitle tag="h5">
                        <Button 
                            outline
                            // className='edit-overlay'
                            onClick={(show) => this.showAvatarForm(true, 0)}
                        >
                            <CompanyAvatar size="48" round="5px" />
                            <FiEdit className='edit-avatar-icon' />
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        {this.props.currentCompany.title}
                    </CardTitle>
                    <CardText>
                        <small className="text-muted">
                        Minha área de trabalho
                        </small>
                    </CardText>
                </CardImgOverlay>
                <CardBody>
                    {/* <CardText> */}
                        {this.props.children}
                    {/* </CardText> */}
                </CardBody>
                </Card>
                {modalAvatarForm}
            </Fragment>
        );
	}
 }

const mapStateToProps = state => {
	return {
        currentCompany: state.company
	}
};

const mapDispatchProps = dispatch => {
	return {
		setCompany: (slug) => dispatch(actions.setCompany(slug))
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(CompanyBox));
