import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderContext from '../../../context/LoaderContext';
import * as actions from '../../../store/actions/index';
import CompanyBox from '../../Aux/CompanyBox/CompanyBox';

class MyHome extends Component {
	static contextType = LoaderContext;

	// constructor(props) {
	//   super(props);
    // //   if(this.props.info === null) {
    // //       this.props.onInfo();
    // //   }
	// }

    componentDidMount() {
        this.props.setCompany(this.props.info.own_company.slug);
    }

	render() {
        // if(!this.props.isAuth) {
        //     return <Redirect to="/login" />;
        // }
        // console.log("MyHome", this.props.info);

    	return (
            <CompanyBox>
                <h3>My New Home!</h3>
            </CompanyBox>
        );
	}
 }

const mapStateToProps = state => {
	return {
		info: state.auth.info,
        isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
		onInfo: () => dispatch(actions.info()),
		setCompany: (slug) => dispatch(actions.setCompany(slug))
	};
};

export default connect(mapStateToProps, mapDispatchProps)(MyHome);
