import React, {Component} from 'react';
import {Input} from 'reactstrap';
import Tones from './Tones';

class toneSelect extends Component {

    // constructor(props) {
    //     super(props);
    // };

    // componentDidMount() {
    // }

    render() {

        return (
            <Input
                type={"select"}
                // size="2"
                name={this.props.name}
                id={this.props.id}
                value={this.props.value}
                onChange={(event) => this.props.onChange(event, 'tone')}
            >
                {
                    // console.log(this.state.notes)
                    Tones.map((tone, index) => {
                        return (
                            <option 
                                key={index} 
                                value={tone.id}
                                selected={parseInt(this.props.value) === parseInt(tone.id) ? 'selected' : 'false'}
                            >{tone[this.props.notation]}</option>
                        );
                    })
                }
            </Input>
        );
    }
}

export default toneSelect;
