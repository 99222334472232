import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, NavItem, NavLink as NavLinkStrap } from 'reactstrap';
import { BsFileEarmarkMusic } from "react-icons/bs";
import { FaCircleUser } from "react-icons/fa6";
import { GiIonicColumn, GiViolin } from "react-icons/gi";
import { FiBookOpen } from "react-icons/fi";
import classNames from 'classnames';

class MenuCollection extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
            this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        return (
            <Fragment>
                <NavItem onClick={this.toggleNavbar} className={classNames({'menu-open': !this.state.collapsed})}>
                    <NavLinkStrap className="dropdown-toggle" href="#">
                        <FiBookOpen />&nbsp;&nbsp;
                        Acervo
                    </NavLinkStrap>
                </NavItem>
                <Collapse isOpen={!this.state.collapsed} navbar className={classNames('items-menu',{'mb-1': !this.state.collapsed})}>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/scores"}>
                            <BsFileEarmarkMusic />&nbsp;&nbsp;
                            Scores
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/scores/authors"}>
                            <FaCircleUser />&nbsp;&nbsp;
                            Autores
                        </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/scores/instrumentations"}>
                            <GiViolin />&nbsp;&nbsp;
                            Instrumentações
                         </NavLink>
                    </NavItem>
                    <NavItem className="pl-4">
                        <NavLink to={"/c/"+ this.props.slug +"/scores/styles"}>
                            <GiIonicColumn />&nbsp;&nbsp;
                            Estilos
                        </NavLink>
                    </NavItem>
                </Collapse>
            </Fragment>
        );
    }
}

export default MenuCollection;
