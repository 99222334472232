import React, { Component, createRef } from 'react';
import { Stage, Layer, Circle } from 'react-konva';
import * as pdfjsLib from 'pdfjs-dist';
import pdfUrl_ from './eed5a1b41d187692226e357509ec5b09.pdf';
// import 'pdfjs-dist/build/pdf.worker.entry';

// Configura o caminho do worker
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.9.155/pdf.worker.min.mjs`;

class ViewScorePdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdfPage: null,
      scale: 1.0,
    };

    this.canvasRef = createRef();
  }

  componentDidMount() {
    this.loadPDF();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scale !== this.state.scale) {
      this.renderPDFPage();
    }
  }

  loadPDF = async () => {
    const pdfUrl = this.props.pdfUrl;
    // const  pdfUrl  = pdfUrl_;

    try {
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;
      const page = await pdf.getPage(1);
      this.setState({ pdfPage: page }, this.renderPDFPage);
    } catch (error) {
      console.error('Erro ao carregar o PDF:', error);
    }
  };

  renderPDFPage = () => {
    const { pdfPage, scale } = this.state;
    const canvas = this.canvasRef.current;

    if (pdfPage && canvas) {
      const viewport = pdfPage.getViewport({ scale });
      const context = canvas.getContext('2d');

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      pdfPage.render(renderContext);
    }
  };

  handleCircleClick = () => {
    this.setState((prevState) => ({
      scale: prevState.scale * 1.2,
    }));
  };

  render() {
    const { pdfPage, scale } = this.state;

    if (!pdfPage) {
      return <div>Carregando PDF...</div>;
    }

    const viewport = pdfPage.getViewport({ scale });

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <canvas
          ref={this.canvasRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        />
        <Stage
          width={viewport.width}
          height={viewport.height}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          <Layer>
            <Circle
              x={viewport.width / 2}
              y={viewport.height / 2}
              radius={50}
              fill="red"
              onClick={this.handleCircleClick}
            />
          </Layer>
        </Stage>
      </div>
    );
  }
}

export default ViewScorePdf;
