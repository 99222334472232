import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
// import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducerRoot';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    createRootReducer(history),
    composeEnhancers(
        applyMiddleware(
            // routerMiddleware(history),
            thunk
        )
    )
);

export default store;
