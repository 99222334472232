import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import { Table, Button, Col, FormGroup, Label, Input } from 'reactstrap';
import authenticatedInstance from '../../api/authenticated';
import SelectAsync from 'react-select/async';
import ModalDelete from '../Aux/ModalConfirm/ModalConfirm';
import ModalShow from '../Aux/ModalShow/ModalShow';
import Paginator from '../Aux/Paginator/Paginator';
import { Link } from 'react-router-dom';
import { FiEye } from "react-icons/fi";
import sympleItensGet from '../../api/sympleItensGet';

class Collection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            page: 1,
            pages: 1,
            searchTitle: '',
            searchAuthor: [],
            searchInstrumentations: [],
            searchStyle: [],
            deleteId: null,
            deleteShow: false,
            modalShow: false
        }
    };

    componentDidMount() {
        this.loadCollection(this.state.page);
    }

    loadCollection = (page) => {
        if (typeof page === 'undefined') {
            page = this.state.page;
        }

        // SEARCH
        let searchQuery = '&search=';
        let searchArray = [];
        if (this.state.searchTitle) {
            searchArray.push('title:' + this.state.searchTitle);
        }
        if (this.state.searchAuthor !== null && this.state.searchAuthor.length > 0) {
            let author_ids = [];
            this.state.searchAuthor.forEach((obj, i) => {
                author_ids.push(obj.value);
            })
            searchArray.push('author.id:' + author_ids.join(','));
        }
        if (this.state.searchInstrumentations !== null && this.state.searchInstrumentations.length > 0) {
            let instr_ids = [];
            this.state.searchInstrumentations.forEach((obj, i) => {
                instr_ids.push(obj.value);
            })
            searchArray.push('instrumentation.id:' + instr_ids.join(','));
        }
        if (this.state.searchStyle !== null && this.state.searchStyle.length > 0) {
            let style_ids = [];
            this.state.searchStyle.forEach((obj, i) => {
                style_ids.push(obj.value);
            })
            searchArray.push('style.id:' + style_ids.join(','));
        }
        console.log('searchArray', searchArray);
        searchQuery += searchArray.join(';');
        console.log('searchQuery', searchQuery);
        return authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/opus?page=' + page + searchQuery + '&searchJoin=and')
        .then(response => {
            this.setState({
                itens: response.data.opus.data,
                pages: response.data.opus.last_page,
                page: response.data.opus.current_page
            });
        }).catch(error => {
            this.setState({error: true});
        });
    }

    searchItemHandlerKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.loadCollection(1);
        }
    }

    show = (item) => {
        this.setState({showItem: item, modalShow: true});
    }

    showCancel = () => {
        this.setState({modalShow: false});
    }

    exclude = (id) => {
        this.setState({deleteId: id, deleteShow: true});
    }

    excludeDo = () => {
        authenticatedInstance.delete('/collection/' + this.props.currentCompanyId + '/opus/' + this.state.deleteId).then(response => {
            this.loadCollection();
        }).catch(error => {
            this.setState({error: true});
        });
    }

    excludeCancel = () => {
        this.setState({deleteShow: false});
    }

    searchTitleHandler = (event) => {
        this.setState({searchTitle: event.target.value});
    }

    searchAuthorHandler = (newValue, actionMeta) => {
        let searchAuthor_ = {
            ...this.state.searchTitle
        };
        searchAuthor_ = newValue;
        this.setState({searchAuthor: searchAuthor_});
    }

    searchInstrumentationsHandler = (newValue, actionMeta) => {
        let searchInstrumentations_ = {
            ...this.state.searchInstrumentations
        };
        searchInstrumentations_ = newValue;
        this.setState({searchInstrumentations: searchInstrumentations_});
    }

    searchStyleHandler = (newValue, actionMeta) => {
        let searchStyle_ = {
            ...this.state.searchStyle
        };
        searchStyle_ = newValue;
        this.setState({searchStyle: searchStyle_});
    }

    setPage = (e, page) => {
        e.preventDefault();
        this.setState({page: page});
        this.loadCollection(page);
    }

    render() {

        let deleteTitleModal = 'Excluir Obra';
        let deleteMessageModal = 'Tem certeza que deseja excluir?';
        let modalExclude = "";

        if(this.state.deleteShow) {
            modalExclude = (
                <ModalDelete
                    title={deleteTitleModal}
                    message={deleteMessageModal}
                    handler={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

        let modalShow = "";

        if(this.state.modalShow) {
            modalShow = (
                <ModalShow
                    item={this.state.showItem}
                    delete={() => this.exclude(this.state.showItem.id)}
                    cancel={() => this.showCancel()}
                    show={true}
                />
            );
        }

        return (
            <Fragment>
                <FormGroup row>
                    <Col sm="11">
                        <h2>Acervo</h2>
                    </Col>
                    <Col sm="1">
                        <Link to={"/c/"+ this.props.currentCompany.slug +"/scores/opus/add"}>
                            <Button color="primary">Nova Obra</Button>
                        </Link>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={2}>
                        <Label for="title">Título</Label>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            placeholder="Título da obra"
                            onChange={(event) => this.searchTitleHandler(event)}
                            onKeyPress={this.searchItemHandlerKeyPress}
                        />
                    </Col>
                    <Col sm={3}>
                        <Label for="author">Autor</Label>
                        <SelectAsync
                            id="author"
                            name="author"
                            isMulti="true"
                            matchPos="any"
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/' + this.props.currentCompanyId + '/authors', 'name')}
                            onChange={this.searchAuthorHandler}
                        />
                    </Col>
                    <Col sm={3}>
                        <Label for="instrumentations">Instrumentação</Label>
                        <SelectAsync
                            id="instrumentations"
                            name="instrumentations"
                            isMulti="true"
                            matchPos="any"
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/instrumentations', 'title')}
                            onChange={this.searchInstrumentationsHandler}
                        />
                    </Col>
                    <Col sm={3}>
                        <Label for="style">Estilo</Label>
                        <SelectAsync
                            id="style"
                            name="style"
                            isMulti="true"
                            matchPos="any"
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/styles', 'title')}
                            onChange={this.searchStyleHandler}
                        />
                    </Col>
                    <Col sm={1}>
                        <Label for="btn_search">Buscar</Label>
                        <Button id="btn_search" color="primary" onClick={e => this.setPage(e, 1)}>Pesquisar</Button>
                    </Col>
                </FormGroup>
                <Table dark>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Título</th>
                            <th>Autor</th>
                            <th>Visualizar</th>
                            <th>Editar</th>
                            <th>Excluir</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        typeof this.state.itens != 'undefined' ?
                            this.state.itens.map((item, index) => {
                                console.log('item: ', item);
                                return (
                                    <tr key={index}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.title}</td>
                                        <td>{item.author.name}</td>
                                        <td>
                                            <Link to={"/c/"+ this.props.currentCompany.slug + '/scores/opus/view/' + item.id}>
                                                <Button color="primary" onClick={() => this.show(item)}>Visualizar</Button>&nbsp;&nbsp;
                                            </Link>
                                            <Button color="primary" onClick={() => this.show(item)}><FiEye size="1.3em"/></Button>
                                        </td>
                                        <td>
                                            <Link to={"/c/"+ this.props.currentCompany.slug + '/scores/opus/edit/' + item.id}>
                                                <Button color="warning">Editar</Button>
                                            </Link>
                                        </td>
                                        <td><Button color="danger" onClick={() => this.exclude(item.id)}>Excluir</Button></td>
                                    </tr>
                                );
                            })
                        :
                        <tr><th scope="row">Nenhum item encontrado!</th></tr>
                    }
                    </tbody>
                </Table>
                <Paginator
                    pages={this.state.pages}
                    page={this.state.page}
                    handlePage={this.setPage}
                />
                {modalExclude}
                {modalShow}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        isAuth: state.auth.isAuthenticated,
        currentCompany: state.company
	}
};


const mapDispatchProps = dispatch => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchProps)(Collection);
