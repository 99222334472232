import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import {
    Button,
    Col, 
    Form, 
    FormGroup, 
    Label, 
    Input 
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import toastfy from '../../Aux/Toastfy/Toastfy';
import * as actions from '../../../store/actions/index';
import authenticatedInstance from '../../../api/authenticated';
import AddressForm from '../../Aux/AddressForm/AddressForm';
import CompanyBox from '../../Aux/CompanyBox/CompanyBox';
import withRouter from '../../Aux/WithRouter/WithRouter';
import SympleItensGet from '../../../api/sympleItensGet';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';

class UserMainForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            form:  {
                id: 0,
                current_password: '',
                name: '',
                email: '',
                birth_date: '',
                phone: '',
                selectedInstruments: [],
                instruments: [],
                'own_company': {
                    address: {
                        country_id: '',
                        country: {
                            label: '',
                            value: ''
                        },
                        state: '',
                        address_1: '',
                        address_2: '',
                        number: '',
                        complement: '',
                        neighborhood: ''
                    }
                }
            }
        }
    }

    componentDidMount() {
        console.log("componentDidMount", this.props.info)
        let updatedForm = {...this.state.form};
        let selectedInstruments = [];
        updatedForm = this.props.info;
        updatedForm.own_company.address = this.props.info.own_company.address;
        updatedForm.instruments.map((value) => {
            selectedInstruments.push({ value: value.id, label: value.title });
            return selectedInstruments;
        });
        updatedForm.selectedInstruments = selectedInstruments;
    this.setState({form: updatedForm});
    }

    inputChangedHandler = (event, inputIdentifier) => {
        console.log('fiedls name: ', inputIdentifier);
        console.log('fiedls changed: ', event.target.value);

        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    inputAddressChangedHandler = (event, inputIdentifier) => {
        console.log('fiedls name: ', inputIdentifier);
        console.log('fiedls changed: ', event.target.value);

        const updatedForm = {...this.state.form};
        updatedForm['own_company']['address'][inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    inputCreatableChanged = (newValue, actionMeta) => {
        console.log('label 1:', actionMeta.name);
        console.log('value 2:', newValue);

        const updatedForm = {...this.state.form};
        updatedForm['own_company']['address'][actionMeta.name + '_id'] = newValue.value;
        this.setState({form: updatedForm});
    };

    handleInstrumentChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.selectedInstruments = newValue;
        this.setState({ form: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/instruments', 'title');
        const updatedForm = {...this.state.form};
        const new_instrument = {
          value: newInstrument.id,
          label: newInstrument.title
        };
        updatedForm.instruments.push(new_instrument);
        this.setState({
          form: updatedForm
        });
    }

    submitHandler = (data) => {
        const updatedForm = {...this.state.form};
        updatedForm.instruments = [];
        this.state.form.selectedInstruments.map((value) => {
            updatedForm.instruments.push(value.value);
        });
        delete updatedForm.id;
        delete updatedForm.companies;
        delete updatedForm.last_logged_in;
        delete updatedForm.roles;
        delete updatedForm.selectedInstruments;

        authenticatedInstance.put('/user/update', updatedForm).then(response => {
            this.props.onInfo();
            toastfy("success", 'Informações alteradas com sucesso!');
            return response.data;
        }).catch(error => {
            toastfy("error", 'Houve algum problema ao atualizar, tente novamente mais tarde.');
            this.setState({error: true});
        });
    }

    render() {

        return (
        <CompanyBox>
            <Form>
                <FormGroup row>
                    <legend>Informações Básicas</legend>
                    {/* <Col sm={4}>
                        <Label for="password">Senha Atual</Label>
                        <Input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="************"
                            onChange={(event) => this.inputChangedHandler(event, 'current_password')}
                        />

                    </Col> */}
                    <Col sm={4}>
                        <Label for="title">Instrumentos</Label>
                        <SelectAsync
                            id="instruments"
                            name="instruments"
                            isMulti="true"
                            matchPos="any"
                            value={this.state.form.selectedInstruments}
                            loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/instruments', 'title')}
                            onCreateOption={this.onCreateInstruments}
                            onChange={this.handleInstrumentChange}
                          />
                    </Col>
                    <Col sm={8}>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label for="name">Nome</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Seu Nome"
                                    onChange={(event) => this.inputChangedHandler(event, 'name')}
                                    value={this.state.form.name} />
                            </Col>
                            <Col sm={6}>
                                <Label for="email">E-mail</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="mail@domain.com"
                                    onChange={(event) => this.inputChangedHandler(event, 'email')}
                                    value={this.state.form.email} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={6}>
                                <Label for="email">Phone</Label>
                                <InputMask
                                    className="form-control"
                                    mask="+55 (99) 99999 9999"
                                    maskChar=" "
                                    onChange={(event) => this.inputAddressChangedHandler(event, 'phone')}
                                    value={this.state.form.own_company.address.phone}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label for="email">Birth Date</Label>
                                <InputMask
                                    className="form-control"
                                    mask="99/99/9999"
                                    maskChar=" "
                                    onChange={(event) => this.inputChangedHandler(event, 'birth_date')}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.form.birth_date}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <legend>Address</legend>
                    <Col sm="12">
                        <AddressForm
                            fields={this.state.form.own_company}
                            inputChanged={this.inputAddressChangedHandler}
                            inputCreatableChanged={this.inputCreatableChanged}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <Col sm="1">
                        <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                    </Col>
                </FormGroup>
            </Form>
        </CompanyBox>
        );
    }
}

const mapStateToProps = state => {
	return {
        info: state.auth.info,
	}
};

const mapDispatchProps = dispatch => {
	return {
		onInfo: () => dispatch(actions.info()),
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(UserMainForm));
