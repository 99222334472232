import axios from 'axios';
import { startStatus, endStatus, handleError } from './interceptors';

// const baseURL = 'http://127.0.0.1:8000/api/v1';
// const baseURL = 'http://192.168.0.121:8000/api/v1';
const baseURL = 'http://api.camerataaccord.com.br/api/v1'
// const baseURL = `${SERVER_URL}v1`;

const notAuthenticatedInstance = axios.create({
  baseURL,
  'Content-Type': 'application/json',
  timeout: 30 * 1000
});

notAuthenticatedInstance.interceptors.request.use(startStatus, handleError);
notAuthenticatedInstance.interceptors.response.use(endStatus, handleError);

export default notAuthenticatedInstance;
