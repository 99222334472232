
const googleMapsSearchLink = (strit_location, number, city) => {
    if (city !== '') {
        const address = 
            (strit_location !== '' ? strit_location.toLowerCase() + ' ' : '') +
            (number !== '' ? number.toLowerCase() + ' ' : '') +
            city.toLowerCase();
    
        return 'http://maps.google.com/?q=' + address.replaceAll(' ', '+');
    }

    return '#';
};

export default googleMapsSearchLink;