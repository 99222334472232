import React, {Fragment, Component} from 'react';
import authenticatedInstance from '../../api/authenticated';
import SimpleItemAddSubmit from '../../api/simpleItemAddSubmit';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import LoaderContext from '../../context/LoaderContext';
import toastfy from '../Aux/Toastfy/Toastfy';
import withRouter from '../Aux/WithRouter/WithRouter';

class SimpleItemForm extends Component {
    static contextType = LoaderContext;

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            id: null,
            title: '',
            title_var_name: 'title',
            entity: {}
        }
    }

    componentDidMount() {
        this.loadInfo();
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.api_entity !== this.props.api_entity) {
            this.loadInfo();
        }
    }

    loadInfo() {
        this.context.toggle();
        authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/'+ this.props.api_entity +'/' + this.props.params.id).then(response => {
            this.setState({
                title: response.data[this.props.title_var_name],
                id: response.data['id']
            });
            this.context.toggle();
        }).catch(error => {
            this.setState({error: true});
            this.context.toggle();
        });
    }

    inputChangedHandler = (event) => {
        const updatedTitle = event.target.value;
        this.setState({title: updatedTitle});
    }

    submitHandler = () => {
        console.log(this.props.currentCompanyId);
        const entity = {};
        entity[this.props.title_var_name] = this.state.title;
        entity['id'] = this.state.id;
        if (typeof this.props.action != "undefined" && this.props.action === "add") {
            SimpleItemAddSubmit(entity, '/collection/'+ this.props.currentCompanyId +'/'+ this.props.api_entity, this.props.title_var_name);
        } else {
            this.context.toggle();
            authenticatedInstance.put('/collection/'+ this.props.currentCompanyId +'/'+ this.props.api_entity +'/' + this.state.id, entity)
            .then(response => {
                this.context.toggle();
                toastfy("success", 'Item editado com sucesso!');
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao editar o item. Aguarde um momento e tente novamente.');
                this.setState({error: true});
                this.context.toggle();
            });
        }
    }

    render() {
        return (<Fragment>
            <FormGroup row="row">
                <Col sm="12">
                    <h2>{this.props.page_title}</h2>
                </Col>
            </FormGroup>
            <FormGroup row={true}>
                <Col sm="12">
                    <Label for="title">Título</Label>
                    <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Título da obra"
                        onChange={(event) => this.inputChangedHandler(event)}
                        value={this.state.title}
                    />
                </Col>
            </FormGroup>
            <FormGroup row={true}>
                <Col sm="1">
                    <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                </Col>
            </FormGroup>
        </Fragment>);
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

export default connect(mapStateToProps, null)(withRouter(SimpleItemForm));
