import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import authenticatedInstance from '../../../api/authenticated';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    CardTitle,
    Row
} from 'reactstrap';
import { Navigate } from 'react-router-dom';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../../store/actions/index';
import sympleItensGet from '../../../api/sympleItensGet';
import withRouter from '../../Aux/WithRouter/WithRouter';


class FormEvent extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            successAdded: false,
            form: {
                id: null,
                title: null,
                description: null,
                date_time: null,
                date: null,
                time: null,
                budget: null,
                local: {id: null, title: null, description: null},
                company: {id: null, slug: null},
                type: {id: null, title: null},
                staff: [],
                financial: []
            }
        }
    }

    componentDidMount() {
        if (
            this.props.edit
            && typeof this.props.params !== 'undefined'
            && typeof this.props.params.id !== 'undefined'
            // && prevProps.params.id !== this.props.params.id
        ) {
            this.setState({
                form: {
                    id: null,
                    title: null,
                    description: null,
                    date_time: null,
                    budget: null,
                    local: {id: null, title: null, description: null},
                    company: {id: null, slug: null},
                    type: {id: null, title: null},
                    staff: [],
                    financial: []
                }
            });
            this.loadEvent(this.props.params.id);
            console.log("FormEvent this.loadEvent")
        }
    }

    loadEvent = async (id) => {
        console.log('loadEvent', id);
        // Load Event
        try {
            authenticatedInstance.get('/collection/'+ this.props.currentCompanyId +'/event/' + id)
            .then(response => {
                console.log('response: ', response.data);
                moment.locale('pt-br');
                const dateTimeObj = moment(response.data.date_time);
                this.setState({
                    form: {
                        id: response.data.id,
                        title: response.data.title,
                        description: response.data.description,
                        date_time: response.data.date_time,
                        date: dateTimeObj.format('YYYY-MM-DD'),
                        time: dateTimeObj.format('LT'),
                        budget: response.data.budget,
                        local: response.data.local,
                        company: response.data.company,
                        type: response.data.type,
                        staff: response.data.staff,
                        financial: response.data.financial

                    }
                });
            });
        } catch (error) {
            this.setState({ error: true });
        }
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        // const updatedForm = {...this.state.form};
        let updatedForm = {};
        updatedForm['title'] = this.state.form.title;
        updatedForm['description'] = this.state.form.description;
        updatedForm['local_id'] = this.state.form.local_id;
        updatedForm['type_id'] = this.state.form.type_id;
        updatedForm['company_id'] = this.props.currentCompanyId;
        updatedForm['date_time'] = this.state.form.date +' '+ this.state.form.time +':00';
        console.log("updatedForm", updatedForm);

        if(this.props.edit) {
            authenticatedInstance.put('/collection/'+ this.props.currentCompanyId +'/event/'+ this.props.params.id, updatedForm).then(response => {
                toastfy("success", 'Evento alterado com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/'+ this.props.currentCompanyId +'/event', updatedForm).then(response => {
                toastfy("success", 'Evento adicionado com sucesso!');
                this.setState({
                    successAdded: true,
                    form: {
                        id: response.data.id
                    }
                });
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar o evento...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    onCreateEventLocal = async (title) => {
        const newEventLocal = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/event_local', 'title');
        const updatedForm = {...this.state.form};
        updatedForm.local.title = newEventLocal.name;
        updatedForm.local.id = newEventLocal.id;
        updatedForm.local_id = newEventLocal.id;
        this.setState({
            form: updatedForm
        });
    }

    handleChangeEventLocal = (newValue, actionMeta) => {
        console.log('handleChangeEventLocal', newValue);
        const updatedForm = {...this.state.form};
        updatedForm.local_id = newValue.value;
        updatedForm.local.id = newValue.value;
        updatedForm.local.title = newValue.label;
        this.setState({ form: updatedForm });
    };

    onCreateEventType = async (title) => {
        const newEventType = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/event_type', 'title');
        const updatedForm = {...this.state.form};
        updatedForm.type.title = newEventType.name;
        updatedForm.type.id = newEventType.id;
        updatedForm.type_id = newEventType.id;
        this.setState({
            form: updatedForm
        });
    }

    handleChangeEventType = (newValue, actionMeta) => {
        console.log('handleChangeEventType', newValue);
        const updatedForm = {...this.state.form};
        updatedForm.type_id = newValue.value;
        updatedForm.type.id = newValue.value;
        updatedForm.type.title = newValue.label;
        this.setState({ form: updatedForm });
    };

    render() {
        console.log('event form:', this.state.form);
        if(this.state.successAdded) {
            return (
                <Navigate to={"/c/"+ this.props.currentCompany.slug +"/events/view/"+ this.state.form.id} />
            );
        }

        return (
        <Fragment>
            <CardTitle tag="h4">
                <Row>
                    <Col sm={4}>
                        Novo Evento
                    </Col>
                </Row>
            </CardTitle>
            <Form>
                <FormGroup row>
                    <Col sm={6}>
                        <Label for="title">Título</Label>
                        <Input type="text" name="title" id="title" placeholder="Título da obra" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                    </Col>
                    <Col sm={3}>
                        <Label for="date">Data</Label>
                        <Input type="date" name="date" id="date" placeholder="Data" onChange={(event) => this.inputChangedHandler(event, 'date')} value={this.state.form.date} />
                    </Col>
                    <Col sm={3}>
                        <Label for="time">Horário</Label>
                        <Input type="time" name="time" id="time" placeholder="Horário" onChange={(event) => this.inputChangedHandler(event, 'time')} value={this.state.form.time} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={4}>
                        <Label for="eventType">Tipo de Evento</Label>
                        <SelectAsync
                            id="eventType"
                            name="eventType"
                            matchPos="any"
                            value={{value: this.state.form.type.id, label: this.state.form.type.title}}
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/events/types', 'title')}
                            onCreateOption={this.onCreateEventType}
                            onChange={this.handleChangeEventType}
                        />
                    </Col>
                    <Col sm={8}>
                        <Label for="local">Local</Label>
                        <SelectAsync
                            id="eventLocal"
                            name="eventLocal"
                            matchPos="any"
                            value={{value: this.state.form.local.id, label: this.state.form.local.title}}
                            loadOptions={(inputValue) => sympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/events/locals', 'title')}
                            onCreateOption={this.onCreateEventLocal}
                            onChange={this.handleChangeEventLocal}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="description">Descrição</Label>
                        <Input type="textarea" name="description" id="description" placeholder="Descrição" onChange={(event) => this.inputChangedHandler(event, 'description')} value={this.state.form.description} />
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <Col sm="1">
                        <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                    </Col>
                </FormGroup>
            </Form>
        </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentCompany: state.company,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormEvent));
