import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    access_token: null,
    expires_at: null,
    error: null,
    loading: false,
    companies: [],
    info: {
        companies: []
    },
    isAuthenticated: false
};

const authStart = ( state, action ) => {
    return updateObject(
        state,
        {
            error: null,
            loading: true
        }
    );
};

const infoStart = ( state, action ) => {
    return updateObject( 
        state, 
        { 
            error: null, 
            loading: true 
        } 
    );
};

const authSuccess = (state, action) => {
    console.log('reducer authSuccess', action);
    localStorage.setItem('access_token', action.authData.access_token);
    localStorage.setItem('expires_at', action.authData.expires_at);
    return updateObject(state, {
        access_token: action.authData.access_token,
        expires_at: action.authData.expires_at,
        // isAuthenticated: true,
        error: null,
        loading: false
     });
};

const updateCompanies = ( state, action ) => {
    let info = JSON.parse(localStorage.getItem('info'));
    // info['companies'] = action.companies;
    info.companies = Object.entries(action.companies);
    localStorage.setItem('info', JSON.stringify(info));
    return updateObject(
        state,
        {
            info: info
        }
    );
};

const authInfo = (state, action) => {
    localStorage.setItem('info', JSON.stringify(action.infoData));
    return updateObject(
        state,
        {
            isAuthenticated: true,
            info: action.infoData,
            loading: false
        }
    );
}

const isAuthenticated = (state, action) => {
    let expiresAt = state.expires_at;

    if(typeof state.expires_at != 'string') {
        expiresAt = localStorage.getItem('expires_at');
    }

    if(!(Date.parse(expiresAt) > Date.now())) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('info');
        return updateObject(state, {
            access_token: null,
            expires_at: null,
            isAuthenticated: false,
            error: null,
            loading: false,
            info: null
         });
    }

    return updateObject(state, {
        access_token: localStorage.getItem('access_token'),
        expires_at: expiresAt,
        info: JSON.parse(localStorage.getItem('info')),
        isAuthenticated: true,
        error: null,
        loading: false
     });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state, action) => {
    localStorage.clear();

    return updateObject(
        state,
        {
            access_token: null,
            expires_at: null,
            isAuthenticated: false,
            error: null,
            loading: false,
            info: null
        }
    );
}

const reducer = ( state = initialState, action ) => {
    // console.log('reducer', action);
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.AUTH_INFO_START: return infoStart(state, action);
        case actionTypes.AUTH_INFO: return authInfo(state, action);
        case actionTypes.AUTH_IS_AUTHENTICATED: return isAuthenticated(state, action);
        case actionTypes.AUTH_UPDATE_COMPANIES: return updateCompanies(state, action);
        default:
            return state;
    }
};

export default reducer;
