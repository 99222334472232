import React, { PureComponent, Fragment } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    List,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Placeholder
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../../store/actions/index';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';
import { 
    FiUser
} from "react-icons/fi";
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormProgram extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            searchType: "name",
            arrangementOptions: [],
            arrangementsLoading: false,
            edit: false,
            event_id: 0,
            arrangements: [],
            form:  {
                id: 0,
                opus_id: 0,
                author_id: 0,
                title: '',
                author: '',
                obs: '',
                arrangement: {},
                instrument: {}
            }
        }
    }

    componentDidMount() {
        console.log('componentDidMount')
        this.loadForm();
    }
    
    componentDidUpdate(prevProps) {
        console.log('componentDidUpdate', prevProps, this.props)
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        const form = {...this.state.form};
        let arrangementOptions = [];
        if(this.props.program !== null) {
            console.log('this.props.program', this.props.program);
            // EDIT
            if(this.props.edit) {
                form.id = this.props.program.id;
                form.opus_id = this.props.program.opus_id;
                form.author_id = this.props.program.author_id;
                form.title = this.props.program.title
                form.author = this.props.program.author
                form.obs = this.props.program.obs;
                form.arrangement = this.props.program.arrangement;
                form.instrument = this.props.program.instrument;
                this.handleArrangementSearch(this.props.program.opus_id);
            }
        }

        this.setState({
            isOpen: open,
            playlist_id: this.props.playlistId,
            arrangementOptions: arrangementOptions,
            form: form
        });
    }

    resetForm = () => {
        this.setState({
            arrangementOptions: [],
            // edit: false,
            form: {
                id: 0,
                opus_id: 0,
                author_id: 0,
                title: '',
                author: '',
                obs: '',
                arrangement: {},
                instrument: {}
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {
            opus_id: this.state.form.opus_id,
            arrangement_id: (this.state.form.arrangement !== null && typeof this.state.form.arrangement.id !== 'undefined' ? this.state.form.arrangement.id : null),
            arrangement_instrument_id: (this.state.form.instrument !== null && typeof this.state.form.instrument.arrangement_instrument_id !== 'undefined' ? this.state.form.instrument.arrangement_instrument_id : null),
            obs: this.state.form.obs
        };
        console.log("submitHandler", updatedForm);

        if(this.props.edit) {
            delete updatedForm.id;
            authenticatedInstance.put('/playlist/'+ this.state.playlist_id +'/program/' + this.state.form.id, updatedForm).then(response => {
                toastfy('success', 'Música alterada com sucesso!');
                // console.log("submit", arrangement);
                this.props.returnFunction({
                    id: this.state.form.id,
                    opus_id: this.state.form.opus_id,
                    author_id: this.state.form.user_id,
                    opus: {
                        title: this.state.form.title,
                        author: {
                            name: this.state.form.author
                        }
                    },
                    obs: this.state.form.obs,
                    arrangement: this.state.form.arrangement,
                    instrument: this.state.form.instrument
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar alterar a música...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/playlist/'+ this.state.playlist_id +'/program', updatedForm).then(response => {
                toastfy('success', 'Música adicionada com sucesso!');
                // console.log("submit", this.state.form.arrangement);
                this.props.returnFunction({
                    id: response.data.id,
                    ordering: 0,
                    opus_id: this.state.form.opus_id,
                    author_id: this.state.form.user_id,
                    opus: {
                        title: this.state.form.title,
                        author: {
                            name: this.state.form.author
                        }
                    },
                    obs: this.state.form.obs,
                    arrangement: this.state.form.arrangement,
                    instrument: this.state.form.instrument
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar adicionar a música...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    handleOpusSearch = (inputValue) => {
        if(inputValue.length >= 3) {
            const queryStr = "?str=" + inputValue;

            return authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/opus/search' + queryStr)
                .then(response => {
                    let optionsArray = [];
                    response.data['itens'].map((option) => {
                        optionsArray.push({
                            value: parseInt(option.opus_id),
                            author: option.author,
                            title: option.title,
                            avatar: (typeof option.photo !== "undefined" && option.photo !== null ? option.photo.path : null)
                        });
                        return optionsArray;
                    });
                    // console.log(optionsArray);
                    return optionsArray;
                }).catch(function(error) {
                    console.log(error);
                });
        }
    };

    handleArrangementSearch = (opusId) => {
        if(
            !this.state.arrangementsLoading 
            && this.state.form.opus_id === 0
            && this.state.arrangementOptions.length === 0
        ) {
            this.setState({arrangementsLoading: true});
            return authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/opus/' + opusId + '/arrangements')
                .then(response => {
                    this.setState({
                        arrangementOptions: response.data['arrangements']['data'],
                        arrangementsLoading: false
                    });
                    return response.data['arrangements']['data'];
                }).catch(function(error) {
                    console.log(error);
                    this.setState({arrangementsLoading: false});
                });
        }
    };

    handleOpusChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.opus_id     = newValue.value;
        updatedForm.title       = newValue.title;
        updatedForm.author      = newValue.author;
        updatedForm.author_id   = newValue.author_id;
        updatedForm.avatar      = newValue.avatar;
        this.handleArrangementSearch(newValue.value);
        this.setState({form: updatedForm});
    };

    handleArrangementChange = (arrangement) => {
        console.log("handleArrangementChange", arrangement);
        const form = {...this.state.form};
        form.arrangement = arrangement;
        this.setState({form: form});
    };

    handleInstrumentChange = (instrument) => {
        console.log("handleInstrumentChange", instrument);
        const form = {...this.state.form};
        form.instrument = instrument;
        this.setState({form: form});
    };

    onCreateOpus = async (name) => {
        const newOpus = await SimpleItemAddSubmit(name, '/collection/'+ this.props.currentCompanyId +'/programs', 'name');
        const updatedForm = {...this.state.form};
        updatedForm.user_id = newOpus.id;
        updatedForm.name = newOpus.name;
        this.setState({
          form: updatedForm
        });
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    toogleSearch = (type) => {
        this.setState({searchType: type});
    };

    render() {

        let arrangements = <Fragment />;
        if(this.state.arrangementsLoading) {
            arrangements = (
                <Fragment>
                <Placeholder
                    animation="glow"
                    tag="h2"
                >
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder
                    animation="wave"
                    tag="p"
                >
                    <Placeholder xs={12} />
                </Placeholder>
                <Placeholder
                    animation="wave"
                    tag="p"
                >
                    <Placeholder xs={12} />
                </Placeholder>
                </Fragment>
            );
        } else if(this.state.arrangementOptions.length > 0) {
            arrangements = this.state.arrangementOptions.map((option, index) => {
                let defaultChecked = false;
                if(
                    typeof this.state.form.arrangement.id !== 'undefined'
                    && parseInt(this.state.form.arrangement.id) === parseInt(option.id) 
                ) {
                    defaultChecked = true;
                }
                return (
                    <FormGroup check key={index}>
                        <Row>
                            <Col sm={1}>
                                <Input
                                    defaultChecked={defaultChecked}
                                    id={option.id}
                                    name="arrangementOptions"
                                    type="radio"
                                    onChange={(arrangement) => this.handleArrangementChange(option)}
                                />
                            </Col>
                            <Col sm={11}>
                                <h5>{option.title} - {option.tone.eu}</h5>
                                <p>{option.description}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <Label check>
                                    <p>
                                    {
                                        option.instrumentations.map((instr, i) => {
                                            return (<Fragment><Badge color="success">{instr.title}</Badge>&nbsp;&nbsp;</Fragment>); 
                                        })
                                    }
                                    </p>
                                </Label>
                            </Col>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <List type="unstyled">
                                {option.instruments.map((instr, i) => {
                                    return (
                                        <li key={i}>
                                            <Input
                                                defaultChecked={this.state.form.instrument !== null && instr.arrangement_instrument_id === this.state.form.instrument.id}
                                                id={instr.arrangement_instrument_id}
                                                name="InstrumentOptions"
                                                type="radio"
                                                onChange={(instrument) => this.handleInstrumentChange(instr)}
                                            />
                                            {instr.title}{typeof instr.number == 'number' && instr.number !== 0 ? ' ' + instr.number : ''}
                                        </li>
                                    );
                                })}
                                </List>
                            </Col>
                        </Row>
                    </FormGroup>
                );
            })
        }

        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
            // onClosed={() => this.resetForm()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        {this.props.edit ? 'Editar Música' : 'Adicionar Música'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                    {!this.props.edit ?
                        <Fragment>
                        <Label for="user">Busque pelo título:</Label>
                        <SelectAsync
                            id="user"
                            name="user"
                            matchPos="any"
                            formatOptionLabel={formatOptionLabel}
                            value={{value: this.state.form.opus_id, author: this.state.form.author, title: this.state.form.title, avatar: this.state.form.avatar}}
                            loadOptions={(inputValue) => this.handleOpusSearch(inputValue)}
                            onCreateOption={this.onCreateOpus}
                            onChange={this.handleOpusChange}
                        />
                        </Fragment>
                        :
                        <h3>{this.state.form.title}</h3>
                    }
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Observação:</Label>
                        <Input type="text" name="obs" id="obs" placeholder="Observação" onChange={(event) => this.inputChangedHandler(event, 'obs')} value={this.state.form.obs} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        {arrangements}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const formatOptionLabel = ({ value, author, title, avatar}) => (
    <Row>
        <Col sm={2}>
            {avatar == null ? (<FiUser size="1.7em" />) : (<img alt={author} src={avatar} style={{width:"1.7em", height:"1.7em", borderRadius:"50%"}} />)}
        </Col>
        <Col sm={10}>
            <h6>{author}</h6>
            <h5>
                <span className='primary'>{title}</span>
            </h5>
        </Col>
    </Row>
);

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormProgram));
