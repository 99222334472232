import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import CompanyAvatar from '../CompanyAvatar/CompanyAvatar';
import { FiAlignLeft } from "react-icons/fi";
import {
    Navbar,
    Button,
    NavbarToggler,
    Collapse,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './TopNavBar.css';

const TopNavBar = (props) => {

    const [isOpen, setIsOpen] = React.useState(false);
    
    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Navbar color="light" light className="navbar shadow-sm p-3 mb-3 bg-white rounded" expand="md">
            <Button color="info" onClick={props.toggle}>
                <FiAlignLeft size="1.5em" />
            </Button>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="container-fluid justify-content-end" navbar>
                    <NavItem>
                        <NavLink
                            to="/"
                        >Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            to="/components"
                        >Componentes</NavLink>
                    </NavItem>
                    {
                        (
                            props.auth.info !== null
                            && props.auth.info.companies !== null
                            && typeof props.auth.info.companies === 'object'
                            && typeof props.auth.info.companies.length !== 'undefined' 
                            && props.auth.info.companies.length > 0
                        ) ?
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Meus conjuntos
                                </DropdownToggle>
                                <DropdownMenu end={true}>
                                    {props.auth.info.companies.map((company, index) => (
                                        <DropdownItem key={index}>
                                            <NavLink
                                                to={'/c/' + company.slug}
                                            >{company.title}</NavLink>
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            :
                            <Fragment />
                    }
                    {
                        props.isAuth ?
                            <Fragment>
                                <UncontrolledDropdown  className="ml-auto" nav>
                                    <DropdownToggle nav caret>
                                        <CompanyAvatar own={true} size="28" round="5px" />
                                    </DropdownToggle>
                                    <DropdownMenu end={false}>
                                        <DropdownItem>
                                            <NavLink
                                                to="/my"
                                            >My Workspace</NavLink>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <NavLink
                                                to="/my/edit/main"
                                            >Edit Account</NavLink>
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            <NavLink
                                                to="/logout"
                                            >Logout</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Fragment>
                            :
                            <Fragment>
                                <NavItem>
                                    <NavLink to="/login">Login</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/signup">Sign Up</NavLink>
                                </NavItem>
                            </Fragment>
                    }
                </Nav>
            </Collapse>
        </Navbar>
    );
}

const mapStateToProps = state => {
	return {
		infoData: state.infoData,
		auth: state.auth,
		info: state.auth.info,
		isAuth: state.auth.isAuthenticated
	}
};

const mapDispatchProps = dispatch => {
	return {
        info: () => dispatch(actions.info()),
	};
};

export default connect(mapStateToProps, mapDispatchProps)(TopNavBar);
