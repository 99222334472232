import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col
} from 'reactstrap';
import classNames from 'classnames';
import * as actions from '../../../store/actions/index';
import SideBar from '../../sidebar/SideBar';
import TopNavBar from '../TopNavBar/TopNavBar';

class Layout extends Component {
    
    constructor(props) {
        super(props);

        // if(
        //     typeof this.props.auth === "undefined"
        //     || this.props.auth === null
        //     || this.props.auth.info === null
        // ) {
        //     console.log("TopNavBar setCurrentCompany", this.props.info);
        //     this.props.info();
        // }

        this.state = {
            showSideDrawer: false
        }
    }

    // componentDidMount() {
    //     console.log("Layout componentDidMount", this.props.info)
    // }
    
    componentDidUpdate(prevProps) {
        // if (this.props.info === null) {
        //     this.props.setInfo();
        // }

        // if (
        //     this.props.currentCompany.id === null
        //     && this.props.info !== null
        // ) {
        //     console.log("Layout setCurrentCompany");
        //     this.props.setCurrentCompany(this.props.info.own_company);
        // }
    }

    // sideDrawerClosedHandler = () => {
    //     this.setState( { showSideDrawer: false } );
    // }

    // sideDrawerToggleHandler = () => {
    //     this.setState( ( prevState ) => {
    //         return { showSideDrawer: !prevState.showSideDrawer };
    //     } );
    // }

    render () {
        return (
            <Fragment>
                <SideBar toggle={this.props.toggle} isOpen={this.props.isOpen}/>
                <Container fluid="fluid" className={classNames('content', {'is-open': this.props.isOpen})}>

                    <TopNavBar
                        toggle={this.props.toggle}
                    />

                    <Col sm="12">
                        {this.props.children}
                    </Col>

                </Container>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
	return {
        info: state.auth.info,
        currentCompany: state.company
	}
};

const mapDispatchProps = dispatch => {
	return {
		// setCurrentCompany: (company) => dispatch(actions.setCurrentCompany(company)),
        setInfo: () => dispatch(actions.info())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(Layout);
