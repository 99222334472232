import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderContext from '../../../context/LoaderContext';
import withRouter from '../../Aux/WithRouter/WithRouter';
import './Company.css';

class Company extends Component {
	static contextType = LoaderContext;

	constructor(props) {
        super(props);
	}

	render() {
    	return (
            <h1>Olá {this.props.currentCompany.title}!</h1>
        );
	}
}

const mapStateToProps = state => {
	return {
		currentCompany: state.company
	}
};

export default connect(mapStateToProps, null)(withRouter(Company));
