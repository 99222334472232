import React, { PureComponent } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import { 
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Row,
    UncontrolledAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    InputGroup,
    InputGroupText
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../../store/actions/index';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';
import SympleItensGet from '../../../api/sympleItensGet';
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormStaff extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            openAccordion: 1,
            form:  {
                id: 0,
                title: '',
                instrument_id: 0,
                instrument: {id: 0, title: ''},
                number: '',
                obs: '',
                budget: 0
            }
        }
    }

    componentDidMount() {
        this.loadForm();
    }

    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        let form = {
            id: 0,
            title: '',
            instrument_id: 0,
            instrument: {id: 0, title: ''},
            number: '',
            obs: '',
            budget: 0
        };

        if(this.props.staff !== null) {
            form = {...this.props.staff};
            form["instrument"] = {
                id: form.instrument_id,
                title: form.title
            };
        }
        this.setState({isOpen: open, form: form});
    }

    resetForm = () => {
        const form = {
            id: 0,
            title: '',
            instrument_id: 0,
            instrument: {id: 0, title: ''},
            number: '',
            obs: '',
            budget: 0
        }
        this.setState({form: form});
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {...this.state.form};

        if(this.props.edit) {
            delete updatedForm.id;
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/staff/' + this.props.staff.id, updatedForm).then(response => {
                toastfy("success", 'Função alterada com sucesso!');
                this.props.returnFunction({
                    title: response.data.instrument.title,
                    abrev: response.data.instrument.abrev,
                    obs: response.data.obs,
                    budget: response.data.budget,
                    number: response.data.number
                });
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a função...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId +'/staff', updatedForm).then(response => {
                toastfy("success", 'Função adicionada com sucesso!');
                this.props.returnFunction({
                    id: response.data.id,
                    instrument_id: response.data.instrument.id,
                    title: response.data.instrument.title,
                    abrev: response.data.instrument.abrev,
                    obs: response.data.obs,
                    budget: response.data.budget,
                    number: parseInt(response.data.number),
                    ordering: parseInt(response.data.ordering),
                });
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a funçao...');
                this.setState({error: true});
                console.log("Staff submitHandler error", error);
                this.props.loaderStop();
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.instrument_id = newValue.value;
        updatedForm.instrument.id = newValue.value;
        updatedForm.instrument.title = newValue.label;
        this.setState({ form: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/instruments', 'title');
        const updatedForm = {...this.state.form};
        updatedForm.instrument = {
            id: newInstrument.id,
            title: newInstrument.title
        };
        updatedForm.instrument_id = newInstrument.id;
        this.setState({
          form: updatedForm
        });
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        console.log('onClosed')
        this.props.onClosed();
    }

    toogleAccordion = (id) => {
        if (this.state.openAccordion !== id) {
            this.setState({openAccordion: id});
        }
    };

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
            // onClosed={() => this.onClosed()}
            // onExit={() => this.onClosed()}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                    {this.props.edit ? 'Editar Cargo' : 'Adicionar Cargo'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <UncontrolledAccordion defaultOpen="1" open={this.state.openAccordion} toggle={this.toogleAccordion}>
                    <AccordionItem>
                    <AccordionHeader targetId="1">Músico</AccordionHeader>
                        <AccordionBody accordionId="1">
                        <FormGroup row>
                            <Col sm={9}>
                                <Label for="title">Instrumento</Label>
                                <SelectAsync
                                    id="instrument"
                                    name="instrument"
                                    matchPos="any"
                                    value={{value: this.state.form.instrument.id, label: this.state.form.instrument.title}}
                                    loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/' + this.props.currentCompanyId + '/instruments', 'title')}
                                    onCreateOption={this.onCreateInstruments}
                                    onChange={this.handleInstrumentChange}
                                />
                            </Col>
                            <Col sm={3}>
                                <Label for="number">Número</Label>
                                <Input type="number" name="number" id="number" onChange={(event) => this.inputChangedHandler(event, 'number')} value={(parseInt(this.state.form.number) === 0 ? '' : this.state.form.number)} />
                            </Col>
                        </FormGroup>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="2">Outros profissionais</AccordionHeader>
                        <AccordionBody accordionId="2">
                        <FormGroup row>
                            <Col sm={12}>
                                <Label for="obs">Título da Função:</Label>
                                <Input type="text" name="title" id="title" placeholder="Descreva a função de trabalho" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                            </Col>
                        </FormGroup>
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
                <br />
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="obs">Observação:</Label>
                        <Input type="text" name="obs" id="obs" placeholder="Observação" onChange={(event) => this.inputChangedHandler(event, 'obs')} value={this.state.form.obs} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="budget">Valor padrão a Receber nessa função:</Label>
                        <InputGroup>
                            <InputGroupText>R$</InputGroupText>
                            <Input type="text" name="budget" id="budget" placeholder="" onChange={(event) => this.inputChangedHandler(event, 'budget')} value={this.state.form.budget} />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormStaff));
