const AddrCountries = [
    {"id": 1, "country_name" : "Afghanistan", "country_code" : "AF", "phone_code" : "93", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 2, "country_name" : "Aland Islands", "country_code" : "AX", "phone_code" : "358", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 3, "country_name" : "Albania", "country_code" : "AL", "phone_code" : "355", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 4, "country_name" : "Algeria", "country_code" : "DZ", "phone_code" : "213", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 5, "country_name" : "American Samoa", "country_code" : "AS", "phone_code" : "1684", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 6, "country_name" : "Andorra", "country_code" : "AD", "phone_code" : "376", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 7, "country_name" : "Angola", "country_code" : "AO", "phone_code" : "244", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 8, "country_name" : "Anguilla", "country_code" : "AI", "phone_code" : "1264", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 9, "country_name" : "Antarctica", "country_code" : "AQ", "phone_code" : "672", "continent_name" : "Antarctica", "continent_code" :"AN" },
    {"id": 10, "country_name" : "Antigua and Barbuda", "country_code" : "AG", "phone_code" : "1268", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 11, "country_name" : "Argentina", "country_code" : "AR", "phone_code" : "54", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 12, "country_name" : "Armenia", "country_code" : "AM", "phone_code" : "374", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 13, "country_name" : "Aruba", "country_code" : "AW", "phone_code" : "297", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 14, "country_name" : "Australia", "country_code" : "AU", "phone_code" : "61", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 15, "country_name" : "Austria", "country_code" : "AT", "phone_code" : "43", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 16, "country_name" : "Azerbaijan", "country_code" : "AZ", "phone_code" : "994", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 17, "country_name" : "Bahamas", "country_code" : "BS", "phone_code" : "1242", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 18, "country_name" : "Bahrain", "country_code" : "BH", "phone_code" : "973", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 19, "country_name" : "Bangladesh", "country_code" : "BD", "phone_code" : "880", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 20, "country_name" : "Barbados", "country_code" : "BB", "phone_code" : "1246", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 21, "country_name" : "Belarus", "country_code" : "BY", "phone_code" : "375", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 22, "country_name" : "Belgium", "country_code" : "BE", "phone_code" : "32", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 23, "country_name" : "Belize", "country_code" : "BZ", "phone_code" : "501", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 24, "country_name" : "Benin", "country_code" : "BJ", "phone_code" : "229", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 25, "country_name" : "Bermuda", "country_code" : "BM", "phone_code" : "1441", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 26, "country_name" : "Bhutan", "country_code" : "BT", "phone_code" : "975", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 27, "country_name" : "Bolivia", "country_code" : "BO", "phone_code" : "591", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 28, "country_name" : "Bonaire,  Sint Eustatius and Saba", "country_code" : "BQ", "phone_code" : "599", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 29, "country_name" : "Bosnia and Herzegovina", "country_code" : "BA", "phone_code" : "387", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 30, "country_name" : "Botswana", "country_code" : "BW", "phone_code" : "267", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 31, "country_name" : "Bouvet Island", "country_code" : "BV", "phone_code" : "55", "continent_name" : "Antarctica", "continent_code" :"AN" },
    {"id": 32, "country_name" : "Brazil", "country_code" : "BR", "phone_code" : "55", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 33, "country_name" : "British Indian Ocean Territory", "country_code" : "IO", "phone_code" : "246", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 34, "country_name" : "Brunei Darussalam", "country_code" : "BN", "phone_code" : "673", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 35, "country_name" : "Bulgaria", "country_code" : "BG", "phone_code" : "359", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 36, "country_name" : "Burkina Faso", "country_code" : "BF", "phone_code" : "226", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 37, "country_name" : "Burundi", "country_code" : "BI", "phone_code" : "257", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 38, "country_name" : "Cambodia", "country_code" : "KH", "phone_code" : "855", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 39, "country_name" : "Cameroon", "country_code" : "CM", "phone_code" : "237", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 40, "country_name" : "Canada", "country_code" : "CA", "phone_code" : "1", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 41, "country_name" : "Cape Verde", "country_code" : "CV", "phone_code" : "238", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 42, "country_name" : "Cayman Islands", "country_code" : "KY", "phone_code" : "1345", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 43, "country_name" : "Central African Republic", "country_code" : "CF", "phone_code" : "236", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 44, "country_name" : "Chad", "country_code" : "TD", "phone_code" : "235", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 45, "country_name" : "Chile", "country_code" : "CL", "phone_code" : "56", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 46, "country_name" : "China", "country_code" : "CN", "phone_code" : "86", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 47, "country_name" : "Christmas Island", "country_code" : "CX", "phone_code" : "61", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 48, "country_name" : "Cocos (Keeling) Islands", "country_code" : "CC", "phone_code" : "672", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 49, "country_name" : "Colombia", "country_code" : "CO", "phone_code" : "57", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 50, "country_name" : "Comoros", "country_code" : "KM", "phone_code" : "269", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 51, "country_name" : "Congo", "country_code" : "CG", "phone_code" : "242", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 52, "country_name" : "Congo,  Democratic Republic of the Congo" , "country_code" : "CD", "phone_code" : "242", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 53, "country_name" : "Cook Islands", "country_code" : "CK", "phone_code" : "682", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 54, "country_name" : "Costa Rica", "country_code" : "CR", "phone_code" : "506", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 55, "country_name" : "Cote D'Ivoire", "country_code" : "CI", "phone_code" : "225", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 56, "country_name" : "Croatia", "country_code" : "HR", "phone_code" : "385", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 57, "country_name" : "Cuba", "country_code" : "CU", "phone_code" : "53", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 58, "country_name" : "Curacao", "country_code" : "CW", "phone_code" : "599", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 59, "country_name" : "Cyprus", "country_code" : "CY", "phone_code" : "357", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 60, "country_name" : "Czech Republic", "country_code" : "CZ", "phone_code" : "420", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 61, "country_name" : "Denmark", "country_code" : "DK", "phone_code" : "45", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 62, "country_name" : "Djibouti", "country_code" : "DJ", "phone_code" : "253", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 63, "country_name" : "Dominica", "country_code" : "DM", "phone_code" : "1767", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 64, "country_name" : "Dominican Republic", "country_code" : "DO", "phone_code" : "1809", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 65, "country_name" : "Ecuador", "country_code" : "EC", "phone_code" : "593", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 66, "country_name" : "Egypt", "country_code" : "EG", "phone_code" : "20", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 67, "country_name" : "El Salvador", "country_code" : "SV", "phone_code" : "503", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 68, "country_name" : "Equatorial Guinea", "country_code" : "GQ", "phone_code" : "240", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 69, "country_name" : "Eritrea", "country_code" : "ER", "phone_code" : "291", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 70, "country_name" : "Estonia", "country_code" : "EE", "phone_code" : "372", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 71, "country_name" : "Ethiopia", "country_code" : "ET", "phone_code" : "251", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 72, "country_name" : "Falkland Islands (Malvinas)", "country_code" : "FK", "phone_code" : "500", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 73, "country_name" : "Faroe Islands", "country_code" : "FO", "phone_code" : "298", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 74, "country_name" : "Fiji", "country_code" : "FJ", "phone_code" : "679", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 75, "country_name" : "Finland", "country_code" : "FI", "phone_code" : "358", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 76, "country_name" : "France", "country_code" : "FR", "phone_code" : "33", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 77, "country_name" : "French Guiana", "country_code" : "GF", "phone_code" : "594", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 78, "country_name" : "French Polynesia", "country_code" : "PF", "phone_code" : "689", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 79, "country_name" : "French Southern Territories", "country_code" : "TF", "phone_code" : "262", "continent_name" : "Antarctica", "continent_code" :"AN" },
    {"id": 80, "country_name" : "Gabon", "country_code" : "GA", "phone_code" : "241", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 81, "country_name" : "Gambia", "country_code" : "GM", "phone_code" : "220", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 82, "country_name" : "Georgia", "country_code" : "GE", "phone_code" : "995", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 83, "country_name" : "Germany", "country_code" : "DE", "phone_code" : "49", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 84, "country_name" : "Ghana", "country_code" : "GH", "phone_code" : "233", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 85, "country_name" : "Gibraltar", "country_code" : "GI", "phone_code" : "350", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 86, "country_name" : "Greece", "country_code" : "GR", "phone_code" : "30", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 87, "country_name" : "Greenland", "country_code" : "GL", "phone_code" : "299", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 88, "country_name" : "Grenada", "country_code" : "GD", "phone_code" : "1473", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 89, "country_name" : "Guadeloupe", "country_code" : "GP", "phone_code" : "590", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 90, "country_name" : "Guam", "country_code" : "GU", "phone_code" : "1671", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 91, "country_name" : "Guatemala", "country_code" : "GT", "phone_code" : "502", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 92, "country_name" : "Guernsey", "country_code" : "GG", "phone_code" : "44", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 93, "country_name" : "Guinea", "country_code" : "GN", "phone_code" : "224", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 94, "country_name" : "Guinea-Bissau", "country_code" : "GW", "phone_code" : "245", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 95, "country_name" : "Guyana", "country_code" : "GY", "phone_code" : "592", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 96, "country_name" : "Haiti", "country_code" : "HT", "phone_code" : "509", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 97, "country_name" : "Heard Island and Mcdonald Islands", "country_code" : "HM", "phone_code" : "0", "continent_name" : "Antarctica", "continent_code" :"AN" },
    {"id": 98, "country_name" : "Holy See (Vatican City State)", "country_code" : "VA", "phone_code" : "39", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 99, "country_name" : "Honduras", "country_code" : "HN", "phone_code" : "504", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 100, "country_name" : "Hong Kong", "country_code" : "HK", "phone_code" : "852", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 101, "country_name" : "Hungary", "country_code" : "HU", "phone_code" : "36", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 102, "country_name" : "Iceland", "country_code" : "IS", "phone_code" : "354", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 103, "country_name" : "India", "country_code" : "IN", "phone_code" : "91", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 104, "country_name" : "Indonesia", "country_code" : "ID", "phone_code" : "62", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 105, "country_name" : "Iran,  Islamic Republic of" , "country_code" : "IR", "phone_code" : "98", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 106, "country_name" : "Iraq", "country_code" : "IQ", "phone_code" : "964", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 107, "country_name" : "Ireland", "country_code" : "IE", "phone_code" : "353", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 108, "country_name" : "Isle of Man", "country_code" : "IM", "phone_code" : "44", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 109, "country_name" : "Israel", "country_code" : "IL", "phone_code" : "972", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 110, "country_name" : "Italy", "country_code" : "IT", "phone_code" : "39", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 111, "country_name" : "Jamaica", "country_code" : "JM", "phone_code" : "1876", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 112, "country_name" : "Japan", "country_code" : "JP", "phone_code" : "81", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 113, "country_name" : "Jersey", "country_code" : "JE", "phone_code" : "44", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 114, "country_name" : "Jordan", "country_code" : "JO", "phone_code" : "962", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 115, "country_name" : "Kazakhstan", "country_code" : "KZ", "phone_code" : "7", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 116, "country_name" : "Kenya", "country_code" : "KE", "phone_code" : "254", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 117, "country_name" : "Kiribati", "country_code" : "KI", "phone_code" : "686", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 118, "country_name" : "Korea,  Democratic People's Republic of" , "country_code" : "KP", "phone_code" : "850", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 119, "country_name" : "Korea,  Republic of" , "country_code" : "KR", "phone_code" : "82", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 120, "country_name" : "Kosovo", "country_code" : "XK", "phone_code" : "381", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 121, "country_name" : "Kuwait", "country_code" : "KW", "phone_code" : "965", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 122, "country_name" : "Kyrgyzstan", "country_code" : "KG", "phone_code" : "996", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 123, "country_name" : "Lao People's Democratic Republic", "country_code" : "LA", "phone_code" : "856", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 124, "country_name" : "Latvia", "country_code" : "LV", "phone_code" : "371", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 125, "country_name" : "Lebanon", "country_code" : "LB", "phone_code" : "961", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 126, "country_name" : "Lesotho", "country_code" : "LS", "phone_code" : "266", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 127, "country_name" : "Liberia", "country_code" : "LR", "phone_code" : "231", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 128, "country_name" : "Libyan Arab Jamahiriya", "country_code" : "LY", "phone_code" : "218", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 129, "country_name" : "Liechtenstein", "country_code" : "LI", "phone_code" : "423", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 130, "country_name" : "Lithuania", "country_code" : "LT", "phone_code" : "370", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 131, "country_name" : "Luxembourg", "country_code" : "LU", "phone_code" : "352", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 132, "country_name" : "Macao", "country_code" : "MO", "phone_code" : "853", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 133, "country_name" : "Macedonia,  the Former Yugoslav Republic of" , "country_code" : "MK", "phone_code" : "389", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 134, "country_name" : "Madagascar", "country_code" : "MG", "phone_code" : "261", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 135, "country_name" : "Malawi", "country_code" : "MW", "phone_code" : "265", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 136, "country_name" : "Malaysia", "country_code" : "MY", "phone_code" : "60", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 137, "country_name" : "Maldives", "country_code" : "MV", "phone_code" : "960", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 138, "country_name" : "Mali", "country_code" : "ML", "phone_code" : "223", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 139, "country_name" : "Malta", "country_code" : "MT", "phone_code" : "356", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 140, "country_name" : "Marshall Islands", "country_code" : "MH", "phone_code" : "692", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 141, "country_name" : "Martinique", "country_code" : "MQ", "phone_code" : "596", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 142, "country_name" : "Mauritania", "country_code" : "MR", "phone_code" : "222", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 143, "country_name" : "Mauritius", "country_code" : "MU", "phone_code" : "230", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 144, "country_name" : "Mayotte", "country_code" : "YT", "phone_code" : "269", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 145, "country_name" : "Mexico", "country_code" : "MX", "phone_code" : "52", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 146, "country_name" : "Micronesia,  Federated States of" , "country_code" : "FM", "phone_code" : "691", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 147, "country_name" : "Moldova,  Republic of" , "country_code" : "MD", "phone_code" : "373", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 148, "country_name" : "Monaco", "country_code" : "MC", "phone_code" : "377", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 149, "country_name" : "Mongolia", "country_code" : "MN", "phone_code" : "976", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 150, "country_name" : "Montenegro", "country_code" : "ME", "phone_code" : "382", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 151, "country_name" : "Montserrat", "country_code" : "MS", "phone_code" : "1664", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 152, "country_name" : "Morocco", "country_code" : "MA", "phone_code" : "212", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 153, "country_name" : "Mozambique", "country_code" : "MZ", "phone_code" : "258", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 154, "country_name" : "Myanmar", "country_code" : "MM", "phone_code" : "95", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 155, "country_name" : "Namibia", "country_code" : "NA", "phone_code" : "264", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 156, "country_name" : "Nauru", "country_code" : "NR", "phone_code" : "674", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 157, "country_name" : "Nepal", "country_code" : "NP", "phone_code" : "977", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 158, "country_name" : "Netherlands", "country_code" : "NL", "phone_code" : "31", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 159, "country_name" : "Netherlands Antilles", "country_code" : "AN", "phone_code" : "599", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 160, "country_name" : "New Caledonia", "country_code" : "NC", "phone_code" : "687", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 161, "country_name" : "New Zealand", "country_code" : "NZ", "phone_code" : "64", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 162, "country_name" : "Nicaragua", "country_code" : "NI", "phone_code" : "505", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 163, "country_name" : "Niger", "country_code" : "NE", "phone_code" : "227", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 164, "country_name" : "Nigeria", "country_code" : "NG", "phone_code" : "234", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 165, "country_name" : "Niue", "country_code" : "NU", "phone_code" : "683", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 166, "country_name" : "Norfolk Island", "country_code" : "NF", "phone_code" : "672", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 167, "country_name" : "Northern Mariana Islands", "country_code" : "MP", "phone_code" : "1670", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 168, "country_name" : "Norway", "country_code" : "NO", "phone_code" : "47", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 169, "country_name" : "Oman", "country_code" : "OM", "phone_code" : "968", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 170, "country_name" : "Pakistan", "country_code" : "PK", "phone_code" : "92", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 171, "country_name" : "Palau", "country_code" : "PW", "phone_code" : "680", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 172, "country_name" : "Palestinian Territory,  Occupied" , "country_code" : "PS", "phone_code" : "970", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 173, "country_name" : "Panama", "country_code" : "PA", "phone_code" : "507", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 174, "country_name" : "Papua New Guinea", "country_code" : "PG", "phone_code" : "675", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 175, "country_name" : "Paraguay", "country_code" : "PY", "phone_code" : "595", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 176, "country_name" : "Peru", "country_code" : "PE", "phone_code" : "51", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 177, "country_name" : "Philippines", "country_code" : "PH", "phone_code" : "63", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 178, "country_name" : "Pitcairn", "country_code" : "PN", "phone_code" : "64", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 179, "country_name" : "Poland", "country_code" : "PL", "phone_code" : "48", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 180, "country_name" : "Portugal", "country_code" : "PT", "phone_code" : "351", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 181, "country_name" : "Puerto Rico", "country_code" : "PR", "phone_code" : "1787", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 182, "country_name" : "Qatar", "country_code" : "QA", "phone_code" : "974", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 183, "country_name" : "Reunion", "country_code" : "RE", "phone_code" : "262", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 184, "country_name" : "Romania", "country_code" : "RO", "phone_code" : "40", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 185, "country_name" : "Russian Federation", "country_code" : "RU", "phone_code" : "70", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 186, "country_name" : "Rwanda", "country_code" : "RW", "phone_code" : "250", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 187, "country_name" : "Saint Barthelemy", "country_code" : "BL", "phone_code" : "590", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 188, "country_name" : "Saint Helena", "country_code" : "SH", "phone_code" : "290", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 189, "country_name" : "Saint Kitts and Nevis", "country_code" : "KN", "phone_code" : "1869", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 190, "country_name" : "Saint Lucia", "country_code" : "LC", "phone_code" : "1758", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 191, "country_name" : "Saint Martin", "country_code" : "MF", "phone_code" : "590", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 192, "country_name" : "Saint Pierre and Miquelon", "country_code" : "PM", "phone_code" : "508", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 193, "country_name" : "Saint Vincent and the Grenadines", "country_code" : "VC", "phone_code" : "1784", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 194, "country_name" : "Samoa", "country_code" : "WS", "phone_code" : "684", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 195, "country_name" : "San Marino", "country_code" : "SM", "phone_code" : "378", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 196, "country_name" : "Sao Tome and Principe", "country_code" : "ST", "phone_code" : "239", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 197, "country_name" : "Saudi Arabia", "country_code" : "SA", "phone_code" : "966", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 198, "country_name" : "Senegal", "country_code" : "SN", "phone_code" : "221", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 199, "country_name" : "Serbia", "country_code" : "RS", "phone_code" : "381", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 200, "country_name" : "Serbia and Montenegro", "country_code" : "CS", "phone_code" : "381", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 201, "country_name" : "Seychelles", "country_code" : "SC", "phone_code" : "248", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 202, "country_name" : "Sierra Leone", "country_code" : "SL", "phone_code" : "232", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 203, "country_name" : "Singapore", "country_code" : "SG", "phone_code" : "65", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 204, "country_name" : "Sint Maarten", "country_code" : "SX", "phone_code" : "1", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 205, "country_name" : "Slovakia", "country_code" : "SK", "phone_code" : "421", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 206, "country_name" : "Slovenia", "country_code" : "SI", "phone_code" : "386", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 207, "country_name" : "Solomon Islands", "country_code" : "SB", "phone_code" : "677", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 208, "country_name" : "Somalia", "country_code" : "SO", "phone_code" : "252", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 209, "country_name" : "South Africa", "country_code" : "ZA", "phone_code" : "27", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 210, "country_name" : "South Georgia and the South Sandwich Islands", "country_code" : "GS", "phone_code" : "500", "continent_name" : "Antarctica", "continent_code" :"AN" },
    {"id": 211, "country_name" : "South Sudan", "country_code" : "SS", "phone_code" : "211", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 212, "country_name" : "Spain", "country_code" : "ES", "phone_code" : "34", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 213, "country_name" : "Sri Lanka", "country_code" : "LK", "phone_code" : "94", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 214, "country_name" : "Sudan", "country_code" : "SD", "phone_code" : "249", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 215, "country_name" : "Suriname", "country_code" : "SR", "phone_code" : "597", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 216, "country_name" : "Svalbard and Jan Mayen", "country_code" : "SJ", "phone_code" : "47", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 217, "country_name" : "Swaziland", "country_code" : "SZ", "phone_code" : "268", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 218, "country_name" : "Sweden", "country_code" : "SE", "phone_code" : "46", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 219, "country_name" : "Switzerland", "country_code" : "CH", "phone_code" : "41", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 220, "country_name" : "Syrian Arab Republic", "country_code" : "SY", "phone_code" : "963", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 221, "country_name" : "Taiwan,  Province of China" , "country_code" : "TW", "phone_code" : "886", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 222, "country_name" : "Tajikistan", "country_code" : "TJ", "phone_code" : "992", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 223, "country_name" : "Tanzania,  United Republic of" , "country_code" : "TZ", "phone_code" : "255", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 224, "country_name" : "Thailand", "country_code" : "TH", "phone_code" : "66", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 225, "country_name" : "Timor-Leste", "country_code" : "TL", "phone_code" : "670", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 226, "country_name" : "Togo", "country_code" : "TG", "phone_code" : "228", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 227, "country_name" : "Tokelau", "country_code" : "TK", "phone_code" : "690", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 228, "country_name" : "Tonga", "country_code" : "TO", "phone_code" : "676", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 229, "country_name" : "Trinidad and Tobago", "country_code" : "TT", "phone_code" : "1868", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 230, "country_name" : "Tunisia", "country_code" : "TN", "phone_code" : "216", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 231, "country_name" : "Turkey", "country_code" : "TR", "phone_code" : "90", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 232, "country_name" : "Turkmenistan", "country_code" : "TM", "phone_code" : "7370", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 233, "country_name" : "Turks and Caicos Islands", "country_code" : "TC", "phone_code" : "1649", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 234, "country_name" : "Tuvalu", "country_code" : "TV", "phone_code" : "688", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 235, "country_name" : "Uganda", "country_code" : "UG", "phone_code" : "256", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 236, "country_name" : "Ukraine", "country_code" : "UA", "phone_code" : "380", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 237, "country_name" : "United Arab Emirates", "country_code" : "AE", "phone_code" : "971", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 238, "country_name" : "United Kingdom", "country_code" : "GB", "phone_code" : "44", "continent_name" : "Europe", "continent_code" :"EU" },
    {"id": 239, "country_name" : "United States", "country_code" : "US", "phone_code" : "1", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 240, "country_name" : "United States Minor Outlying Islands", "country_code" : "UM", "phone_code" : "1", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 241, "country_name" : "Uruguay", "country_code" : "UY", "phone_code" : "598", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 242, "country_name" : "Uzbekistan", "country_code" : "UZ", "phone_code" : "998", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 243, "country_name" : "Vanuatu", "country_code" : "VU", "phone_code" : "678", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 244, "country_name" : "Venezuela", "country_code" : "VE", "phone_code" : "58", "continent_name" : "South America", "continent_code" :"SA" },
    {"id": 245, "country_name" : "Viet Nam", "country_code" : "VN", "phone_code" : "84", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 246, "country_name" : "Virgin Islands,  British" , "country_code" : "VG", "phone_code" : "1284", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 247, "country_name" : "Virgin Islands,  U.s." , "country_code" : "VI", "phone_code" : "1340", "continent_name" : "North America", "continent_code" :"NA" },
    {"id": 248, "country_name" : "Wallis and Futuna", "country_code" : "WF", "phone_code" : "681", "continent_name" : "Oceania", "continent_code" :"OC" },
    {"id": 249, "country_name" : "Western Sahara", "country_code" : "EH", "phone_code" : "212", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 250, "country_name" : "Yemen", "country_code" : "YE", "phone_code" : "967", "continent_name" : "Asia", "continent_code" :"AS" },
    {"id": 251, "country_name" : "Zambia", "country_code" : "ZM", "phone_code" : "260", "continent_name" : "Africa", "continent_code" :"AF" },
    {"id": 252, "country_name" : "Zimbabwe", "country_code" : "ZW", "phone_code" : "263", "continent_name" : "Africa", "continent_code" :"AF" }
];

export default AddrCountries;
