import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import toastfy from '../Aux/Toastfy/Toastfy';
import authenticatedInstance from '../../api/authenticated';
import { Button,  Col, Form, FormGroup, Label, Input } from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import SimpleItemAddSubmit from '../../api/simpleItemAddSubmit';
import SympleItensGet from '../../api/sympleItensGet';
import withRouter from '../Aux/WithRouter/WithRouter';

class FormOpus extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            form:  {
                id: 0,
                title: '',
                author: {
                  'id': '',
                  'name': ''
                },
                author_id: null,
                style: {
                  'id': '',
                  'title': ''
                },
                style_id: null,
                nota: '',
                diretorio: '',
                compoe_livros: '',
                link_audio: '',
                link_video: '',
                // solo: '',
                info: '',
                livro_pagina: '',
                instrumentations: [],
                selectedInstrumentOption: []
            }
        }
    }

    componentDidMount() {
        this.loadInfo();
    }
    
    componentDidUpdate(prevProps) {
        if (
            typeof this.params !== 'undefined' 
            && typeof this.props.params.id !== 'undefined'
            && this.props.params.id !== prevProps.params.id 
        ) {
            this.loadInfo();
        }
    }

    loadInfo() {
        authenticatedInstance.get('/collection/'+ this.props.currentCompanyId +'/opus/' + this.props.params.id).then(response => {
            let new_instrumentations = [];
            response.data.instrumentations.map((value) => {
                new_instrumentations.push({ value: value.id, label: value.title });
                return new_instrumentations;
            });
            const updatedForm = {...response.data};
            updatedForm.selectedInstrumentOption = new_instrumentations;
            updatedForm.author_id = response.data.author.id;
            updatedForm.style_id = response.data.style.id;

            this.setState({
                form: updatedForm
            });
        }).catch(error => {
            this.setState({error: true});
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    checkboxChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = (event.target.checked ? 1 : 0);
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        let new_instrumentations = [];
        this.state.form.selectedInstrumentOption.map((value) => {
            new_instrumentations.push(value.value);
            return new_instrumentations;
        });
        const updatedForm = {...this.state.form};
        updatedForm.instrumentations = new_instrumentations;
        updatedForm.user_id = 0;
        updatedForm.compoe_livros = updatedForm.compoe_livros.length === 0 ? 0 : parseInt(updatedForm.compoe_livros);
        updatedForm.nota = updatedForm.nota.length === 0 ? 0 : parseInt(updatedForm.nota);
        this.setState({form: updatedForm});
        delete updatedForm["_links"];

        if(this.props.edit) {
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/opus/' + this.props.params.id, updatedForm).then(response => {
                toastfy("success", 'Obra alterada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar alterar a Obra...');
                this.setState({error: true});
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/opus', updatedForm).then(response => {
                toastfy("success", 'Obra adicionada com sucesso!');
                return response.data;
            }).catch(error => {
                toastfy("error", 'Houve algum problema ao tentar adicionar a Obra...');
                this.setState({error: true});
            });
        }
    }

    handleInstrumentChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.selectedInstrumentOption = newValue;
        this.setState({ form: updatedForm });
    };

    onCreateInstruments = async (title) => {
        const newInstrument = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/instrumentations', 'title');
        const updatedForm = {...this.state.form};
        const new_instrument = {
          value: newInstrument.id,
          label: newInstrument.title
        };
        updatedForm.selectedInstrumentOption.push(new_instrument);
        this.setState({
          form: updatedForm
        });
    }

    onCreateAuthors = async (title) => {
        const newAuthor = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/authors', 'name');
        const updatedForm = {...this.state.form};
        updatedForm.author.name = newAuthor.name;
        updatedForm.author.id = newAuthor.id;
        updatedForm.author_id = newAuthor.id;
        this.setState({
          form: updatedForm
        });
    }

    handleAuthorChange = (newValue, actionMeta) => {
        console.log('handleAuthorChange', newValue);
        const updatedForm = {...this.state.form};
        updatedForm.author_id = newValue.value;
        updatedForm.author.id = newValue.value;
        updatedForm.author.name = newValue.label;
        this.setState({ form: updatedForm });
        console.log('componentDidMount', this.state.form);
    };

    onCreateStyles = async (title) => {
        const newStyle = await SimpleItemAddSubmit(title, '/collection/'+ this.props.currentCompanyId +'/styles', 'title');
        const updatedForm = {...this.state.form};
        updatedForm.style.title = newStyle.title;
        updatedForm.style.id = newStyle.id;
        updatedForm.style_id = newStyle.id;
        this.setState({
          form: updatedForm
        });
    }

    handleStyleChange = (newValue, actionMeta) => {
        const updatedForm = {...this.state.form};
        updatedForm.style_id = newValue.value;
        updatedForm.style.id = newValue.value;
        updatedForm.style.title = newValue.label;
        this.setState({ form: updatedForm });
    };

    render() {
        let livroPagina = '';
        if(this.state.form.compoe_livros === 1) {
            livroPagina = (
            <FormGroup row>
                <Label for="livro_pagina" sm={4}>Página no Livro:</Label>
                <Col sm={8}>
                    <Input type="text" name="livro_pagina" id="livro_pagina" placeholder="Nº. da Página" onChange={(event) => this.inputChangedHandler(event, 'livro_pagina')} value={this.state.form.livro_pagina} />
                </Col>
            </FormGroup>);
        }

        // console.log('Props', this.state.form);
        return (
            <Form>
                <FormGroup row>
                    <Col sm={5}>
                        <Label for="title">Título</Label>
                        <Input type="text" name="title" id="title" placeholder="Título da obra" onChange={(event) => this.inputChangedHandler(event, 'title')} value={this.state.form.title} />
                    </Col>
                  <Col sm={5}>
                      <Label for="author">Autor</Label>
                      <SelectAsync
                          id="author"
                          name="author"
                          matchPos="any"
                          value={{value: this.state.form.author.id, label: this.state.form.author.name}}
                          loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/' + this.props.currentCompanyId + '/authors', 'name')}
                          onCreateOption={this.onCreateAuthors}
                          onChange={this.handleAuthorChange}
                      />
                  </Col>
                  <Col sm={2}>
                      <Label for="title">Nota</Label>
                      <Input type="number" name="nota" id="nota" placeholder="Nota" onChange={(event) => this.inputChangedHandler(event, 'nota')} value={this.state.form.nota} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={3}>
                        <Label for="style">Estilo</Label>
                        <SelectAsync
                            id="style"
                            name="style"
                            matchPos="any"
                            value={{value: this.state.form.style.id, label: this.state.form.style.title}}
                            loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/styles', 'title')}
                            onCreateOption={this.onCreateStyles}
                            onChange={this.handleStyleChange}
                        />
                    </Col>
                    <Col sm={9}>
                        <Label for="instrumentations">Instrumentações</Label>
                        <SelectAsync
                            id="instrumentations"
                            name="instrumentations"
                            isMulti="true"
                            matchPos="any"
                            value={this.state.form.selectedInstrumentOption}
                            loadOptions={(inputValue) => SympleItensGet(inputValue, '/collection/'+ this.props.currentCompanyId +'/instrumentations', 'title')}
                            onCreateOption={this.onCreateInstruments}
                            onChange={this.handleInstrumentChange}
                          />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="title">Informações</Label>
                        <Input type="textarea" name="info" id="info" placeholder="Informações" onChange={(event) => this.inputChangedHandler(event, 'info')} value={this.state.form.info} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={4}>
                        <Label for="title">Diretório</Label>
                        <Input type="text" name="diretorio" id="diretorio" placeholder="Diretório" onChange={(event) => this.inputChangedHandler(event, 'diretorio')} value={this.state.form.diretorio} />
                    </Col>
                    <Col sm={4}>
                        <Label for="title">Link Áudio</Label>
                        <Input type="text" name="link_audio" id="link_audio" placeholder="Link Áudio" onChange={(event) => this.inputChangedHandler(event, 'link_audio')} value={this.state.form.link_audio} />
                    </Col>
                    <Col sm={4}>
                        <Label for="title">Link Vídeo</Label>
                        <Input type="text" name="link_video" id="link_video" placeholder="Link Vídeo" onChange={(event) => this.inputChangedHandler(event, 'link_video')} value={this.state.form.link_video} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <FormGroup>
                            <div>
                                {/* <CustomInput
                                    type="checkbox"
                                    id="compoe_livros"
                                    name="compoe_livros"
                                    label="Compõe Livros"
                                    onChange={(event) => this.checkboxChangedHandler(event, 'compoe_livros')}
                                    value={this.state.form.compoe_livros}
                                    checked={this.state.form.compoe_livros === 1 ? true : false} /> */}
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        {livroPagina}
                    </Col>
                </FormGroup>
                <FormGroup row={true}>
                    <Col sm="1">
                        <Button type="button" color="primary" onClick={this.submitHandler}>Salvar</Button>
                    </Col>
                </FormGroup>
            </Form>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.id,
	}
};

export default connect(mapStateToProps, null)(withRouter(FormOpus));
