import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    Col,
    Card,
    CardTitle,
    CardSubtitle,
    CardBody,
    CardText,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Row,
} from 'reactstrap';
import classNames from 'classnames';
import authenticatedInstance from '../../../api/authenticated';
import { Link } from 'react-router-dom';
import LoaderContext from '../../../context/LoaderContext';
import withRouter from '../../Aux/WithRouter/WithRouter';
import './Event.css';
import { 
    FiEdit,
    FiClock, 
    FiCalendar, 
    FiMapPin 
} from "react-icons/fi";
import { 
    FaRing
} from "react-icons/fa6";
import EventProgram from './EventProgram';
import EventStaffMembers from './EventStaffMembers';
import EventFinancial from './EventFinancial';
import EventCalendar from './EventCalendar';

class Event extends Component {
	static contextType = LoaderContext;

	constructor(props) {
	  super(props);
      this.state = {
        activeTab: '1',
        error: false,
        Event: {
            id: null,
            title: null,
            description: null,
            date_time: null,
            budget: null,
            local: {id: null, title: null, description: null},
            company: {id: null, slug: null},
            type: {id: null, title: null},
            staff: [],
            program: [],
            calendar: [],
            financial: []
        },
        events: null
      };
	}

    componentDidMount() {
        console.log("Event: componentDidMount")
        this.loadEvent(this.props.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.setState({
                Event: {
                    id: null,
                    title: null,
                    description: null,
                    date_time: null,
                    budget: null,
                    local: {id: null, title: null, description: null},
                    company: {id: null, slug: null},
                    type: {id: null, title: null},
                    staff: [],
                    program: [],
                    financial: [],
                    calendar: []
                }
            });
            this.loadEvent(this.props.params.id);
        }
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    loadEvent = async (id) => {
        console.log("Event: loadEvent")
        // Load Events
        try {
            authenticatedInstance.get('/collection/'+ this.props.currentCompanyId +'/event/' + id)
            .then(response => {
                moment.locale('pt-br');
                const dateTimeObj = moment(response.data.date_time);
                console.log("Event: loadEvent loaded", response.data.staff)

                this.setState({
                    Event: {
                        id: response.data.id,
                        title: response.data.title,
                        description: response.data.description,
                        date_time: response.data.date_time,
                        date: dateTimeObj.format('L'),
                        time: dateTimeObj.format('LT'),
                        budget: response.data.budget,
                        local: response.data.local,
                        company: response.data.company,
                        type: response.data.type,
                        staff: response.data.staff,
                        program: response.data.program,
                        financial: response.data.financial,
                        calendar: response.data.calendar

                    }
                });
            });
        } catch (error) {
            this.setState({ error: true });
        }
    }

    parseNumber = (value) => {
        if(
            typeof value === 'number' 
            && !isNaN(value)
            && parseInt(value) > 0
        ) {
            return ' ' + value.toString();
        }

        return '';
    }
    
	render() {

    	return (
            <Fragment>
                <CardTitle tag="h5">
                    <FaRing />&nbsp;&nbsp;{this.state.Event.title}
                    <span style={{float: 'right'}}>
                        <FiCalendar />&nbsp;&nbsp;{this.state.Event.date}&nbsp;&nbsp;
                        <FiClock />&nbsp;&nbsp;{this.state.Event.time}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to={"/c/"+ this.props.currentCompany.slug +"/events/edit/" + this.state.Event.id}><FiEdit /></Link>
                    </span>
                </CardTitle>
                <CardSubtitle
                    className="mb-2 text-muted"
                    tag="h6"
                >
                    {this.state.Event.local !== null ? this.state.Event.local.title : ''}&nbsp;&nbsp;<FiMapPin />
                </CardSubtitle>
                <CardText>
                
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classNames({ 'active': this.state.activeTab === '1' })}
                            onClick={() => { this.toggleTab('1'); }}
                        >
                            Geral
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ 'active': this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}
                        >
                            Calendário
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ 'active': this.state.activeTab === '3' })}
                            onClick={() => { this.toggleTab('3'); }}
                        >
                            Repertório
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ 'active': this.state.activeTab === '4' })}
                            onClick={() => { this.toggleTab('4'); }}
                        >
                            Equipe Profissional
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ 'active': this.state.activeTab === '5' })}
                            onClick={() => { this.toggleTab('5'); }}
                        >
                            Financeiro
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <Card
                                    className="event-card"
                                    color="light"
                                    outline
                                    style={{
                                        width: '100%    '
                                    }}
                                >
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Informações Gerais
                                        </CardTitle>
                                        <CardText>
                                            {this.state.Event.description}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <EventCalendar
                                    event_id={this.state.Event.id}
                                    calendar={this.state.Event.calendar}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <EventProgram
                                    event_id={this.state.Event.id}
                                    program={this.state.Event.program}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="4">
                        <Row>
                            <Col sm="12">
                                <EventStaffMembers
                                    event_id={this.state.Event.id}
                                    staff={this.state.Event.staff}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="5">
                        <EventFinancial
                            event_id={this.state.Event.id}
                            financial={this.state.Event.financial}
                            budget={this.state.Event.budget}
                        />
                    </TabPane>
                </TabContent>
            </CardText>
        </Fragment>
        );
	}
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
		currentCompany: state.company
	}
};

export default connect(mapStateToProps, null)(withRouter(Event));
