import React, { PureComponent, Fragment } from 'react';
import authenticatedInstance from '../../../api/authenticated';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import moment from 'moment';
import 'moment/locale/pt-br';
import { 
    Badge,
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Placeholder
} from 'reactstrap';
import SelectAsync from 'react-select/async-creatable';
import * as actions from '../../../store/actions/index';
import SimpleItemAddSubmit from '../../../api/simpleItemAddSubmit';
import { 
    FiUser
} from "react-icons/fi";
import withRouter from '../../Aux/WithRouter/WithRouter';

class FormCalendar extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: true,
            form: {
                id: 0,
                date: '',
                hour: '',
                duration: '',
                description: ''
            }
        }
    }

    componentDidMount() {
        this.loadForm();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadForm();
        }
    }

    loadForm = () => {
        const open = this.props.isOpen;
        const form = {...this.state.form};

        if(this.props.calendar !== null) {
            moment.locale('pt-br');
            const dateTimeObj = moment(this.props.calendar.date_time);

            form.id = this.props.calendar.id;
            form.date = dateTimeObj.format('YYYY-MM-DD');
            form.hour = dateTimeObj.format('LT');
            form.duration = this.props.calendar.duration.substring(0, 5);
            form.description = this.props.calendar.description;
        }

        this.setState({
            isOpen: open,
            form: form
        });
    }

    resetForm = () => {
        this.setState({
            form: {
                date: '',
                hour: '',
                duration: '',
                description: ''
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedForm = {...this.state.form};
        updatedForm[inputIdentifier] = event.target.value;
        this.setState({form: updatedForm});
    }

    submitHandler = (data) => {
        const updatedForm = {...this.state.form};
        updatedForm.date_time = updatedForm.date + ' ' + updatedForm.hour + ':00';
        updatedForm.duration = updatedForm.duration + ':00';
        console.log("submitHandler", updatedForm);

        if(this.props.edit) {
            authenticatedInstance.put('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId + '/calendar/' + this.state.form.id, updatedForm).then(response => {
                toastfy('success', 'Evento alterado com sucesso!');
                this.props.returnFunction(updatedForm);
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar alterar o evento...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        } else {
            authenticatedInstance.post('/collection/' + this.props.currentCompanyId + '/event/' + this.props.eventId + '/calendar', updatedForm).then(response => {
                toastfy('success', 'Evento adicionado com sucesso!');
                this.props.returnFunction(updatedForm);
                this.resetForm();
                return response.data;
            }).catch(error => {
                toastfy('error', 'Houve algum problema ao tentar adicionar o evento...');
                this.setState({error: true});
                this.props.loaderStop();
            });
        }
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.props.onClosed()}
            isOpen={this.state.isOpen}
        >
            <OffcanvasHeader toggle={() => this.props.onClosed()}>
                <Row>
                    <Col sm={12}>
                        {this.props.edit ? 'Editar Compromisso' : 'Adicionar Compromisso'}
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Form>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="date">Data:</Label>
                        <Input type="date" name="date" id="date" placeholder="Data" onChange={(event) => this.inputChangedHandler(event, 'date')} value={this.state.form.date} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="hour">Hora:</Label>
                        <Input type="time" name="hour" id="hour" placeholder="Hora" onChange={(event) => this.inputChangedHandler(event, 'hour')} value={this.state.form.hour} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="hour">Duração:</Label>
                        <Input type="time" name="duration" id="duration" placeholder="Duração" onChange={(event) => this.inputChangedHandler(event, 'duration')} value={this.state.form.duration} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Label for="description">Descrição:</Label>
                        <Input type="text" name="description" id="description" placeholder="Descrição" onChange={(event) => this.inputChangedHandler(event, 'description')} value={this.state.form.description} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={12}>
                        <Button type="button" color="primary" onClick={(e) => this.submitHandler(e)}>{this.props.edit ? 'Editar' : 'Adicionar'}</Button>
                    </Col>
                </FormGroup>
            </Form>
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(FormCalendar));
