import React, {Fragment, Component} from 'react';
import { connect } from 'react-redux';
import authenticatedInstance from '../../api/authenticated';
import {
    Table,
    Button,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import toastfy from '../Aux/Toastfy/Toastfy';
import ModalDelete from '../Aux/ModalConfirm/ModalConfirm';
import Paginator from '../Aux/Paginator/Paginator';
import { Link } from 'react-router-dom';
import withRouter from '../Aux/WithRouter/WithRouter';

class SimpleItens extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itens: [],
            page: 1,
            pages: 1,
            search: '',
            deleteId: null,
            deleteShow: false
        }
    };

    componentDidMount() {
        this.loadCollection(this.state.page);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.api_entity !== this.props.api_entity) {
            this.loadCollection(this.state.page);
        }
    }

    loadCollection = (page) => {
        if (typeof page === 'undefined') {
            page = this.state.page;
        }
        let searchQuery = '&orderBy=' + this.props.title_var_name;
        if (this.state.search !== '') {
            searchQuery += '&search='+ this.props.title_var_name +':' + this.state.search;
        }
        authenticatedInstance.get('/collection/' + this.props.currentCompanyId + '/'+ this.props.api_entity +'?page=' + page + searchQuery).then(response => {
            this.setState({
                itens: response.data.itens.data,
                pages: response.data.itens.last_page,
                page: response.data.itens.current_page
            });
        }).catch(error => {
            this.setState({error: true});
        });
    }

    setPage = (e, page) => {
        e.preventDefault();
        this.setState({page: page});
        this.loadCollection(page);
    }

    searchItemHandler = (event) => {
        const search_str = event.target.value;
        if(search_str.length >= 3) {
            this.setState({
                search: event.target.value,
                page: 1
            });
            // this.loadCollection(1);
        }
    }

    searchItemHandlerKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.loadCollection(1);
        }
    }

    exclude = (id) => {
        this.setState({deleteId: id, deleteShow: true});
    }

    excludeDo = () => {
        authenticatedInstance.delete('/collection/' + this.props.currentCompanyId + '/' + this.props.api_entity +'/' + this.state.deleteId)
        .then(response => {
            this.loadCollection(this.state.page);
            toastfy("success", 'Item excluído com sucesso!');
        }).catch(error => {
            this.setState({error: true});
            toastfy("error", 'Houve algum problema ao excluir o item, tente novamente...');
        });
        this.setState({deleteShow: false});
    }

    excludeCancel = () => {
        this.setState({deleteShow: false});
    }

    render() {
        let deleteTitleModal = 'Excluir';
        let deleteMessageModal = 'Tem certeza que deseja excluir?';

        let modalExclude = "";

        if (this.state.deleteShow) {
            modalExclude = (<ModalDelete title={deleteTitleModal} message={deleteMessageModal}
                handler={() => this.excludeDo()} cancel={() => this.excludeCancel()} show={true}/>);
        }

        return (<Fragment>
            <FormGroup row="row">
                <Col sm="11">
                    <h2>{this.props.page_title}</h2>
                </Col>
                <Col sm="1">
                    <Link to={"/c/" + this.props.currentCompany.slug + "/scores/" + this.props.api_entity + "/add"}>
                        <Button color="primary">Novo</Button>
                    </Link>
                </Col>
            </FormGroup>
            <FormGroup row="row">
                <Col sm={11}>
                    <Label for="title">Título</Label>
                    <Input
                        type="text"
                        id={this.props.title_var_name}
                        name={this.props.title_var_name}
                        placeholder="Título"
                        onChange={(event) => this.searchItemHandler(event)}
                        onKeyPress={this.searchItemHandlerKeyPress}
                    />
                </Col>
                <Col sm={1}>
                    <Label for="btn_search">Buscar</Label>
                    <Button id="btn_search" color="primary" onClick={() => this.loadCollection(1)}>Pesquisar</Button>
                </Col>
            </FormGroup>
            <Table dark="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Editar</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.itens.map((item, index) => {
                        return (<tr key={index}>
                            <th scope="row">{item.id}</th>
                            <td>{item[this.props.title_var_name]}</td>
                            <td>
                                <Link to={'/c/' + this.props.currentCompany.slug + '/scores/'+ this.props.api_entity +'/edit/' + item.id}>
                                    <Button color="primary">Editar</Button>
                                </Link>
                            </td>
                            <td>
                                <Button color="danger" onClick={() => this.exclude(item.id)}>Excluir</Button>
                            </td>
                        </tr>);
                    })}
                </tbody>
            </Table>
            <Paginator pages={this.state.pages} page={this.state.page} handlePage={this.setPage}/>
            {modalExclude}
        </Fragment>);
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
        currentCompany: state.company
	}
};

export default connect(mapStateToProps, null)(withRouter(SimpleItens));
