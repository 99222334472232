import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import toastfy from '../../Aux/Toastfy/Toastfy';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    Col,
    Card,
    CardBody,
    CardText,
    Container,
    Row,
    ButtonGroup,
    Button,
    Badge
} from 'reactstrap';
import { ReactSortable } from "react-sortablejs";
import * as actions from '../../../store/actions/index';
import authenticatedInstance from '../../../api/authenticated';
import LoaderContext from '../../../context/LoaderContext';
import './Event.css';
import { 
    FiEdit,
    FiTrash2,
    FiMove,
    FiAlertTriangle
} from "react-icons/fi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import FormCalendar from './FormCalendar';
import ModalDelete from '../../Aux/ModalDelete/ModalDelete';
import withRouter from '../../Aux/WithRouter/WithRouter';

class EventCalendar extends Component {
    static contextType = LoaderContext;

    constructor(props) {
        super(props);
        this.state = {
            error: false,
            formCalendarOpen: false,
            formCalendarEdit: false,
            formCalendarEditData: null,
            formCalendarEditId: null,
            formCalendarEditPosition: null,
            event_id: null,
            calendar: [],
            exclude: {
                entity: 'anything',
                anything: {
                    id: 0,
                    show: false,
                    title: "",
                    message: ""
                },
                calendar: {
                    id: 0,
                    position: 0,
                    show: false,
                    title: "Excluir compromisso do calendário",
                    message: "Deseja realmente excluir esse compromisso?"
                },
            },
            events: null
        };
    }

    componentDidMount() {
        console.log("componentDidMount", this.props.event_id, this.props.calendar);
        this.setState({
            event_id: this.props.event_id,
            calendar: this.props.calendar
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event_id !== this.props.event_id) {
            this.setState({
                event_id: this.props.event_id,
                calendar: this.props.calendar
            });
        }
    }

    calendarAdd = () => {
        this.setState({
            formCalendarOpen: !this.state.formCalendarOpen,
            formCalendarEdit: false,
            formCalendarEditData: null
        });
    }

    calendarEdit = (position) => {
        this.setState({
            formCalendarOpen: !this.state.formCalendarOpen,
            formCalendarEdit: true,
            formCalendarEditPosition: position,
            formCalendarEditData: this.state.calendar[position]
        });
    }

    addCalendarEvent = (calendarNew) => {
        console.log("addCalendarEvent", calendarNew);
        const calendar = Object.values({...this.state.calendar});
        if(this.state.formCalendarEdit) {
            let data = {...this.state.formCalendarEditData};
            data['date_time'] = calendarNew['date_time'];
            data['duration'] = calendarNew['duration'];
            data['description'] = calendarNew['description'];
            calendar[this.state.formCalendarEditPosition] = data;
        } else {
            calendar.push(calendarNew);
        }
        this.setState({
            formCalendarOpen: false,
            calendar: calendar
        });
    }

    exclude = (id, position, entity, path) => {
        console.log("exclude", id, position, entity, path);
        const exclude = {...this.state.exclude};
        exclude["entity"] = entity;
        exclude[entity]["id"] = id;
        exclude[entity]["position"] = position;
        exclude[entity]["show"] = true;
        exclude[entity]["path"] = '/collection/' + this.props.currentCompanyId + '/event/' + this.props.event_id + path;
        this.setState({exclude: exclude});
    }

    excludeDo = () => {
        const calendar = Object.values({...this.state.calendar});
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
        switch(this.state.exclude.entity) {
            case "calendar":
                delete calendar[exclude[this.state.exclude.entity]["position"]];
                break;
            default:
                break;
        }
        this.setState({calendar: calendar});
    }

    excludeCancel = () => {
        const exclude = {...this.state.exclude};
        exclude[this.state.exclude.entity]["show"] = false;
        this.setState({exclude: exclude});
    }

    render() {
        let modalExclude = "";
        if(this.state.exclude[this.state.exclude.entity]["show"]) {
            modalExclude = (
                <ModalDelete
                    title={this.state.exclude[this.state.exclude.entity]["title"]}
                    message={this.state.exclude[this.state.exclude.entity]["message"]}
                    path={this.state.exclude[this.state.exclude.entity]["path"]}
                    actionOnSuccess={() => this.excludeDo()}
                    cancel={() => this.excludeCancel()}
                    show={true}
                />
            );
        }

        return (
            <Fragment>
                <Card
                    className="event-card"
                    color="light"
                    outline
                    style={{
                        width: '100%'
                    }}
                >
                    <CardBody>
                        <CardText>
                            <Row>
                                <Col sm={5}>
                                    <Button type="button" color="primary" onClick={this.calendarAdd}><AiOutlineUsergroupAdd /> Adicionar Compromisso</Button>
                                </Col>
                            </Row>
                            <hr/>
                            {
                            typeof this.state.calendar != 'undefined' && this.state.calendar.length > 0 ?
                                <div>
                                {this.state.calendar.map((calendar, index) => {
                                moment.locale('pt-br');
                                const dateTimeFormatted = moment(calendar.date_time);
                                return (
                                    <Container key={index} className="bg-light border item-list-box">
                                        <Row>
                                            <Col sm={11}>
                                                <h5>{dateTimeFormatted.format('LLL')} - Duração: {calendar.duration.substring(0, 5)}</h5>
                                                <p>{calendar.description}</p>
                                            </Col>
                                            <Col sm={1}>
                                                <ButtonGroup vertical>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() => this.calendarEdit(index)}
                                                    >
                                                        <FiEdit title="Editar Compromisso" />
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        outline
                                                        onClick={() => this.exclude(calendar.id, index, "calendar", "/calendar/" + calendar.id)}
                                                    >
                                                        <FiTrash2 title="Apagar Compromisso" />
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </Container>
                                );
                                })}
                                </div>
                                : 
                                <p>Sem eventos agendados.</p>
                            }
                        </CardText>
                    </CardBody>
                </Card>
                <FormCalendar
                    returnFunction={(calendar) => this.addCalendarEvent(calendar)}
                    eventId={this.state.event_id}
                    isOpen={this.state.formCalendarOpen}
                    onClosed={this.calendarAdd}
                    edit={this.state.formCalendarEdit}
                    calendar={this.state.formCalendarEditData}
                />
                {modalExclude}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(EventCalendar));
