import React, { Component, createRef } from 'react';
import { 
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row
} from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import withRouter from '../Aux/WithRouter/WithRouter';
import ViewScorePdf from './ViewScorePdf';

class ViewScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            edit: false,
        }
    }

    componentDidMount() {
        this.loadScore();
    }
    
    componentDidUpdate(prevProps) {
        if(prevProps !== this.props) {
            this.loadScore();
        }
    }

    loadScore = () => {
        this.setState({
            isOpen: this.props.show,
            pdfUrl: this.props.pdfUrl
        });
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onClosed = () => {
        this.props.onClosed();
    }

    render() {
        return (
        <Offcanvas
            direction="end"
            scrollable
            toggle={() => this.toggle()}
            isOpen={this.state.isOpen}
            style={{width: '100%'}}
        >
            <OffcanvasHeader toggle={() => this.toggle()}>
            {/* <OffcanvasHeader toggle={() => {}}> */}
                <Row>
                    <Col sm={12}>
                        View Score
                    </Col>
                </Row>
            </OffcanvasHeader>
            <OffcanvasBody>
                <ViewScorePdf />
            </OffcanvasBody>
        </Offcanvas>
        );
    }
}

const mapStateToProps = state => {
	return {
        currentCompanyId: state.company.current,
	}
};

const mapDispatchProps = dispatch => {
	return {
        loaderStop: () => dispatch(actions.loaderStop())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(ViewScore));
