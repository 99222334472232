import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    Col
} from 'reactstrap';
import { Route, Routes } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import LoaderContext from '../../../context/LoaderContext';
import withRouter from '../../Aux/WithRouter/WithRouter';
import './Home.css';

class Home extends Component {
	static contextType = LoaderContext;

	render() {
        console.log(this.props.auth);

        return (
            <Fragment>
                <Col sm="12">
                    <Routes>
                        <Route key="my_home" path="/" exact={true} element={<h2>Home!</h2>} />
                    </Routes>
                </Col>
            </Fragment>
        );
	}
}

const mapStateToProps = state => {
	return {
		info: state.auth.info,
        auth: state.auth,
	}
};

const mapDispatchProps = dispatch => {
	return {
		setCurrentCompany: (company) => dispatch(actions.setCurrentCompany(company)),
		setInfo: () => dispatch(actions.info())
	};
};

export default connect(mapStateToProps, mapDispatchProps)(withRouter(Home));
