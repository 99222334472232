import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const Paginator = (props) => {

  const pages = [];
  for(let page = 1; page <= props.pages; page++) {
    pages.push(
      <PaginationItem key={page} active={page === props.page ? true : false}>
        <PaginationLink onClick={e => props.handlePage(e, page)} href="#">
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  }

  const iniDisabled = props.page === 1 ? true : false;
  const endDisabled = props.page === props.pages ? true : false;

  return (
    <div className="pagination-wrapper">
      <Pagination aria-label="Page navigation">
        <PaginationItem disabled={iniDisabled}>
          <PaginationLink first="true" onClick={e => props.handlePage(e, 1)} href="#" />
        </PaginationItem>
        <PaginationItem disabled={iniDisabled}>
          <PaginationLink previous={true} onClick={e => props.handlePage(e, props.page - 1)} href="#" />
        </PaginationItem>
        {pages}
        <PaginationItem disabled={endDisabled}>
          <PaginationLink next={true} onClick={e => props.handlePage(e, props.page + 1)} href="#" />
        </PaginationItem>
        <PaginationItem disabled={endDisabled}>
          <PaginationLink last="true" onClick={e => props.handlePage(e, props.pages)} href="#" />
        </PaginationItem>
      </Pagination>
    </div>
  );
}

export default Paginator;
