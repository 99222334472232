import * as actionTypes from './actionTypes';

export const loaderStart = () => {
    return {
        type: actionTypes.LOADER_START
    };
};

export const loaderStop = () => {
    return {
        type: actionTypes.LOADER_STOP
    };
};
